import { Component, OnInit, ViewChild, OnDestroy} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";
import { SelectionModel } from "@angular/cdk/collections";
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
    selector: "cs-grupo",
    templateUrl: "./grupo.component.html",
    styleUrls: ["./grupo.component.scss"]
})
export class GrupoComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    private subscription: any;
    public listRedes: any [];
    public _columns: string[] = [];
    public acao: string;
    public filtro: boolean = true;
    public grupos: any[] = [];

    public total: number;
    public dataSource = new MatTableDataSource<any>(this.grupos);
    public selection = new SelectionModel<any>(true, []);

    public unidadeSelecionada: any;

    public model: any = {
        ID: '00000000-0000-0000-0000-000000000000',
        RedeID:null
    };

    constructor(
        public dialog: MatDialog,
        private ui: UIService,
        private api: APIService,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {

        this._columns = ['Acoes', 'Nome', 'Rede'];
        this.listarRedes();

        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];

            setTimeout(() => {

                this.paginator.pageSize = 10;

                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

                const parameter: any = JSON.parse(JSON.stringify({}));
                parameter.Sort = 'Nome|asc';

                if (id == '0') {
                    this.acao = "Inclusão";
                    this.filtro = false;
                    return;
                }
                else if(id){
                    this.model.ID = id;
                    this.acao = "Edição";
                    this.filtro = false;
                }
                this.filtrar();
            });
        })
    }

    ngOnDestroy() {
    }

    cancelar() {
        this.acao = null;
        this.filtro = true;
        this.location.replaceState('/grupos');
        this.router.navigate(["/grupos"]);
        this.limparFiltro();
        setTimeout(() => {
            this.filtrar();
        });
    }

    validar() {
        let podeSalvar: boolean = true;
        let reason = '';
        if (this.model.Nome == null || this.model.Nome == '' || this.model.Nome.replace(' ', '').length == 0){
            reason = this.ui.buildMessage(reason,'Forneça o nome.');
            podeSalvar = false;
        }
        else if (this.model.RedeID == null){
            reason = this.ui.buildMessage(reason,'Forneça a rede.');
            podeSalvar = false;
        }
        if (!podeSalvar) {
            this.ui.error(reason);
        }
        return podeSalvar;
    }

    salvar() {
        if (this.validar()) {
            this.ui.block();

            const parameter: any = { ID: this.model.ID, Nome:this.model.Nome, RedeID: this.model.RedeID};

            this.api.atualizarGrupo(parameter).subscribe((response: any) => {
                this.ui.unblock();

                if (response.error) {
                    this.ui.error(response.error);
                } else {
                    this.ui.success("Grupo salvo com sucesso.");

                    this.filtro = true;
                    this.limparFiltro();
                    this.location.replaceState('/grupos');
                    this.router.navigate(["/grupos"]);
                    setTimeout(() => {
                        this.filtrar(0, true)
                    });
                }
            });
        }
    }

    filtrar(page?: number, reset = false) {

        let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        if (reset) {
            skip = 0;
            this.paginator.pageIndex = 0;
        }
        const parameter: any = { Grupo: { ID: this.model.ID, Nome:this.model.Nome, Rede: { ID: this.model.RedeID } } }
        if (parameter.Grupo.Rede.ID == null)
            parameter.Grupo.Rede.ID = '00000000-0000-0000-0000-000000000000';

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        if (this.sort && this.sort.direction) {
            parameter.Sort = this.sort.active + '|' + this.sort.direction;
        }
        else {
            parameter.Sort = 'Nome|asc';
        }

        this.ui.block('Pesquisando grupos...');

        this.api.obterGrupos(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    if (!this.filtro && response.data.Grupos.length > 0) {
                        this.model.Nome = response.data.Grupos[0].Nome;
                        this.model.RedeID = response.data.Grupos[0].Rede.ID;
                    }
                    else {
                        this.total = response.data.Total;
                        this.grupos = response.data.Grupos;

                        this.dataSource = new MatTableDataSource<any>(response.data.Grupos);
                        this.selection = new SelectionModel<any>(true, []);

                        if (page) {
                            this.paginator.pageIndex = page;
                        }
                    }
                }
            }
        );
    }

    private listarRedes(){
        const parameter: any = JSON.parse(JSON.stringify({}));
        this.api.obterRedes(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.listRedes = response.data.Redes;
                }
            }
        );
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    pageChanged(page: any) {

        setTimeout(() => {
            this.filtrar();
        });
    }

    sortChanged(sort: any) {

        setTimeout(() => {
            this.filtrar();
        });
    }

    newRecord() {
        this.acao = "Inclusão";
        this.filtro = false;
        this.location.replaceState('/grupos/0');
        this.model = {
            ID: '00000000-0000-0000-0000-000000000000',
            RedeID:null
        };
        //this.router.navigate(['/grupos', 0]);
    }

    editRecord(element: any) {

        this.acao = "Edição";
        this.filtro = false;
        this.location.replaceState('/grupos/' + element.ID);
        this.model = {ID: element.ID, Nome:element.Nome, RedeID: element.Rede.ID};
        //this.router.navigate(['/grupos', element.ID]);
    }

    deleteRecord(element: any) {
        Swal.fire({
            title: 'Atenção!',
            text: "Deseja mesmo realizar a exclusão? A ação será irreversível.",
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, deletar!'
        }).then((result) => {
            if (result.value) {
                const parameter: any = JSON.parse(JSON.stringify({ ID: element.ID }));
                this.api.excluirGrupo(parameter).subscribe(

                    (response: any) => {

                        this.ui.unblock();

                        if (response.error) {

                            this.ui.error(response.error);
                        }
                        else {
                            this.ui.success("Grupo Excluído com sucesso.");
                            this.filtrar();
                        }
                    }
                );
            }
        });
    }

    selecionaUnidade(unidade: any) {

        this.unidadeSelecionada = unidade;
    }

    rowSelected(row: any) {

    }

    limparFiltro() {
        this.model = {
            RedeID:null
        };
    }
}
