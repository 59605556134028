import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'cs-rede-pagamentos',
    templateUrl: './rede-pagamentos.component.html',
    styleUrls: ['./rede-pagamentos.component.scss']
})
export class RedePagamentosComponent implements OnInit {

    @Input() modal: string = undefined;

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public model: any = { };
    public redes: any[] = [];
    public total: number;
    public dataSource = new MatTableDataSource<any>(this.redes);
    public selection = new SelectionModel<any>(true, []);

    public unidadeSelecionada: any;

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        setTimeout(() => {

            this._columns = [this.modal !== undefined ? 'Selecionado' : 'Acoes', 'Nome']

            this.paginator.pageSize = 10;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            if (this.modal) {
            }

            this.loadSession();
        });
    }

    limparFiltro() {

        this.model = {
        };
    }

    filtrar(page?: number, reset = false) {

        this.ui.block('Pesquisando redes de pagamentos...');

        let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        if (reset) {
            skip = 0;
            this.paginator.pageIndex = 0;
        }
        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        if (this.sort && this.sort.direction) {
            parameter.Sort = JSON.stringify([{ field: this.sort.active, dir: this.sort.direction }]);
            //parameter.Sort = this.sort.active + '|' + this.sort.direction;
        }
        else {
            parameter.Sort = JSON.stringify([{ field: 'Nome', dir: 'asc' }]);
            //parameter.Sort = 'Nome|asc';
        }

        this.api.listarRedePagamento(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    this.redes = response.data.RedesPagamentos;

                    this.dataSource = new MatTableDataSource<any>(response.data.RedesPagamentos);
                    this.selection = new SelectionModel<any>(true, []);

                    if (page) {
                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    newRecord() {

        this.saveSession();
        this.router.navigate(['/redepagamentos', 0]);
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/redepagamentos', element.ID]);
    }

    deleteRecord(element: any) {
    }

    saveSession() {
        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {
        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Nome';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    selecionaUnidade(unidade: any) {

        this.unidadeSelecionada = unidade;
    }

    rowSelected(row: any) {

        if (!this.modal) {

            return;
        }

        if (this.modal === 'single') {

            this.selecionaUnidade(row);
        }
        else if (this.modal === 'multiple') {

            this.selection.toggle(row);
        }
    }

}
