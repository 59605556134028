import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Observer, Subject, ReplaySubject } from "rxjs";
import { MatCheckbox, MatDialog, MatOption, MatSelect } from "@angular/material";

import { Font, FontPickerService } from "ngx-font-picker";

import { UIService } from "../../services/ui.service";
import { APIService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

import { SelecaoParceirosComponent } from "../../components/selecao-parceiros/selecao-parceiros.component";

import html2canvas from "html2canvas";

import * as moment from "moment";
import { FormControl } from "@angular/forms";
import { take, takeUntil } from "rxjs/operators";

declare var window: any;

@Component({
    selector: "cs-premio",
    templateUrl: "./premio.component.html",
    styleUrls: ["./premio.component.scss"]
})
export class PremioComponent implements OnInit, OnDestroy {
    @ViewChild("cropperFrente") cropperFrente: any;
    @ViewChild("opacityFrente") opacityFrente: any;

    @ViewChild("cropperVerso") cropperVerso: any;
    @ViewChild("opacityVerso") opacityVerso: any;
    @ViewChild("multiSelect") multiSelect: MatSelect;
    @ViewChild("singleSelect") singleSelect: MatSelect;
    public parceirosMultiCtrl: FormControl = new FormControl();
    public parceirosMultiFilerCtrl: FormControl = new FormControl();
    public filteredParceirosMulti: ReplaySubject<Rede[]> = new ReplaySubject<
        Rede[]
    >(1);

    protected _onDestroy = new Subject<void>();

    public emissorMultiCtrl: FormControl = new FormControl();
    public emissorMultiFilerCtrl: FormControl = new FormControl();
    public filteredEmissorMulti: ReplaySubject<Parceiro[]> = new ReplaySubject<
        Parceiro[]
    >(1);

    protected _onDestroyEmissor = new Subject<void>();

    public selectedItemsParceiros = [];
    public parceiros: Parceiro[] = [];
    public participantesCount = 0;
    public selectedOptions = [];
    public todosParticipantes:boolean;
    public redes: Rede[] = [];
    public isReady: boolean;

    private subscription: any;

    public acao: string;

    public model: any = {
        Parceiro: {
            ID: 1
        },
        Lojas: [],
        ValorVenda: 0,
        RetornoVendedores: 0,
        RetornoCaixas: 0,
        RetornoGerentes: 0,
        AlvoColaboradores: true
    };

    public ladoPremio: number = 1;

    public cropperReadyFrente: boolean;
    public croppedImageFrente: any;

    public cropperReadyVerso: boolean;
    public croppedImageVerso: any;

    public robotoFiles: any = {
        "100": "http://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgWxP.ttf",
        "300":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5vAw.ttf",
        "500":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9vAw.ttf",
        "700":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlvAw.ttf",
        "900":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmYUtvAw.ttf",
        "100italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOiCnqEu92Fr1Mu51QrIzc.ttf",
        "300italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TjARc9.ttf",
        regular: "http://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Me5Q.ttf",
        italic:
            "http://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu52xP.ttf",
        "500italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51S7ABc9.ttf",
        "700italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBhc9.ttf",
        "900italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TLBBc9.ttf"
    };

    public robotoStyles: any[] = [
        "100",
        "100italic",
        "300",
        "300italic",
        "regular",
        "italic",
        "500",
        "500italic",
        "700",
        "700italic",
        "900",
        "900italic"
    ];

    public camposFrente: any[] = [
        {
            Nome: "Campo 1",
            Texto: "",
            Fonte: {
                size: "32px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 2",
            Texto: "",
            Fonte: {
                size: "28px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 3",
            Texto: "",
            Fonte: {
                size: "20px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 4",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 5",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 6",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 7",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 8",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 9",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 10",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        }
    ];

    public modelosFrente: any[] = [
        {
            ID: 0,
            Nome: "Nenhum"
        },
        {
            ID: 4,
            Nome: "Apenas Texto",
            Campos: this.camposFrente
        },
        {
            ID: 1,
            Nome: "Modelo 1",
            Imagem: "../../../assets/images/modelo1.png",
            Campos: this.camposFrente,
            QRCode: {
                Frente: "black",
                Fundo: "transparent",
                PosicaoY: 348,
                PosicaoX: 38
            }
        },
        {
            ID: 2,
            Nome: "Modelo 2",
            Imagem: "../../../assets/images/modelo2.png",
            Campos: this.camposFrente,
            QRCode: {
                Frente: "white",
                Fundo: "black",
                PosicaoY: 594,
                PosicaoX: 20
            }
        },
        {
            ID: 3,
            Nome: "Modelo 3",
            Imagem: "../../../assets/images/modelo3.png",
            Campos: this.camposFrente,
            QRCode: {
                Frente: "black",
                Fundo: "white",
                PosicaoY: 604,
                PosicaoX: 10
            }
        }
    ];

    public camposVerso: any[] = [
        {
            Nome: "Campo 1",
            Texto: "",
            Fonte: {
                size: "20px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 2",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 3",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 4",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 5",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 6",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 7",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        }
    ];

    public modelosVerso: any[] = [
        {
            ID: 0,
            Nome: "Nenhum"
        },
        {
            ID: 1,
            Nome: "Modelo 1",
            Campos: this.camposVerso
        }
    ];

    public modeloSelecionadoFrente: any = this.modelosFrente[0];
    public campoSelecionadoFrente: any;

    public modeloSelecionadoVerso: any = this.modelosVerso[0];
    public campoSelecionadoVerso: any;

    public imageChangedEventFrente: any;
    public imageChangedEventVerso: any;

    public qrCode: any = {
        Tamanho: 100
    };

    public salvando: boolean;

    constructor(
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute,
        private storage: StorageService,
        private fontService: FontPickerService,
        public dialog: MatDialog
    ) {}

    layout() {
        const layout: any = {
            camposFrente: this.camposFrente,
            modelosFrente: this.modelosFrente,
            camposVerso: this.camposVerso,
            modelosVerso: this.modelosVerso,
            indexModeloFrente: this.modelosFrente.indexOf(
                this.modeloSelecionadoFrente
            ),
            indexModeloVerso: this.modelosVerso.indexOf(
                this.modeloSelecionadoVerso
            ),
            croppedImageFrente: this.croppedImageFrente,
            opacityFrente: this.opacityFrente.value,
            croppedImageVerso: this.croppedImageVerso,
            opacityVerso: this.opacityVerso.value,
            qrCode: this.qrCode
        };

        return JSON.stringify(layout);
    }

    ngOnInit() {
        this.ui.block();

        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];
            const modelo: number = +params["modelo"];

            if (id != '0') {
                this.api.obterVoucher(id).subscribe((response: any) => {
                    if (!response.error) {
                        response.data.Voucher.Parceiro = {};
                        response.data.Voucher.Lojas = [];

                        // if (response.data.Voucher.Validade) {
                        //     response.data.Voucher.Validade = moment(
                        //         response.data.Voucher.Validade
                        //     );
                        // }

                        const emissor: string = response.data.Voucher.Emissor;
                        const lojas: string[] =
                            response.data.Voucher.Participantes;
                        this.carregarParceiros(lojas, emissor);
                        // this.api.obterParceiros({ Take: 9999, Skip: 0, Sort: 'Nome|asc' }).subscribe(

                        //     (response: any) => {

                        //         if (!response.error) {

                        //             const parceiros: any[] = response.data.Parceiros;

                        //             this.model.Parceiro = parceiros.find((parceiro) => parceiro.ID === emissor);

                        //             for (const loja of lojas) {

                        //                 this.model.Lojas.push(parceiros.find((parceiro) => parceiro.ID === loja));
                        //             }
                        //         }
                        //     }
                        // );

                        response.data.Voucher.AlvoClientes =
                            response.data.Voucher.Publico === 1 ||
                            response.data.Voucher.Publico === 3;
                        response.data.Voucher.AlvoColaboradores =
                            response.data.Voucher.Publico === 2 ||
                            response.data.Voucher.Publico === 3;

                        this.model = response.data.Voucher;
                        this.acao = "Edição";

                        const layout: any = JSON.parse(
                            response.data.Voucher.Layout
                        );

                        this.camposFrente = layout.camposFrente;
                        this.modelosFrente = layout.modelosFrente;
                        this.camposVerso = layout.camposVerso;
                        this.modelosVerso = layout.modelosVerso;

                        this.qrCode = layout.qrCode;

                        setTimeout(() => {
                            this.ladoPremio = 2;

                            this.cropperVerso.imageBase64 =
                                layout.croppedImageVerso;
                            this.opacityVerso.value = layout.opacityVerso;
                            this.croppedImageVerso = layout.croppedImageVerso;
                            this.cropperReadyVerso = true;

                            setTimeout(() => {
                                this.ladoPremio = 1;

                                this.cropperFrente.imageBase64 =
                                    layout.croppedImageFrente;
                                this.opacityFrente.value = layout.opacityFrente;
                                this.croppedImageFrente =
                                    layout.croppedImageFrente;
                                this.cropperReadyFrente = true;

                                this.load(
                                    layout.indexModeloFrente,
                                    layout.indexModeloVerso
                                );
                            }, 1000);
                        }, 1000);
                    }
                    else{
                        this.ui.unblock();
                    }
                });
            } else {
                this.carregarParceiros(null, null);
                if (modelo) {
                    this.api.obterVoucher(modelo).subscribe((response: any) => {
                        if (!response.error) {
                            this.acao = "Criação";

                            const layout: any = JSON.parse(
                                response.data.Voucher.Layout
                            );

                            this.camposFrente = layout.camposFrente;
                            this.modelosFrente = layout.modelosFrente;
                            this.camposVerso = layout.camposVerso;
                            this.modelosVerso = layout.modelosVerso;

                            this.qrCode = layout.qrCode;

                            setTimeout(() => {
                                this.ladoPremio = 2;

                                this.cropperVerso.imageBase64 =
                                    layout.croppedImageVerso;
                                this.opacityVerso.value = layout.opacityVerso;
                                this.croppedImageVerso =
                                    layout.croppedImageVerso;
                                this.cropperReadyVerso = true;

                                setTimeout(() => {
                                    this.ladoPremio = 1;

                                    this.cropperFrente.imageBase64 =
                                        layout.croppedImageFrente;
                                    this.opacityFrente.value =
                                        layout.opacityFrente;
                                    this.croppedImageFrente =
                                        layout.croppedImageFrente;
                                    this.cropperReadyFrente = true;

                                    this.load(
                                        layout.indexModeloFrente,
                                        layout.indexModeloVerso
                                    );
                                }, 1000);
                            }, 1000);
                        }
                    });
                } else {
                    this.acao = "Criação";

                    this.load(0, 0);
                }
            }
        });
    }

    mudarEmissor(event){
        // let msg = (event.value && event.value.ID) ? event.value.ID: "0";
        // msg = "mudar emissor: " + msg;
        // console.log(msg);
    }

    toggleAllSelection(select,event){
        if (event.checked) {
            select.options.forEach((item: MatOption) => item.select());
          } else {
            select.options.forEach((item: MatOption) => item.deselect());
          }

    }

    avaliarParticipantesSelecionados(event){
        if(this.participantesCount == 0){
            return;
        }
        this.todosParticipantes = (this.participantesCount == event.length);
    }

    load(indexModeloFrente: number, indexModeloVerso: number) {
        for (const modelo of this.modelosFrente) {
            if (modelo.Campos) {
                for (const campo of modelo.Campos) {
                    if (campo.Fonte) {
                        campo.Fonte = new Font(campo.Fonte);

                        this.fontService.loadFont(campo.Fonte);
                    }
                }
            }
        }

        this.modeloSelecionadoFrente = this.modelosFrente[indexModeloFrente];
        if (this.modeloSelecionadoFrente.Campos)
            this.campoSelecionadoFrente = this.modeloSelecionadoFrente.Campos[0];

        for (const modelo of this.modelosVerso) {
            if (modelo.Campos) {
                for (const campo of modelo.Campos) {
                    if (campo.Fonte) {
                        campo.Fonte = new Font(campo.Fonte);
                        this.fontService.loadFont(campo.Fonte);
                    }
                }
            }
        }

        this.modeloSelecionadoVerso = this.modelosVerso[indexModeloVerso];
        if (this.modeloSelecionadoVerso.Campos)
            this.campoSelecionadoVerso = this.modeloSelecionadoVerso.Campos[0];

        this.isReady = true;

        //this.ui.unblock();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancelar() {
        this.router.navigate(["/premios"]);
    }

    getImages() {
        return Observable.create((observer: Observer<boolean>) => {
            const ladoPremio: number = this.ladoPremio;

            this.ladoPremio = 1;
            this.salvando = true;

            setTimeout(() => {
                html2canvas(
                    document.querySelector("#imagem-voucher-frente")
                ).then(canvas => {
                    this.model.ImagemFrente = canvas.toDataURL();
                });

                this.ladoPremio = 2;

                setTimeout(() => {
                    html2canvas(
                        document.querySelector("#imagem-voucher-verso")
                    ).then(canvas => {
                        this.model.ImagemVerso = canvas.toDataURL();

                        setTimeout(() => {
                            this.salvando = false;
                            this.ladoPremio = ladoPremio;

                            observer.next(true);
                        });
                    });
                });
            });
        });
    }

    salvar() {
        this.salvando = true;
        let msg = !this.model.ID
        ? "Gerando prêmios..."
        : "Atualizando prêmio...";
        this.ui.block(msg);
        if (this.validar()) {
            this.getImages().subscribe(() => {
                const data: any = JSON.parse(JSON.stringify(this.parameter()));
                this.api.salvarVoucher(data).subscribe((response: any) => {
                    if (response.error) {
                        this.ui.error(response.error);
                        this.salvando = false;
                    } else {
                        msg = (msg == "Gerando prêmios...") ? "Prêmios criados com sucesso!": "Prêmios atualizados com sucesso!";
                        this.ui.success(msg);

                        this.router.navigate(["/premios"]);
                    }
                    this.ui.unblock();
                });
            });
        }else{
            this.salvando = false;
        }

    }

    fileChangeEventFrente(event: any): void {
        this.imageChangedEventFrente = event;
    }

    imageCroppedFrente(image: string) {
        this.croppedImageFrente = image;
        this.cropperReadyFrente = true;
    }

    mudouModeloFrente() {
        if (this.modeloSelecionadoFrente.QRCode) {
            this.salvando = true;

            setTimeout(() => {
                this.qrCode = this.modeloSelecionadoFrente.QRCode;

                setTimeout(() => {
                    this.salvando = false;
                });
            });
        }

        if (
            this.modeloSelecionadoFrente &&
            this.modeloSelecionadoFrente.Campos
        ) {
            this.campoSelecionadoFrente = this.modeloSelecionadoFrente.Campos[0];
        } else {
            this.campoSelecionadoFrente = undefined;
        }
    }

    fileChangeEventVerso(event: any): void {
        this.imageChangedEventVerso = event;
    }

    imageCroppedVerso(image: string) {
        this.croppedImageVerso = image;
        this.cropperReadyVerso = true;
    }

    mudouModeloVerso() {
        if (this.modeloSelecionadoVerso && this.modeloSelecionadoVerso.Campos) {
            this.campoSelecionadoVerso = this.modeloSelecionadoVerso.Campos[0];
        } else {
            this.campoSelecionadoVerso = undefined;
        }
    }

    selecionarParceiro() {
        const dialogRef = this.dialog.open(SelecaoParceirosComponent, {
            width: "80vw",
            height: "85vh",
            data: {
                selection: "single"
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.model.Parceiro = result;
            }
        });
    }

    selecionarLojas() {
        const dialogRef = this.dialog.open(SelecaoParceirosComponent, {
            width: "80vw",
            height: "85vh",
            data: {
                selection: "multiple"
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.model.Lojas = result;
            }
        });
    }

    validar() {
        let podeSalvar: boolean = true;
        let reason: string;

        if (!this.model.Descricao) {
            podeSalvar = false;
            reason = "é necessário informar uma descrição para o mesmo.";
        } else if (!this.emissorMultiCtrl.value || !this.emissorMultiCtrl.value.ID) {
            podeSalvar = false;
            reason = "é necessário selecionar o parceiro emissor do mesmo.";
        } else if (!this.model.ID && !this.model.Quantidade) {
            podeSalvar = false;
            reason =
                "é necessário informar a quantidade de premios a ser criada.";
        } else if (!this.model.AlvoClientes && !this.model.AlvoColaboradores) {
            podeSalvar = false;
            reason = "é necessário selecionar o público alvo.";
        }

        if (!podeSalvar) {
            this.ui.error("Não foi possível salvar o premios pois " + reason);
        }

        if(!podeSalvar){
            this.ui.unblock();
        }

        return podeSalvar;
    }

    onKeypress(event, decimals) {
        if (event.key === "." || event.key === ",") {
            if (!decimals || decimals === 0) {
                event.preventDefault();
                return;
            }

            if (
                event.target.value
                    .substring(
                        event.target.selectionStart,
                        event.target.selectionEnd
                    )
                    .indexOf(",") >= 0 ||
                event.target.value.indexOf(",") < 0
            ) {
                let value =
                    event.target.value.substr(0, event.target.selectionStart) +
                    "," +
                    event.target.value.substr(event.target.selectionEnd);

                if (value.startsWith(",")) {
                    value = "0" + value;
                }

                event.target.value = value;
            }

            event.preventDefault();
        } else {
            const numeric = /^\d+$/.test(event.key);

            if (!numeric) {
                event.preventDefault();
            }
        }
    }

    formatNumber(event, decimals: number) {
        let currentValue = event.target.value;

        if (currentValue === "0,") {
            currentValue = "0";
        }

        if (currentValue.indexOf(",") >= 0) {
            currentValue = currentValue
                .split(".")
                .join("")
                .replace(",", ".");
        }

        let newValue = Number(currentValue).toLocaleString("pt-BR", {
            useGrouping: true,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });

        if (newValue === "NaN") {
            currentValue = 0;
            newValue = Number(currentValue).toLocaleString("pt-BR", {
                useGrouping: true,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            });
        }

        currentValue = Number(Number(currentValue).toFixed(decimals));

        setTimeout(() => {
            event.target.value = newValue;
        });
    }

    onPaste(event) {
        let pastedText;

        if (window.clipboardData && window.clipboardData.getData) {
            pastedText = window.clipboardData.getData("Text");
        } else if (event.clipboardData && event.clipboardData.getData) {
            pastedText = event.clipboardData.getData("text/plain");
        }

        if (isNaN(Number(pastedText))) {
            return false;
        }
    }

    lojasParticipantes() {
        return this.model.Lojas.map(a => a.Nome || a.Grupo || a.Rede).join(
            ", "
        );
    }

    parameter() {
        return {
            ID: this.model.ID,
            Descricao: this.model.Descricao,
            Parceiro: this.emissorMultiCtrl.value.ID || 0,
            // Validade: this.model.Validade ? this.model.Validade.toNET() : null,
            Quantidade: this.model.Quantidade,
            Clientes: this.model.AlvoClientes,
            ValorVenda: this.model.ValorVenda,
            Colaboradores: this.model.AlvoColaboradores,
            Vendedores: this.model.RetornoVendedores,
            Caixas: this.model.RetornoCaixas,
            Gerentes: this.model.RetornoGerentes,
            ImagemFrente: this.model.ImagemFrente,
            ImagemVerso: this.model.ImagemVerso,
            Lojas: this.parceirosMultiCtrl.value.map(loja => loja.ID),
            QRCode:
                (this.qrCode.PosicaoY
                    ? this.qrCode.PosicaoY + "px"
                    : "calc(50% - 50px)") +
                "|" +
                (this.qrCode.PosicaoX
                    ? this.qrCode.PosicaoX + "px"
                    : "calc(50% - 50px)") +
                "|" +
                (this.qrCode.Frente ? this.qrCode.Frente : "black") +
                "|" +
                (this.qrCode.Fundo ? this.qrCode.Fundo : "white"),
            Layout: this.layout(),
            // Pontos: this.model.Pontos,
            LimitePorCPF: this.model.LimitePorCPF,
            Limite: this.model.Limite,
            Utilizados: this.model.Utilizados,
            EstoqueIlimitado: this.model.EstoqueIlimitado
        };
    }
    ngAfterViewInit() {
        this.setInitialValue();
    }

    protected setInitialValue() {
        this.filteredParceirosMulti
            .pipe(
                take(1),
                takeUntil(this._onDestroy)
            )
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                this.multiSelect.compareWith = (a: Parceiro, b: Parceiro) =>
                    a && b && a.ID === b.ID;
            });
        this.filteredEmissorMulti
            .pipe(
                take(1),
                takeUntil(this._onDestroyEmissor)
            )
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                this.singleSelect.compareWith = (a: Parceiro, b: Parceiro) =>
                    a && b && a.ID === b.ID;
            });
    }
    protected filterParceiroMulti() {
        if (!this.parceiros) {
            return;
        }
        // get the search keyword
        let search = this.parceirosMultiFilerCtrl.value;
        const redeGroupsCopy = this.copyRedeGroups(this.redes);
        if (!search) {
            this.filteredParceirosMulti.next(redeGroupsCopy);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredParceirosMulti.next(
            redeGroupsCopy.filter(redeGroup => {
                const showRedeGroup =
                    redeGroup.Rede.toLowerCase().indexOf(search) > -1;
                if (!showRedeGroup) {
                    redeGroup.Parceiros = redeGroup.Parceiros.filter(
                        a => a.Nome.toLowerCase().indexOf(search) > -1
                    );
                }
                return redeGroup.Parceiros.length > 0;
            })
        );
    }
    protected filterEmissorMulti() {
        if (!this.parceiros) {
            return;
        }
        // get the search keyword
        let search = this.emissorMultiFilerCtrl.value;
        if (!search) {
            this.filteredEmissorMulti.next(this.parceiros.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredEmissorMulti.next(
            this.parceiros.filter(
                a => a.Nome.toLowerCase().indexOf(search) > -1
            )
        );
    }

    carregarParceiros(lojas: string[], emissor: string) {
        this.api.listarParceirosPorPerfil(1).subscribe((response: any) => {
            this.ui.unblock();
            if (!response.error) {
                // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fapp.component.html
                // exemplos de multiselect

                // this.parceiros = response.data.Parceiros;
                response.data.Parceiros.forEach(element => {
                    if (element.Nome && element.IsEmissor) {
                    //if (element.Nome) {
                        this.parceiros.push({
                            ID: element.ID,
                            Nome: element.Nome,
                            IsEmissor: element.IsEmissor
                        });
                    }
                });



                // this.model.Parceiro = this.parceiros.find((parceiro) => parceiro.ID === emissor);

                if (emissor)
                    this.emissorMultiCtrl.setValue(
                        this.parceiros.find(parceiro => parceiro.ID === emissor)
                    );

                this.filteredEmissorMulti.next(this.parceiros.slice());
                this.emissorMultiFilerCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroyEmissor))
                    .subscribe(() => {
                        this.filterEmissorMulti();
                    });
            }
        });
        this.api.listarParceirosPorPerfil(0).subscribe((response: any) => {
            if (!response.error) {

                // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fapp.component.html
                // exemplos de multiselect
                let parceiros=[];
                let p = response.data.Parceiros;
                // this.parceiros = response.data.Parceiros;

                response.data.Parceiros.forEach(element => {
                    if (element.Nome){//} && element.IsEmissor == true) {
                    //if (element.Nome) {
                        let p = {
                            ID: element.ID,
                            Nome: element.Nome
                        };
                        parceiros.push(p);
                        this.participantesCount = this.participantesCount + 1;
                    }
                });

                response.data.Parceiros.forEach(element => {
                    if (element.Nome) {
                        let rede = this.redes.find(a => a.Rede == element.Rede);
                        if (!rede) {
                            rede = { Parceiros: [], Rede: element.Rede };
                            this.redes.push(rede);
                        }
                        const p = { Nome: element.Nome, ID: element.ID, IsEmissor: element.IsEmissor };
                        rede.Parceiros.push(p);
                    }
                });
                this.filteredParceirosMulti.next(
                    this.copyRedeGroups(this.redes)
                );
                if (lojas) {
                    for (const loja of lojas) {
                        let parc = parceiros.find(
                            parceiro => parceiro.ID === loja
                        );
                        if(parc){
                            this.selectedItemsParceiros.push(parc);
                        }
                    }
                    this.parceirosMultiCtrl.setValue(
                        this.selectedItemsParceiros
                    );
                }

                this.parceirosMultiFilerCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterParceiroMulti();
                    });
            }
            this.ui.unblock();
        });
    }

    protected copyRedeGroups(redeGroups: Rede[]) {
        const redeGroupsCopy = [];
        redeGroups.forEach(redeGroup => {
            redeGroupsCopy.push({
                Rede: redeGroup.Rede,
                Parceiros: redeGroup.Parceiros.slice()
            });
        });
        return redeGroupsCopy;
    }
}

export interface Parceiro {
    ID: string;
    Nome: string;
    IsEmissor: boolean
}
export interface Rede {
    Rede: string;
    Parceiros: Parceiro[];
}
