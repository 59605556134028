import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';

@Component({
  selector: 'cs-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {

  @ViewChild('email') email;

  public model: any = {};
  public errors: any = {};

  private subscription: any;
  private codigoRedefinicaoSenha: String;

  constructor(private ui: UIService, private router: Router, public api: APIService, private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.subscription = this.route.params.subscribe(

      (params: any) => {

          const codigoRedefinicaoSenha: String = params['codigoRedefinicaoSenha'];
          this.codigoRedefinicaoSenha = codigoRedefinicaoSenha;

          if (codigoRedefinicaoSenha) {

              this.ui.block();

              const parameter: any = {
                CodigoRedefinicaoSenha: codigoRedefinicaoSenha
              };

              this.api.obterEmailRedefinicaoSenha(parameter).subscribe(

                  (response: any) => {

                      this.ui.unblock();

                      if (response.error) {

                          this.ui.error(response.error);
                          this.router.navigate(['/home']);
                      }
                      else {

                          this.model.Email = response.data.Email;
                      }
                  }
              );
          }
      }
    );

    setTimeout(() => { this.email.nativeElement.focus(); });
  }

  clearError(field: string) {

      this.errors[field] = undefined;
  }

  validate(field?: string) {

    if (!field || field === 'NovaSenha') {

      if (!this.model.NovaSenha || !this.model.ConfirmarNovaSenha) {
        this.errors.Senha = 'A nova senha e a confirmação da nova senha são obrigatórias.';
      }

      if (this.model.NovaSenha != this.model.ConfirmarNovaSenha) {

        this.errors.ConfirmarSenha = 'Nova senha e confirmação de nova senha não conferem.';
      }
      else if (!/[0-9]+/.test(this.model.NovaSenha)
        || !/[a-zA-Z]+/.test(this.model.NovaSenha)
        || !this.model.NovaSenha
        || this.model.NovaSenha.length < 8) {
        this.errors.NovaSenha = 'Informe senha de tamanho mínimo 8 caracteres que contenha letras e números.';
      }
      
    }

    if (!this.codigoRedefinicaoSenha || this.codigoRedefinicaoSenha.trim().length == 0){
      this.ui.error("Código de redefinição inválido.");
      return "codigo";
    }

    return ((JSON.stringify(this.errors)).length - 2);
  }

  redefinirSenha() {

    this.errors = {};
    const errors = this.validate();

    if (!errors) {

        const parameter: any = {
          Email: this.model.Email,
          Senha: this.model.NovaSenha,
          CodigoRedefinicaoSenha: this.codigoRedefinicaoSenha
        };

        this.ui.block();

        this.api.redefinirSenha(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.errors.Email = response.error;
                }
                else {

                    this.ui.success('Senha redefinida com sucesso.');
                    this.router.navigate(['/login']);
                }
            }
        );
    }
    else {

        this.ui.error('Por favor verifique os campos inválidos.');
    }
  }
}
