import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';

@Component({
    selector: 'cs-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @ViewChild('email') email;

    public model: any = {
        // Email: 'admin@trybu.com.br',
        // Senha: '123'
    };

    public errors: any = {};

    public keepConnected: boolean = true;

    constructor(private ui: UIService, private router: Router, public api: APIService) {
    }

    ngOnInit() {
        setTimeout(() => { this.email.nativeElement.focus(); });
    }

    clearError(field: string) {

        this.errors[field] = undefined;
    }

    validate(field?: string) {

        if (!field || field === 'Email') {

            if (!this.model.Email) {

                this.errors.Email = 'Por favor informe seu Login.';
            }
            // else {

                // const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                // if (!expression.test(String(this.model.Email).toLowerCase())) {

                //     this.errors.Email = 'Endereço de e-mail inválido.';
                // }
           // }
        }

        if (!field || field === 'Senha') {

            if (!this.model.Senha) {

                this.errors.Senha = 'A senha é obrigatória.';
            }
        }

        return ((JSON.stringify(this.errors)).length - 2);
    }

    entrar() {

        this.errors = {};
        const errors = this.validate();

        if (!errors) {

            const parameter: any = {
                Email: this.model.Email,
                Senha: this.model.Senha,
                Code: '2'
            };

            this.ui.block();
            this.api.token = null;
            this.api.login(parameter, this.keepConnected).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.errors.Senha = response.error;
                    }
                    else {

                        this.router.navigate(['/home']);
                    }
                }
            );
        }
        else {

            this.ui.error('Por favor verifique os campos inválidos.');
        }
    }

    registrar() {

        this.router.navigate(['/registrar']);
    }

}
