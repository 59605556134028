import { AppConfigService } from './../services/app.config.service';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '../routes/app-routing.module';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatDialogModule } from '@angular/material';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../modules/material.module';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ColorPickerModule } from 'ngx-color-picker';
import { FontPickerModule, FONT_PICKER_CONFIG, FontPickerConfigInterface, FontPickerService } from 'ngx-font-picker';
import { QRCodeModule } from 'angularx-qrcode';

import { StartWithPipe } from '../pipes/start-with.pipe';
import { SafeUrlPipe, SafeHtmlPipe, SafeStylePipe } from '../pipes/safe.pipe';

import { UIService } from '../services/ui.service';
import { StorageService } from '../services/storage.service';
import { APIService } from '../services/api.service';

import { AppComponent } from './app.component';

import { MenuComponent } from '../components/menu/menu.component';
import { MapDialogComponent } from '../components/map-dialog/map-dialog.component';
import { SelecaoParceirosComponent } from '../components/selecao-parceiros/selecao-parceiros.component';
import { SelecaoVouchersComponent } from '../components/selecao-vouchers/selecao-vouchers.component';
import { SelecaoPremiosComponent } from '../components/selecao-premios/selecao-premios.component';
import { SelecaoComunicadosComponent } from '../components/selecao-comunicados/selecao-comunicados.component';

import { LoginComponent } from '../pages/login/login.component';
import { HomeComponent } from '../pages/home/home.component';

import { ComunicadosComponent, DialogVisualizarComunicadosComponent } from '../pages/comunicados/comunicados.component';
import { ComunicadoComponent } from '../pages/comunicado/comunicado.component';

import { ParceirosComponent } from '../pages/parceiros/parceiros.component';
import { ParceiroComponent } from '../pages/parceiro/parceiro.component';

import { EstabelecimentosComponent } from '../pages/estabelecimentos/estabelecimentos.component';
import { EstabelecimentoComponent } from '../pages/estabelecimento/estabelecimento.component';

import { VendasComponent } from '../pages/vendas/vendas.component';
import { VendaComponent, DialogReciboComponent } from '../pages/venda/venda.component';

import { ParcelasComponent } from '../pages/parcelas/parcelas.component';

import { ReportsComponent } from '../pages/reports/reports.component';
import { ConfigReportsComponent } from '../pages/config-reports/config-reports.component';
import { ConfigReportComponent } from '../pages/config-report/config-report.component';

import { VouchersComponent, DialogMaisVouchersComponent, DialogVisualizarVouchersComponent } from '../pages/vouchers/vouchers.component';
import { VoucherComponent } from '../pages/voucher/voucher.component';

import { PremiosComponent, DialogMaisPremiosComponent, DialogVisualizarPremiosComponent } from '../pages/premios/premios.component';
import { PremioComponent } from '../pages/premio/premio.component';

import { GincanasComponent } from '../pages/gincanas/gincanas.component';
import { GincanaComponent } from '../pages/gincana/gincana.component';

import { CampanhasComponent } from '../pages/campanhas/campanhas.component';
import { CampanhaComponent } from '../pages/campanha/campanha.component';

import { ConfiguracoesComponent } from '../pages/configuracoes/configuracoes.component';

import { OperacoesComponent, DialogConfirmacaoEnvioVoucherComponent, DialogConfirmacaoEnvioPremioComponent, DialogConfirmacaoEnvioComunicadoComponent, DialogConfirmacaoEnvioPontosComponent } from '../pages/operacoes/operacoes.component';

import { RelatoriosComponent } from '../pages/relatorios/relatorios.component';

import { ViewComponent } from '../pages/view/view.component';

import { registerLocaleData } from '@angular/common';

import localePt from '@angular/common/locales/pt';

import * as moment from 'moment';

import { ColaboradoresComponent } from '../pages/pages-parceiro/colaboradores/colaboradores.component';
import { ConfirmarEmailComponent } from '../pages/confirmar-email/confirmar-email.component';
import { CriarContaComponent } from '../pages/criar-conta/criar-conta.component';
import { EsqueceuSenhaComponent } from '../pages/esqueceu-senha/esqueceu-senha.component';
import { RedefinirSenhaComponent } from '../pages/redefinir-senha/redefinir-senha.component';
import { ConsultoresComponent } from '../pages/consultores/consultores.component';
import { ConsultorComponent } from '../pages/consultor/consultor.component';
import { CPFPipe } from '../pipes/cpf.pipe';
import { ListaImagensComponent } from '../components/lista-imagens/lista-imagens.component';
import { ConfCobrancasComponent } from '../pages/conf-cobrancas/conf-cobrancas.component';
import { ConfCobrancaComponent, DialogVisualizarAcoesCobrancaComponent, DialogCodigoRetornoAcoesCobrancaComponent, DialogComunicacaoAcoesCobrancaComponent, DialogMessageComunicadoCobrancaComponent } from '../pages/conf-cobranca/conf-cobranca.component';
import { RedePagamentosComponent } from '../pages/rede-pagamentos/rede-pagamentos.component';
import { RedePagamentoComponent } from '../pages/rede-pagamento/rede-pagamento.component';
import { ExecucaoAcaoCobrancasComponent } from '../pages/exec-acao-cobrancas/exec-acao-cobrancas.component';
import { ExecucaoAcaoCobrancasComunicadosComponent } from '../pages/exec-acao-cobrancas-comunicados/exec-acao-cobrancas-comunicados.component';
import { GrupoComponent } from '../pages/grupos/grupo.component';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
    apiKey: 'AIzaSyBvlq223KY7L5miokg0o6zffVJfFzawjzs'
};

//https://devblogs.microsoft.com/premier-developer/angular-how-to-editable-config-files/
export function initializeApp(appConfig: AppConfigService) {
    return () => appConfig.load();
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM [de] YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM [de] YYYY',
    }
};

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        StartWithPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        SafeStylePipe,
        MenuComponent,
        MapDialogComponent,
        SelecaoParceirosComponent,
        LoginComponent,
        HomeComponent,
        ComunicadosComponent,
        ComunicadoComponent,
        ParceirosComponent,
        ParceiroComponent,
        EstabelecimentosComponent,
        EstabelecimentoComponent,
        VendasComponent,
        VendaComponent,
        ParcelasComponent,
        ReportsComponent,
        ConfigReportsComponent,
        ConfigReportComponent,
        VoucherComponent,
        VouchersComponent,
        PremioComponent,
        PremiosComponent,
        GincanasComponent,
        GincanaComponent,
        CampanhasComponent,
        CampanhaComponent,
        ConfiguracoesComponent,
        SelecaoVouchersComponent,
        SelecaoPremiosComponent,
        SelecaoComunicadosComponent,
        DialogMaisVouchersComponent,
        DialogMaisPremiosComponent,
        DialogVisualizarVouchersComponent,
        DialogVisualizarPremiosComponent,
        DialogVisualizarComunicadosComponent,
        OperacoesComponent,
        DialogConfirmacaoEnvioVoucherComponent,
        DialogConfirmacaoEnvioPremioComponent,
        DialogConfirmacaoEnvioComunicadoComponent,
        DialogConfirmacaoEnvioPontosComponent,
        ViewComponent,
        RelatoriosComponent,
        ColaboradoresComponent,
        ConfirmarEmailComponent,
        CriarContaComponent,
        EsqueceuSenhaComponent,
        RedefinirSenhaComponent,
        ConsultoresComponent,
        ConsultorComponent,
        CPFPipe,
        ListaImagensComponent,
        ConfCobrancasComponent,
        ConfCobrancaComponent,
        DialogVisualizarAcoesCobrancaComponent,
        DialogCodigoRetornoAcoesCobrancaComponent,
        DialogComunicacaoAcoesCobrancaComponent,
        DialogMessageComunicadoCobrancaComponent,
        RedePagamentosComponent,
        RedePagamentoComponent,
        ExecucaoAcaoCobrancasComponent,
        ExecucaoAcaoCobrancasComunicadosComponent,
        DialogReciboComponent,
        GrupoComponent
    ],
    entryComponents: [
        MenuComponent,
        ListaImagensComponent,
        MapDialogComponent,
        SelecaoParceirosComponent,
        SelecaoVouchersComponent,
        SelecaoPremiosComponent,
        SelecaoComunicadosComponent,
        ConfirmarEmailComponent,
        DialogMaisVouchersComponent,
        DialogMaisPremiosComponent,
        DialogVisualizarVouchersComponent,
        DialogVisualizarPremiosComponent,
        DialogVisualizarComunicadosComponent,
        DialogConfirmacaoEnvioVoucherComponent,
        DialogConfirmacaoEnvioPremioComponent,
        DialogConfirmacaoEnvioComunicadoComponent,
        DialogConfirmacaoEnvioPontosComponent,
        DialogVisualizarAcoesCobrancaComponent,
        DialogCodigoRetornoAcoesCobrancaComponent,
        DialogComunicacaoAcoesCobrancaComponent,
        DialogMessageComunicadoCobrancaComponent,
        DialogReciboComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        FlexLayoutModule,
        MaterialModule,
        MatDialogModule,
        BlockUIModule.forRoot({
            delayStart: 250
        }),
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 1,
            enableHtml: true,
            progressBar: true,
            progressAnimation: 'increasing',
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true
        }),
        NgxMaskModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB25sY1y3OV4YI3VdBTKY3cgHTfTXVUWKs',
            libraries: ['places']
        }),
        ImageCropperModule,
        ColorPickerModule,
        FontPickerModule,
        QRCodeModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'pt'
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS
        },
        FontPickerService,
        {
            provide: FONT_PICKER_CONFIG,
            useValue: DEFAULT_FONT_PICKER_CONFIG
        },
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfigService], 
            multi: true
        },
        StartWithPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        SafeStylePipe,
        UIService,
        StorageService,
        APIService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}

moment.prototype.toNET = function () {

    return '/Date(' + this.toDate().getTime() + ')/';
};
