import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform  {

    constructor(private sanitized: DomSanitizer) {

    }

    transform(value) {

        return this.sanitized.bypassSecurityTrustUrl(value);
    }
}

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform  {

    constructor(private sanitized: DomSanitizer) {

    }

    transform(value) {

        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({
    name: 'safeStyle'
})
export class SafeStylePipe implements PipeTransform  {

    constructor(private sanitized: DomSanitizer) {

    }

    transform(value) {

        return this.sanitized.bypassSecurityTrustStyle(value);
    }
}
