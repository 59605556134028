import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from '../../services/storage.service';

import * as moment from 'moment';
import { SelecaoVouchersComponent } from '../../components/selecao-vouchers/selecao-vouchers.component';
import { SelecaoComunicadosComponent } from '../../components/selecao-comunicados/selecao-comunicados.component';
import { SelecaoPremiosComponent } from 'src/app/components/selecao-premios/selecao-premios.component';
import { ListaImagensComponent } from 'src/app/components/lista-imagens/lista-imagens.component';

@Component({
    selector: 'cs-operacoes',
    templateUrl: './operacoes.component.html',
    styleUrls: ['./operacoes.component.scss']
})
export class OperacoesComponent implements OnInit {

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public campanhas: any[] = [];

    public model: any = {
        TipoColaborador:0
    };

    public colaboradores: any[] = [];
    public redes: any[] = [];
    public grupos: any[] = [];
    public planosPontuacao: any[] = [];

    public total: number;
    public dataSource = new MatTableDataSource<any>(this.colaboradores);
    public selection = new SelectionModel<any>(true, []);

    public pageSize: number = 20;

    public tiposColaborador: any[] = [
        {Id: 0, Name: "Todos"},
        {Id: 1, Name: "Parceiro"},
        {Id: 2, Name: "Estabelecimento"}];

    constructor(private ui: UIService, private api: APIService, private storage: StorageService, public dialog: MatDialog) {
    }

    ngOnInit() {

        this.api.obterCampanhas({ Take: 9999, Sort: 'Nome|asc' }).subscribe(

            (response: any) => {

                if (!response.error) {

                    this.campanhas = response.data.Campanhas;
                }
            }
        );

        setTimeout(() => {

            this._columns = ['Selecionado', 'Nome', 'Telefone', 'Loja', 'TipoColaborador', 'Entregas', 'Resgates', 'Confirmados', 'Atendidos'];

            this.paginator.pageSize = this.pageSize;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.loadSession();
        });
        const parameter: any = JSON.parse(JSON.stringify(this.model));

        this.api.ListaPlanosPontuacao(parameter).subscribe(

            (response: any) => {

                if (!response.error) {
                    this.planosPontuacao = response.data.Itens;
                }
            }
        );
    }

    trocarTipoColaborador(event){
        let tipoId = event.value;
        let tipoColaborador = this.obterTipoColaborador(tipoId);
        const parameter: any = JSON.parse(JSON.stringify(this.model));
        if(tipoColaborador == "Parceiro"){
            this.api.obterRedesParceiros(parameter).subscribe(
                (response: any) => {

                    if (response.error) {
                        this.ui.error(response.error);
                    }
                    else {
                        this.redes = response.data.RedesParceiros;
                    }
                }
            );
        }

        if(tipoColaborador == "Estabelecimento"){
            this.api.listarRedes(parameter).subscribe(
                (response: any) => {

                    if (response.error) {
                        this.ui.error(response.error);
                    }
                    else {
                        this.redes = response.data.Redes;
                    }
                }
            );
        }

    }

    obterTipoColaborador(tipoId){
        let res = this.tiposColaborador.find(x=>x.Id == tipoId).Name;
        return res;
    }

    obterFuncaoOuGrupo(element){
        let res = '';
        let tipoColaborador = this.obterTipoColaborador(element.TipoColaborador);
        if(tipoColaborador == "Parceiro"){
            res = 'Função: ';
        }
        if(tipoColaborador == "Estabelecimento"){
            res = 'Grupo: ';
        }
        res = res + element.FuncaoOuGrupo
        return res;
    }

    selectionChanged(event)
    {
        let tipoColaborador = this.obterTipoColaborador(this.model.TipoColaborador);
        if(tipoColaborador == "Parceiro"){
            this.api.obterGruposParceiros (event.value).subscribe(

                (response: any) => {

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {
                        //this.total = response.data.Total;
                        this.grupos = response.data.GruposParceiros;
                    }
                }
            );
        }
        if(tipoColaborador == "Estabelecimento"){

            let par:any = {
                Grupo:{
                    Rede:{
                        ID: event.value
                    }
                }
            };
            this.api.obterGrupos (par).subscribe(

                (response: any) => {

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {
                        this.grupos = response.data.Grupos;
                    }
                }
            );
        }

    }

    limparFiltro() {

        this.model = {};
    }

    filtrar(page?: number) {

        this.ui.block('Pesquisando colaboradores...');

        const skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;

        const parameter: any = JSON.parse(JSON.stringify(this.model));

        if (parameter.DataDe && moment(parameter.DataDe).isValid()) {

            parameter.DataDe = (moment(parameter.DataDe) as any).toNET();
        }

        if (parameter.DataAte && moment(parameter.DataAte).isValid()) {

            parameter.DataAte = (moment(parameter.DataAte) as any).toNET();
        }

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        if (this.sort && this.sort.direction) {

            parameter.Sort = this.sort.active + '|' + this.sort.direction;
        }
        else {

            parameter.Sort = 'Nome|asc';
        }

        this.api.listarColaboradorComEvento(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    this.colaboradores = response.data.Colaboradores;

                    const pagina = this.colaboradores.slice(0, this.paginator.pageSize);

                    this.dataSource = new MatTableDataSource<any>(pagina);
                    this.selection = new SelectionModel<any>(true, []);

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    rowSelected(row: any) {

        this.selection.toggle(row);
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Nome';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    enviarSMS() {
    }

    enviarPremio() {

        this.storage.set('session', null);

        const dialogRef = this.dialog.open(ListaImagensComponent, {
            width: 'calc(90%)',
            height: 'calc(80%)',
            data: {
                selection: 'single',
                tipo: 'Premios',
                publico: 'Colaboradores'
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {

                const voucherID: number = result.ID;

                const dialogRef = this.dialog.open(DialogConfirmacaoEnvioPremioComponent, {
                    width: '420px',
                    data: { voucher: result }
                });

                dialogRef.afterClosed().subscribe(result => {

                    if (result) {

                        this.ui.block();

                        const parameter = {
                            Voucher: voucherID,
                            Colaboradores: this.selection.selected.map((colaborador) => colaborador.ID)
                        };

                        this.api.enviarVoucher(parameter).subscribe(

                            (response: any) => {

                                this.ui.unblock();

                                if (!response.error) {

                                    this.ui.success('Prêmio(s) enviado(s) com sucesso.');
                                }
                                else {

                                    this.ui.error(response.error);
                                }
                            }
                        );
                    }
                });
            }
        });
    }

    enviarComunicado() {

        this.storage.set('session', null);


        const dialogRef = this.dialog.open(ListaImagensComponent, {
            width: 'calc(90%)',
            height: 'calc(80%)',
            data: {
                selection: 'single',
                tipo: 'Comunicados',
                publico: 'Colaboradores'
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {

                const comunicadoID: number = result.ID;

                const dialogRef = this.dialog.open(DialogConfirmacaoEnvioComunicadoComponent, {
                    width: '420px',
                    data: { comunicado: result }
                });

                dialogRef.afterClosed().subscribe(result => {

                    if (result) {

                        this.ui.block();

                        const parameter = {
                            Comunicado: comunicadoID,
                            AgentesIDs: this.selection.selected.map((colaborador) => colaborador.AgenteID)
                        };

                        this.api.enviarComunicado(parameter).subscribe(

                            (response: any) => {

                                this.ui.unblock();

                                if (!response.error) {

                                    this.ui.success('Comunicado(s) enviado(s) com sucesso.');
                                }
                                else {

                                    this.ui.error(response.error);
                                }
                            }
                        );
                    }
                });
            }
        });
    }

    enviarPontos() {

        const dialogRef = this.dialog.open(DialogConfirmacaoEnvioPontosComponent, {
            width: '300px',
            data: { planosPontuacao: this.planosPontuacao }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {

                this.ui.block();

                const parameter = {
                    Pontos: result.pontos,
                    Observacao: result.observacao,
                    PlanoPontuacao: result.planoPontuacao,
                    Colaboradores: this.selection.selected.map((colaborador) => colaborador.ID),
                    ColaboradorComUsuarioID: true
                };

                this.api.enviarPontos(parameter).subscribe(

                    (response: any) => {

                        this.ui.unblock();

                        if (!response.error) {

                            this.ui.success('Ponto(s) enviado(s) com sucesso.');
                        }
                        else {

                            this.ui.error(response.error);
                        }
                    }
                );
            }
        });
    }
}

@Component({
    selector: 'cs-confirmacao-envio-voucher',
    template: `
        <div style="padding: 24px; text-align: center;">

            <h1 mat-dialog-title>Presentear com Voucher</h1>

            <div mat-dialog-content>

                <p>Confirma o envio do voucher <u>{{data.voucher.Descricao}}</u> para os colaboradores selecionados?</p>

            </div>

            <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-button (click)="cancel()">Não</button>
                <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">Sim, enviar!</button>
            </div>

        </div>
    `,
})
export class DialogConfirmacaoEnvioVoucherComponent {

    constructor(public dialogRef: MatDialogRef<DialogConfirmacaoEnvioVoucherComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    cancel(): void {

        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-confirmacao-envio-premio',
    template: `
        <div style="padding: 24px; text-align: center;">

            <h1 mat-dialog-title>Enviar Prêmio</h1>

            <div mat-dialog-content>

                <p>Confirma o envio do prêmio <u>{{data.voucher.Descricao}}</u> para os colaboradores selecionados?</p>

            </div>

            <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-button (click)="cancel()">Não</button>
                <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">Sim, enviar!</button>
            </div>

        </div>
    `,
})
export class DialogConfirmacaoEnvioPremioComponent {

    constructor(public dialogRef: MatDialogRef<DialogConfirmacaoEnvioPremioComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    cancel(): void {

        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-confirmacao-envio-comunicado',
    template: `
        <div style="padding: 24px; text-align: center;">

            <h1 mat-dialog-title>Enviar Comunicado</h1>

            <div mat-dialog-content>

                <p>Confirma o envio do comunicado <u>{{data.comunicado.Descricao}}</u> para os colaboradores selecionados?</p>

            </div>

            <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-button (click)="cancel()">Não</button>
                <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">Sim, enviar!</button>
            </div>

        </div>
    `,
})
export class DialogConfirmacaoEnvioComunicadoComponent {

    constructor(public dialogRef: MatDialogRef<DialogConfirmacaoEnvioComunicadoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    cancel(): void {

        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-confirmacao-envio-pontos',
    template: `
        <div style="padding: 24px; text-align: center;">

            <h1 mat-dialog-title>Envio de Pontos</h1>

            <div mat-dialog-content>
                <mat-form-field floatLabel="always" style="width: 150px;">
                    <mat-select #inputPlanoPontuacao placeholder="Plano Pontuacao">
                        <mat-option *ngFor="let plano of data.planosPontuacao" [value]="plano.ID">{{plano.Nome}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div mat-dialog-content>
                <mat-form-field floatLabel="always" style="width: 200px;" fxFlex="1 1 auto">
                    <input #observacao matInput placeholder="Observação" style="text-align: left;">
                </mat-form-field>

                <mat-form-field floatLabel="always" style="width: 70px;">
                    <input #pontos type="number" min="0" matInput placeholder="Pontos" style="text-align: right;">
                </mat-form-field>
            </div>

            <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-button (click)="cancel()">{{pontos.value  && observacao.value && inputPlanoPontuacao.value ? 'Não' : 'Cancelar'}}</button>
                <button *ngIf="pontos.value && observacao.value && inputPlanoPontuacao.value" mat-button mat-raised-button color="primary"
                [mat-dialog-close]="{
                    'observacao': observacao.value,
                    'pontos': pontos.value,
                    'planoPontuacao': inputPlanoPontuacao.value
                }">Sim, enviar!</button>
            </div>
            <div>
                <br>
                <p *ngIf="pontos.value  && observacao.value && inputPlanoPontuacao.value" style="font-weight: bold;color: red;">Confirma o envio de <u>{{pontos.value}} ponto{{pontos.value > 1 ? 's' : ''}}</u> para os colaboradores selecionados?</p>
            </div>

        </div>
    `,
})
export class DialogConfirmacaoEnvioPontosComponent {

    constructor(public dialogRef: MatDialogRef<DialogConfirmacaoEnvioPontosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    cancel(): void {

        this.dialogRef.close();
    }
}
