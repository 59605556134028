import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from "@angular/router";
import { Observable } from "rxjs";

import { APIService } from "./../services/api.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {
    admComponents: string[] = [
        "ComunicadosComponent",
        "ComunicadoComponent",
        "ParceirosComponent",
        "ParceiroComponent",
        "EstabelecimentosComponent",
        "EstabelecimentoComponent",
        "VouchersComponent",
        "VoucherComponent",
        "PremioComponent",
        "PremiosComponent",
        "GincanasComponent",
        "GicanaComponent",
        "CampanhasComponent",
        "CampanhaComponent",
        "OperacoesComponent",
        "RelatoriosComponent",
        "HomeComponent",
        "LoginComponent",
        "MenuComponent",
        "ConfiguracoesComponent",
        "VendasComponent",
        "VendaComponent"
    ];

    consultorComponents: string[] = [
        "ParceirosComponent",
        "ParceiroComponent",
        "RelatoriosComponent",
        "ViewComponent",
        "HomeComponent",
        "LoginComponent",
        "MenuComponent",
        "ConfiguracoesComponent"
    ];

    parceiroComponents: string[] = [
        "ColaboradoresComponent",
        "HomeComponent",
        "LoginComponent",
        "MenuComponent",
        "ConfiguracoesComponent"
    ];

    colaboradorComponents: string[] = [
        "HomeComponent",
        "LoginComponent",
        "MenuComponent",
        "ConfiguracoesComponent"
    ];

    clienteComponents: string[] = [
        "HomeComponent",
        "LoginComponent",
        "MenuComponent",
        "ConfiguracoesComponent"
    ];

    constructor(private router: Router, private api: APIService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const logged: boolean = this.api.token ? true : false;
        let authorized: boolean = false;

        if (!logged) {
            this.router.navigate(["/login"]);
            return (authorized = false);
        }

        authorized = this.podeAcessarComponente(next);

        return authorized;
    }

    isAdmin(): boolean {
        const authorized: boolean = this.api.token ? true : false;

        return authorized && this.api.usuario.AdminID != 0;
    }

    isParceiro(): boolean {
        const authorized: boolean = this.api.token ? true : false;

        return authorized && this.api.usuario.ParceiroID != 0;
    }

    isColaborador(): boolean {
        const authorized: boolean = this.api.token ? true : false;

        return authorized && this.api.usuario.ColaboradorID != 0;
    }

    isCliente(): boolean {
        const authorized: boolean = this.api.token ? true : false;

        return authorized && this.api.usuario.ClienteID != 0;
    }

    isConsultor(): boolean {
        const authorized: boolean = this.api.token ? true : false;

        return authorized && this.api.usuario.ConsultorID != 0;
    }

    isAnyUser(): boolean {
        return (
            this.isAdmin() ||
            this.isConsultor() ||
            this.isParceiro() ||
            this.isColaborador() ||
            this.isCliente()
        );
    }

    podeAcessarComponente(next: ActivatedRouteSnapshot): boolean {
        if (
            next.routeConfig.path == "home" ||
            next.routeConfig.path == "configuracoes"
        )
            return true;
        if (this.api.menu.length == 0) this.api.menu = this.api.usuario.Menu;
        var rota = next.routeConfig.path;
        var partesRota = rota.split("/");
        if (partesRota.length > 0) {
            rota = partesRota[0];
        }
        if (this.api.menu && this.api.menu.find(a => a.Route == rota || (a.Items != null && a.Items.find(i=> i.Route == rota))))
            return true;

        return false;
    }
}
