import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { APIService } from './../../services/api.service';
import { UIService } from './../../services/ui.service';

@Component({
  selector: 'cs-confirmar-email',
  templateUrl: './confirmar-email.component.html',
  styleUrls: ['./confirmar-email.component.scss']
})
export class ConfirmarEmailComponent implements OnInit, OnDestroy {

  private subscription: any;
  public model: any = {};

  constructor(private api: APIService, private ui: UIService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.subscription = this.route.params.subscribe(

      (params: any) => {

          const codigoConfirmacao: String = params['codigoConfirmacao'];

          if (codigoConfirmacao) {

              this.ui.block();

              const parameter: any = {
                  CodigoConfirmacao: codigoConfirmacao
              };

              this.api.confirmarEmail(parameter).subscribe(

                  (response: any) => {

                      this.ui.unblock();

                      if (response.error) {

                          this.ui.error(response.error);
                          this.router.navigate(['/home']);
                      }
                      else {

                          this.model.Email = response.data.Email;
                      }
                  }
              );
          }
      }
    );
  }

  ngOnDestroy() {

    this.subscription.unsubscribe();
  }

  loginPage() {

    this.router.navigate(['/login']);
  }
}
