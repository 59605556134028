import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSelect } from '@angular/material';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

import { SelecaoVouchersComponent } from './../../components/selecao-vouchers/selecao-vouchers.component';
import { SelecaoParceirosComponent } from './../../components/selecao-parceiros/selecao-parceiros.component';
import { SelecaoComunicadosComponent } from '../../components/selecao-comunicados/selecao-comunicados.component';

import * as moment from 'moment';
import { ListaImagensComponent } from 'src/app/components/lista-imagens/lista-imagens.component';
import { Subject, ReplaySubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { element } from '@angular/core/src/render3/instructions';

declare var window: any;

@Component({
    selector: 'cs-campanha',
    templateUrl: './campanha.component.html',
    styleUrls: ['./campanha.component.scss']
})

export class CampanhaComponent implements OnInit, OnDestroy {

    @ViewChild('inputNome') inputNome: ElementRef;
    @ViewChild('valorVenda') valorVenda: ElementRef;
    @ViewChild("multiSelect") multiSelect: MatSelect;

    public parceirosMultiCtrl: FormControl = new FormControl();
    public parceirosMultiFilerCtrl: FormControl = new FormControl();
    public filteredParceirosMulti: ReplaySubject<Rede[]> = new ReplaySubject<
        Rede[]
    >(1);

    protected _onDestroy = new Subject<void>();
    public selectedItemsParceiros = [];
    public parceiros: Parceiro[] = [];
    public redes: Rede[] = [];
    public parceirosApi=[];


    public tiposParticipante: string[] =  ['Estabelecimento', 'Parceiro', 'Colaborador-Parceiro', 'Usuário-Estabelecimento', 'Rede-Estabelecimento', 'Rede-Parceiro', 'Lista-Distribuidor'];// ['Canal', 'Agente', 'Rede'];

    public tipoRegraDistribuicao: string[] = ['Unspecified', 'Canal', 'Agente', 'Rede', 'LimiteGeral', 'ClienteParceiro', 'LimiteClienteParceiro', 'RedeParceiro', 'ListaDistribuidor'];

    private subscription: any;
    public publico: string = 'Clientes';

    public curParti: string = "";
    public acao: string;
    planos = [];
    edicao = false;
    listRedes:any[] = [];
    redeUtilizaTrybuClienteParceiro: Boolean = false

    public labelComunicadoRegras: string = "";
    public hintComunicadoRegras: string  = "";
    public unityIdEmissor:Number = null;

    private participanteObj: any = {
        ID:0,
        Nome:"",
        Ilimitado:false,
        Quantidade:0,
        Disponiveis:0,
        TipoRegraDistribuicaoParticipante:0,
        TipoParticipante:"",
        Inativo:false
    }

    public model: any = {
        ID: 0,
        Participantes: [],
        Inicio: moment().startOf('day'),
        PlanoID: null,
        TipoParticipante:"",
        LimiteGeral:null,
        Influencer: false,
        ClienteParceiro:false,
        ClienteParceiroIlimitado:false,
        LimitePorClienteParceiro:0,
        DescricaoLinkPreview:null,
        TituloLinkPreview:null,
        CompartilhamentoRede: false,
        CompartilhamentoIndividual: false,
        Inativo:false
    };

    public permissoes;

    public ilimitado = false;

    public hgt = '50';

    constructor(public dialog: MatDialog, private ui: UIService, public api: APIService,
        private router: Router, private route: ActivatedRoute,
        private storage: StorageService) {

    }

    ngOnInit() {

        this.subscription = this.route.params.subscribe(

            (params: any) => {

                const id: string = params['id'];



                this.ui.block('Pesquisando redes...');
                this.api.listarRedes({}).subscribe(

                    (response: any) => {

                        this.ui.unblock();

                        if (response.error) {

                            this.ui.error(response.error);
                        }
                        else {
                            this.listRedes = response.data.Redes;
                            this.redeUtilizaTrybuClienteParceiro = this.listRedes.find(x => x.UtilizaTrybuClienteParceiro === true) != null;
                        }
                    }
                );



                if (id != '0') {

                    this.ui.block();

                    const parameter: any = {
                        ID: id
                    };
                   // this.carregarParceiros(null);
                    this.api.obterCampanha(parameter).subscribe(

                        (response: any) => {

                            this.ui.unblock();

                            if (response.error) {

                                this.ui.error(response.error);
                                this.router.navigate(['/campanhas']);
                            }
                            else {

                                const campanha: any = response.data.Campanha;

                                this.api.listaPlanosPontuacao({UnityIdEmissorVoucher: this.unityIdEmissor}).subscribe(
                                    (response: any) => {
                                        if (response.error) {
                                            this.ui.error(response.error);
                                            this.router.navigate(['/campanhas']);
                                        }
                                        else {
                                            this.model.Comunicado = null;
                                            this.resetProcessData();
                                            this.planos = response.data.Itens;
                                        }
                                    }
                                )

                                this.model.ID = campanha.ID;
                                this.model.Nome = campanha.Nome;
                                this.model.Inicio = moment(campanha.Inicio);
                                this.model.Termino = campanha.Termino ? moment(campanha.Termino) : null;
                                this.model.Entrega = campanha.Entrega;
                                this.model.Resgate = campanha.Resgate;
                                this.model.Confirmacao = campanha.Confirmacao;
                                this.model.Atendimento = campanha.Atendimento;
                                this.model.EntregaResponsavel = campanha.EntregaResponsavel;
                                this.model.ResgateResponsavel = campanha.ResgateResponsavel;
                                this.model.ConfirmacaoResponsavel = campanha.ConfirmacaoResponsavel;
                                this.model.AtendimentoResponsavel = campanha.AtendimentoResponsavel;
                                this.model.AtendimentoVoucherReceptor = campanha.AtendimentoVoucherReceptor;
                                this.model.PlanoID = campanha.PlanoID;
                                this.model.LimiteGeral = campanha.LimiteGeral
                                this.model.DescricaoLinkPreview = campanha.DescricaoLinkPreview;
                                this.model.TituloLinkPreview = campanha.TituloLinkPreview;
                                this.model.Participantes = [];
                                this.model.Influencer= campanha.Influencer;
                                this.model.ClienteParceiro = campanha.ClienteParceiro;
                                this.model.ClienteParceiroIlimitado = campanha.ClienteParceiroIlimitado;
                                this.model.LimitePorClienteParceiro = campanha.LimitePorClienteParceiro;
                                this.model.CompartilhamentoIndividual = campanha.CompartilhamentoIndividual;
                                this.model.CompartilhamentoRede = campanha.CompartilhamentoRede;
                                this.model.Inativo = campanha.Inativo;
                                this.model.CodigoRastreio =  campanha.CodigoRastreio;
                                this.model.EntregaGestorParceria = campanha.EntregaGestorParceria;
                                this.model.ConfirmacaoGestorParceria = campanha.ConfirmacaoGestorParceria;
                                this.model.AtendimentoGestorParceria = campanha.AtendimentoGestorParceria;
                                this.model.ResgateGestorParceria = campanha.ResgateGestorParceria;

                                campanha.Distribuicao.forEach(element => {
                                    this.participanteObj = {
                                        ID: element.ID,
                                        ListaDistribuidorID: element.ListaDistribuidorID,
                                        Nome: element.Nome,
                                        Ilimitado:element.Ilimitado || false,
                                        Quantidade: element.Quantidade,
                                        Bloqueado: element.Quantidade == 0 && !element.Ilimitado,
                                        Disponiveis:element.Disponiveis,
                                        TipoParticipante:element.TipoParticipante,
                                        TipoRegraDistribuicaoParticipante: element.TipoRegraDistribuicaoParticipante,
                                        Inativo:element.Inativo
                                    };
                                    this.model.Participantes.push(this.participanteObj);

                                });

                                this.ordenarParticipantesInativos();

                                this.setLabelComunicadoRegras(campanha.Influencer, campanha.ClienteParceiro);

                                // let lojaIds=[];
                                // campanha.Distribuicao.forEach(element => {
                                //     lojaIds.push(element.ID);
                                // });
                                // this.carregarParceiros(lojaIds);
                                // for (const participante of campanha.Distribuicao) {

                                //     this.api.obterCanalDistribuicao({ ID: participante.ID }).subscribe(

                                //         (response: any) => {

                                //             if (response.error) {
                                //                 this.ui.error(response.error);
                                //                 this.router.navigate(['/campanhas']);
                                //             }
                                //             else {
                                //                 response.data.CanalDistribuicao.Disponiveis = participante.Quantidade;
                                //                 response.data.CanalDistribuicao.Quantidade = participante.Quantidade;
                                //                 response.data.CanalDistribuicao.Ilimitado = participante.Ilimitado || false;
                                //                 this.model.Participantes.push(response.data.CanalDistribuicao);
                                //             }
                                //         }
                                //     );
                                // }


                                if (campanha.Comunicado) {

                                    this.api.obterComunicados({ ID: campanha.Comunicado }).subscribe(

                                        (response: any) => {

                                            if (response.error) {

                                                this.ui.error(response.error);
                                                this.router.navigate(['/campanhas']);
                                            }
                                            else {

                                                this.model.Comunicado = response.data.Comunicados[0];
                                            }
                                        }
                                    );
                                }

                                this.api.obterVouchers({ ID: campanha.Voucher }).subscribe(

                                    (response: any) => {

                                        if (response.error) {

                                            this.ui.error(response.error);
                                            this.router.navigate(['/campanhas']);
                                        }
                                        else {

                                            this.model.Voucher = response.data.Vouchers[0];
                                            this.model.Cupom = campanha.Cupom;
                                            this.unityIdEmissor = this.model.Voucher.UnityIdEmissor;
                                            this.model.Prazo = campanha.Prazo != 0 ? campanha.Prazo: null;
                                            if(this.model.Voucher){
                                                if (this.model.Voucher.Publico != 1) this.publico = 'Colaboradores';

                                            }
                                            else this.publico = 'Clientes';
                                            this.acao = 'Manutenção';
                                            this.edicao = true;
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
                else {
                    this.carregarParceiros(null);
                    this.acao = 'Criação';
                }
            }
        );
    }

    inativarRegra(participante){
        if(!window.confirm('Confirma a exclusão do Participante?')){
            return;
        }
        participante.Inativo = true;
        this.ordenarParticipantesInativos();
    }

    ordenarParticipantesInativos(){
        if(this.model.Participantes.length > 1 && this.model.Participantes[0].Inativo){
            this.model.Participantes = this.model.Participantes.sort(function(x, y) {
                // false values first
                return (x.Inativo === y.Inativo)? 0 : x? -1 : 1;
            });
        }
    }

    bloquerParticipante(event, participante){
        this.model.Participantes.find(a=>a.ID==participante.ID).Bloqueado = event.checked;
        if(event.checked){
            participante.Bloqueado = true;
        }
        else
        {
            if(participante.Quantidade == 0){
                participante.Quantidade = 1;
            }
        }
    }

    validarQuantidade(participante){
        if(participante.Quantidade <= 0){
            participante.Quantidade = 1;
        }
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

    getTipoRegraDistribuicao(tipoRegra){
        return this.tipoRegraDistribuicao[tipoRegra];
    }

    getTipoRegraDistribuicaoIndex(tipoParticipante){

        let pos:number = 0
        if(tipoParticipante == "Estabelecimento" || tipoParticipante == "Parceiro" || tipoParticipante == "Lista-Distribuidor"){
            pos = this.tipoRegraDistribuicao.indexOf("Canal");
        }
        if(tipoParticipante == "Colaborador-Parceiro" || tipoParticipante == "Usuário-Estabelecimento" ||
           tipoParticipante == "Cliente-Parceiro" ){
            pos = this.tipoRegraDistribuicao.indexOf("Agente");
        }
        if(tipoParticipante == "Rede-Estabelecimento"){
            pos = this.tipoRegraDistribuicao.indexOf("Rede");
        }

        if(tipoParticipante == "Rede-Parceiro"){
            pos = this.tipoRegraDistribuicao.indexOf("RedeParceiro");
        }

        if(tipoParticipante == "Lista-Distribuidor"){
            pos = this.tipoRegraDistribuicao.indexOf("ListaDistribuidor");
        }


        return pos;
    }

    trocarTipoCampanha(event, isInfulencer, isClienteParceiro){

        if(this.model.ClienteParceiro && this.model.Entrega == undefined){
            this.model.Entrega = 0;
            this.model.EntregaResponsavel = 0;
            this.model.ResgateResponsavel = 0;
            this.model.ConfirmacaoResponsavel = 0;
            this.model.AtendimentoResponsavel = 0;
            this.model.EntregaGestorParceria = 0;
        }
        if(this.model.Influencer && this.model.Resgate == undefined ){
            this.model.Entrega = 0;
            this.model.Resgate = 0;
            this.model.Confirmacao = 0;
            this.model.Atendimento = 0;
            this.model.EntregaResponsavel = 0;
            this.model.ResgateResponsavel = 0;
            this.model.ConfirmacaoResponsavel = 0;
            this.model.AtendimentoResponsavel = 0;
            this.model.EntregaGestorParceria = 0;
            this.model.ResgateGestorParceria = 0;
            this.model.ConfirmacaoGestorParceria = 0;
            this.model.AtendimentoGestorParceria = 0;
            this.model.AtendimentoVoucherReceptor = 0;
         }

        if(this.model.Participantes.length > 0 ){
            let curStatus = event.checked;
            if(!window.confirm('Mudar o tipo de campanha irá reiniciar a configuração. Confirma a mudança?')){

                event.checked = !curStatus;
                let curCheck = event.source.id;
                if(curCheck == 'influencer'){
                    setTimeout(() => {
                    this.model.Influencer = !curStatus;
                    } );
                }
                if(curCheck == 'clienteParceiro'){
                    setTimeout(() => {
                        this.model.ClienteParceiro = !curStatus;
                    } );
                }
                return;
            }
        }
        this.resetProcessData();
        this.setLabelComunicadoRegras(isInfulencer, isClienteParceiro);
    }

    resetProcessData(){
        this.parceirosMultiCtrl = new FormControl();
        this.parceirosMultiFilerCtrl = new FormControl();
        this.filteredParceirosMulti= new ReplaySubject<Rede[]>(1);
        this.parceirosApi=[];
        this.model.TipoParticipante = "";
        this.selectedItemsParceiros = [];
        if(this.model.ID == 0){
            this.model.Participantes = [];
        }
        this.parceiros = [];
        this.redes = [];
        this.curParti = "";
        this.copyRedeGroups(this.redes);
    }


    setLabelComunicadoRegras(isInfulencer, isClienteParceiro){

        if(isInfulencer && isInfulencer == true){
            this.model.ClienteParceiro = false;
        }

        if(isClienteParceiro && isClienteParceiro == true){
            this.model.Influencer = false;
            this.tiposParticipante = ['Cliente-Parceiro'];
        }else{
            this.tiposParticipante = ['Estabelecimento', 'Parceiro', 'Colaborador-Parceiro','Usuário-Estabelecimento', 'Rede-Estabelecimento', 'Rede-Parceiro', 'Lista-Distribuidor'];
        }

        if(isInfulencer == undefined){isInfulencer = false;}

        if(this.model.TipoParticipante && this.model.TipoParticipante != ""){
            this.listarEntidadePorTipo(this.model.TipoParticipante, isInfulencer, this.model.UnityIdEmissor);
        }
        if(isInfulencer){
            this.labelComunicadoRegras = "Comunicado de Boas-Vindas";
            this.hintComunicadoRegras = "São os comunicados que serão automaticamente entregues aos funcionários das lojas participantes no momento da ativação da campanha";
        }else{
            this.model.CompartilhamentoRede = false;
            this.model.CompartilhamentoIndividual = false;
            this.labelComunicadoRegras = "Comunicado de Boas-Vindas e Pontuação";
            this.hintComunicadoRegras = "São os comunicados que serão automaticamente entregues aos funcionários das lojas participantes no momento da ativação da campanha, e a regra de pontuação na entrega/resgate dos vouchers.";
        }
    }

    remover(index)
    {
        let part = this.model.Participantes[index];
        if (!part.NovaRegraDistribuicao){
            this.ui.error("Você não pode excluir este participante, apnenas inativar.");
            return;
        }

        this.model.Participantes.splice(index, 1);
        this.selectedItemsParceiros=[];
        // for (const participante of this.model.Participantes) {
        //     this.selectedItemsParceiros.push(
        //         this.parceiros.find(
        //             parceiro => parceiro.ID === participante.ID
        //         )
        //     );
        //     //   this.model.Participantes.push(this.parceiros.find((parceiro) => parceiro.ID === participante));
        // }
        this.parceirosMultiCtrl.setValue(
            this.selectedItemsParceiros
        );

    }
    cancelar() {

        this.router.navigate(['/campanhas']);
    }

    salvar() {

        if(this.model.Influencer){
            this.model.Entrega = 0;
        }

        this.model.Participantes.forEach(element => {
            if(element.Bloqueado){
                element.Quantidade = 0;
                element.Ilimitado = false;
            }
        });

        const parameter: any = this.parameter();

        if (this.validar(parameter)) {

            this.ui.block();

            this.api.salvarCampanha(parameter).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {

                        response.data.Alertas.forEach(element => {
                            this.ui.warning(element.Descricao, element.Mensagem,{ timeOut: 15000 });
                        });

                        this.ui.success(this.model.ID ? 'Campanha atualizada com sucesso.' : 'Campanha criada com sucesso.');

                        this.router.navigate(['/campanhas']);
                    }
                }
            );
        }
    }

    selecionarVoucher() {

        if(this.model.Voucher && this.model.Participantes.length > 0){
            if(!window.confirm('Mudar o voucher irá reiniciar a configuração. Confirma a mudança?')){
                return;
            }
        }

        if (this.model.ID) {

            return;
        }

        this.storage.set('session', null);
        this.storage.set('session', null);

        const dialogRef = this.dialog.open(ListaImagensComponent, {
            width: 'calc(95%)',
            height: 'calc(80%)',
            data: {
                selection: 'single',
                tipo: 'Vouchers',
                publico: this.publico
            }
        });



        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {

                this.api.obterVouchers({'ID': result.ID}).subscribe((response: any) => {
                    if (!response.error) {
                        this.model.Voucher=response.data.Vouchers[0];
                        this.unityIdEmissor = this.model.Voucher.UnityIdEmissor;
                        this.api.listaPlanosPontuacao({UnityIdEmissorVoucher: this.unityIdEmissor}).subscribe(
                            (response: any) => {
                                if (response.error) {
                                    this.ui.error(response.error);
                                    this.router.navigate(['/campanhas']);
                                }
                                else {
                                    this.model.Comunicado = null;
                                    this.resetProcessData();
                                    this.planos = response.data.Itens;
                                }
                            }
                        )
                    }
                });
                ;
            }
        });
    }

    getVoucherDescription(){
        return (this.model.Voucher && this.model.Voucher.Descricao) ? this.model.Voucher.Descricao : '';
    }

    selecionarComunicado() {

        if (this.model.ID && this.model.Comunicado) {

            return;
        }

        this.storage.set('session', null);


        const dialogRef = this.dialog.open(ListaImagensComponent, {
            width: 'calc(95%)',
            height: 'calc(80%)',
            data: {
                selection: 'single',
                tipo: 'Comunicado',
                publico: this.publico,
                unityIdEmissorVoucher: this.model.Voucher.UnityIdEmissor
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {
                this.api.obterComunicado( result.ID).subscribe((response: any) => {
                    if (!response.error) {
                        this.model.Comunicado=response.data.Comunicado;
                    }
                });

            }
        });
    }

    desabilitado(){
        if(this.model.ID || !this.model.Voucher){
            return true;
        }
    }

    lojasParticipantes() {

        return this.model.Participantes.map(a => a.Nome || a.Grupo || a.Rede).join(', ');
    }

    //todo: criar diretiva
    removeNegative(value){
        var v = value;
        var at = this.model.Atendimento;
        if(at < 0){
            this.model.Atendimento = Math.abs(this.model.Atendimento);
        }
    }


    tipoParticipanteSelected(value) {

        this.filteredParceirosMulti= new ReplaySubject<Rede[]>(1);
        if (value != null) {
            if(this.curParti != value.value){
                this.curParti = value.value
            }else{
                return;
            }

            this.listarEntidadePorTipo(this.curParti, this.model.Influencer, this.unityIdEmissor);
        }
    }


    listarEntidadePorTipo(tipo: string, isInfluencer: boolean = false, unityIdEmissor = null) {

        //public parceiros: Parceiro[] = [];
        this.parceiros = [];
        this.redes = [];

        this.api
            .listarEntidadePorTipo(tipo, isInfluencer, unityIdEmissor)
            .subscribe((response: any) => {
                if (response.error) {
                    this.ui.error(response.error);
                }
                else {
                    // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fapp.component.html
                    // exemplos de multiselect

                    // this.parceiros = response.data.Parceiros;
                    this.parceirosApi=response.data.Parceiros;
                    response.data.Parceiros.forEach(element => {
                        if (element.Nome) {
                            this.parceiros.push({
                                ID: element.ID,
                                Nome: element.Nome,
                                Tipo: element.TipoParticipante,
                                CPF: element.CPF
                            });
                            let rede = this.redes.find(
                                a => a.Rede == element.Rede
                            );
                            if (!rede) {
                                rede = { Parceiros: [], Rede: element.Rede };
                                this.redes.push(rede);
                            }
                            const p = { Nome: element.Nome, ID: element.ID, Tipo: element.TipoParticipante, CPF: element.CPF };
                            rede.Parceiros.push(p);
                        }
                    });

                    // this.model.Parceiro = this.parceiros.find((parceiro) => parceiro.ID === emissor);
                    // if (lojas) {
                    //     for (const participante of lojas) {
                    //         this.selectedItemsParceiros.push(
                    //             this.parceiros.find(
                    //                 parceiro => parceiro.ID === participante
                    //             )
                    //         );
                    //         //   this.model.Participantes.push(this.parceiros.find((parceiro) => parceiro.ID === participante));
                    //     }
                    //     this.parceirosMultiCtrl.setValue(
                    //         this.selectedItemsParceiros
                    //     );
                    // }

                    this.filteredParceirosMulti.next(
                        this.copyRedeGroups(this.redes)
                    );
                    this.parceirosMultiFilerCtrl.valueChanges
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe(() => {
                            this.filterParceiroMulti();
                        });


                }
            });
    }



    selecionarLojas() {

        const dialogRef = this.dialog.open(SelecaoParceirosComponent, {
            width: '80vw',
            height: '85vh',
            data: {
                selection: 'multiple'
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {

                for (const novaLoja of result) {

                    let jaContem: boolean = false;

                    for (const antigaLoja of this.model.Participantes)

                        if (novaLoja.ID == antigaLoja.ID) {

                            jaContem = true;
                            break;
                        }

                    if (!jaContem){
                        this.model.Participantes.push(novaLoja);
                    }else{
                        novaLoja.Inativo = false;
                    }
                }

                // this.model.Participantes = result;

                for (const participante of this.model.Participantes) {

                    participante.Quantidade = 0;
                    participante.Ilimitado = false;
                    participante.Inativo = false;
                }
            }
        });
    }

    distribuirVouchers() {

        const quantidade: number = Math.floor(this.model.Voucher.Estoque[0].Quantidade / this.model.Participantes.length);
        const diferenca: number = this.model.Voucher.Estoque[0].Quantidade - (quantidade * this.model.Participantes.length);

        for (const participante of this.model.Participantes) {

            participante.Quantidade = quantidade;
        }

        this.model.Participantes[0].Quantidade += diferenca;
    }

    onKeypress(event, decimals) {

        if (event.key === '.' || event.key === ',') {

            if (!decimals || decimals === 0) {

                event.preventDefault();
                return;
            }

            if (event.target.value.substring(event.target.selectionStart, event.target.selectionEnd).indexOf(',') >= 0 || event.target.value.indexOf(',') < 0) {

                let value = event.target.value.substr(0, event.target.selectionStart) + ',' + event.target.value.substr(event.target.selectionEnd);

                if (value.startsWith(',')) {

                    value = '0' + value;
                }

                event.target.value = value;
            }

            event.preventDefault();
        }
        else {

            const numeric = /^\d+$/.test(event.key);

            if (!numeric) {

                event.preventDefault();
            }
        }
    }

    formatNumber(event, decimals: number) {

        let currentValue = event.target.value;

        if (currentValue === ('0,')) {

            currentValue = '0';
        }

        if (currentValue.indexOf(',') >= 0) {

            currentValue = currentValue.split('.').join('').replace(',', '.');
        }

        let newValue = Number(currentValue).toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: decimals, maximumFractionDigits: decimals });

        if (newValue === 'NaN') {

            currentValue = 0;
            newValue = Number(currentValue).toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        }

        currentValue = Number(Number(currentValue).toFixed(decimals));

        setTimeout(() => { event.target.value = newValue; });
    }

    onPaste(event) {

        let pastedText;

        if (window.clipboardData && window.clipboardData.getData) {

            pastedText = window.clipboardData.getData('Text');
        }
        else if (event.clipboardData && event.clipboardData.getData) {

            pastedText = event.clipboardData.getData('text/plain');
        }

        if (isNaN(Number(pastedText))) {

            return false;
        }
    }

    parameter() {

        let _parameter: any;

        if (this.publico == 'Clientes') {

            _parameter = {
                ID: this.model.ID == '0' ? '00000000-0000-0000-0000-000000000000' : this.model.ID,
                Nome: this.model.Nome,
                Inicio: this.model.Inicio ? this.model.Inicio.toNET() : null,
                Termino: this.model.Termino ? this.model.Termino.toNET() : null,
                Voucher: this.model.Voucher ? this.model.Voucher.ID : null,
                Distribuicao: [],
                Cupom: this.model.Cupom,
                Prazo: this.model.Prazo ? Number(this.model.Prazo) : 0,
                Comunicado: this.model.Comunicado ? this.model.Comunicado.ID : null,
                Entrega: Number(this.model.Entrega),
                Resgate: Number(this.model.Resgate),
                Confirmacao:Number(this.model.Confirmacao),
                Atendimento:Number(this.model.Atendimento),
                EntregaResponsavel: Number(this.model.EntregaResponsavel),
                ResgateResponsavel: Number(this.model.ResgateResponsavel),
                ConfirmacaoResponsavel:Number(this.model.ConfirmacaoResponsavel),
                AtendimentoResponsavel:Number(this.model.AtendimentoResponsavel),
                EntregaGestorParceria: Number(this.model.EntregaGestorParceria),
                ResgateGestorParceria: Number(this.model.ResgateGestorParceria),
                ConfirmacaoGestorParceria:Number(this.model.ConfirmacaoGestorParceria),
                AtendimentoGestorParceria:Number(this.model.AtendimentoGestorParceria),
                AtendimentoVoucherReceptor:Number(this.model.AtendimentoVoucherReceptor),
                PlanoID: this.model.PlanoID,
                LimiteGeral: this.model.LimiteGeral,
                DescricaoLinkPreview: this.model.DescricaoLinkPreview,
                TituloLinkPreview: this.model.TituloLinkPreview,
                Influencer: this.model.Influencer,
                ClienteParceiro: this.model.ClienteParceiro,
                ClienteParceiroIlimitado: this.model.ClienteParceiroIlimitado,
                LimitePorClienteParceiro: this.model.LimitePorClienteParceiro,
                CompartilhamentoRede: this.model.CompartilhamentoRede,
                CompartilhamentoIndividual: this.model.CompartilhamentoIndividual,
                Inativo:this.model.Inativo
            };
        }

        else {

            _parameter = {
                ID: this.model.ID,
                Nome: this.model.Nome,
                Inicio: this.model.Inicio ? this.model.Inicio.toNET() : null,
                Termino: this.model.Termino ? this.model.Termino.toNET() : null,
                Voucher: this.model.Voucher ? this.model.Voucher.ID : null,
                Distribuicao: [],
                Cupom: 0,
                Prazo: 0,
                Comunicado: 0,
                Entrega: 0,
                Resgate: 0
            };
        }

        for (const participante of this.model.Participantes) {

            _parameter.Distribuicao.push({
                ID: participante.ID,
                Nome: participante.Nome,
                Quantidade: this.publico == 'Clientes' ? participante.Quantidade == undefined ? 0: participante.Quantidade : 0,
                Ilimitado: this.publico == 'Clientes' ? participante.Ilimitado : 0,
                TipoParticipante: participante.TipoParticipante,
                ListaDistribuidorID: participante.ListaDistribuidorID,
                TipoRegraDistribuicaoParticipante: participante.TipoRegraDistribuicaoParticipante,
                Inativo: participante.Inativo,
                Bloqueado: participante.Bloqueado
            });
        }

        return { Campanha: _parameter };
    }

    validar(parameter: any) {

        let podeSalvar: boolean = true;
        let reason: string = '';

        if (!parameter.Campanha.Voucher) {

            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'É necessário selecionar um voucher promocional');
        }

        if (this.model.Influencer && !this.model.CompartilhamentoIndividual && !this.model.CompartilhamentoRede) {

            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'Selecione pelo menos uma forma de compartilhamento');
        }

        if (this.publico == 'Clientes' && this.model.Voucher && this.model.Voucher.Publico != 1) {

            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'Voucher selecionado não é disponível para "Publico: Clientes"');
        }
        if (this.publico == 'Colaboradores' && this.model.Voucher && this.model.Voucher.Publico != 2) {

            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'Voucher selecionado não é disponível para "Publico: Colaboradores"');
        }
        // else if (!parameter.Campanha.Distribuicao.length) {

        //     podeSalvar = false;
        //     reason = 'é necessário selecionar as lojas que irão distribuir os vouchers.';
        // }
        // else if (parameter.Campanha.Distribuicao.map((participante) => participante.Quantidade).reduce((a, b) => a + b, 0) > Number(this.model.Voucher.Estoque[0].Quantidade)) {

        //     podeSalvar = false;
        //     reason = 'a quantidade de vouchers distribuída entre as lojas é maior que a quantidade disponível.';
        // }
        if (!parameter.Campanha.Nome) {

            podeSalvar = false;

            reason = this.ui.buildMessage(reason,'É necessário informar um nome para a mesma');
        }
        if (this.model.ID === 0 && parameter.Campanha.Inicio && moment(parameter.Campanha.Inicio).isBefore(moment().startOf('day'))) {

            podeSalvar = false;
            reason = this.ui.buildMessage(reason,reason + 'Quando informado o início da campanha, este tem que ser à partir de hoje');
        }
        // if (parameter.Campanha.Termino && moment(parameter.Campanha.Termino).isBefore(moment().endOf('day'))) {

        //     podeSalvar = false;
        //     if (reason != null && reason != ''){ reason = reason + ' <br/>- '}
        //     reason = reason + 'Quando informado o término da campanha, este tem que ser uma data no futuro';
        // }
        if (parameter.Campanha.Inicio && parameter.Campanha.Termino && moment(parameter.Campanha.Termino).isBefore(moment(parameter.Campanha.Inicio))) {

            podeSalvar = false;
            reason = this.ui.buildMessage(reason,reason + 'O término da campanha tem que ser após o seu início');
        }
        if (!parameter.Campanha.TituloLinkPreview) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'É necessário informar um título para o link preview');
        }
        if (!parameter.Campanha.DescricaoLinkPreview) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'É necessário informar uma descrição para o link preview');
        }
        if(!parameter.Campanha.Influencer && !parameter.Campanha.ClienteParceiro && (!parameter.Campanha.Cupom || parameter.Campanha.Cupom <= 0)){
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'É necessário informar um valor para o Cupom');
        }
        if((Number.isNaN(parameter.Campanha.Entrega) || Number.isNaN(parameter.Campanha.Resgate) ||
            Number.isNaN(parameter.Campanha.Confirmacao) ||Number.isNaN(parameter.Campanha.Atendimento) ||
            Number.isNaN(parameter.Campanha.EntregaResponsavel)|| Number.isNaN(parameter.Campanha.ConfirmacaoResposavel) ||
            Number.isNaN(parameter.Campanha.ResgateResponsavel)|| Number.isNaN(parameter.Campanha.AtendimentoResponsavel) ||
            Number.isNaN(parameter.Campanha.AtendimentoVoucherReceptor) || Number.isNaN(parameter.Campanha.EntregaGestorParceria)||
            Number.isNaN(parameter.Campanha.ConfirmacaoGestorParceria) || Number.isNaN(parameter.Campanha.ResgateGestorParceria) ||
            Number.isNaN(parameter.Campanha.AtendimentoGestorParceria)))
        {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'É necessário definir as Regras de Pontuação.');
        }

        if (!parameter.Campanha.Comunicado) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,'É necessário informar um Comunicado');
        }

        if (!podeSalvar) {

            this.ui.error(reason, ' Não foi possível salvar a campanha! ', {tapToDismiss: false});
        }

        return podeSalvar;
    }

    podeAlterarInicio() {

        return this.model.ID === 0 || !this.model.Inicio || moment(this.model.Inicio).isAfter(moment().startOf('day'));
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    selectionChanged(event)
    {
       // console.log(event);

        event.value.forEach(element => {
            let parceiro= this.parceirosApi.find(a=>a.ID==element.ID);
            if(this.model.TipoParticipante == "Lista-Distribuidor"){
                let nome = parceiro.Nome + "-(Lista-Distribuidor)";
                if(!this.model.Participantes.find(a=>a.Nome==nome)){
                    parceiro.Nome = nome;
                    parceiro.TipoParticipante = this.model.TipoParticipante;
                    parceiro.TipoRegraDistribuicaoParticipante = this.getTipoRegraDistribuicaoIndex(this.model.TipoParticipante);
                    parceiro.NovaRegraDistribuicao = true;
                    this.model.Participantes.push(parceiro);
                }
            }
            else if(!this.model.Participantes.find(a=>a.ID==element.ID)){
                parceiro.TipoParticipante = this.model.TipoParticipante;
                parceiro.TipoRegraDistribuicaoParticipante = this.getTipoRegraDistribuicaoIndex(this.model.TipoParticipante);
                parceiro.NovaRegraDistribuicao = true;
                this.model.Participantes.push(parceiro);
            }else{
                this.model.Participantes.find(a=>a.ID==element.ID).Inativo = false;
            }
        });
        this.ordenarParticipantesInativos();
    }
    protected setInitialValue() {
        this.filteredParceirosMulti
            .pipe(
                take(1),
                takeUntil(this._onDestroy)
            )
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                if (this.multiSelect)
                    this.multiSelect.compareWith = (a: Parceiro, b: Parceiro) =>
                        a && b && a.ID === b.ID;
            });

    }
    protected filterParceiroMulti() {
        if (!this.parceiros) {
            return;
        }
        // get the search keyword
        this.model.lojas=[];
        let search = this.parceirosMultiFilerCtrl.value;
        const redeGroupsCopy = this.copyRedeGroups(this.redes);
        if (!search) {
            this.filteredParceirosMulti.next(redeGroupsCopy);

            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredParceirosMulti.next(
            redeGroupsCopy.filter(redeGroup => {
                const showRedeGroup =
                    redeGroup.Rede.toLowerCase().indexOf(search) > -1;
                if (!showRedeGroup) {
                    redeGroup.Parceiros = redeGroup.Parceiros.filter(
                        a => a.Nome.toLowerCase().indexOf(search) > -1
                    );
                }
                return redeGroup.Parceiros.length > 0;
            })
        );
    }
    carregarParceiros(lojas: string[]) {

        this.api
            .listarParceirosPorPerfil(2)
            .subscribe((response: any) => {
                if (!response.error) {
                    // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fapp.component.html
                    // exemplos de multiselect

                    // this.parceiros = response.data.Parceiros;
                    this.parceirosApi=response.data.Parceiros;
                    response.data.Parceiros.forEach(element => {
                        if (element.Nome) {
                            this.parceiros.push({
                                ID: element.ID,
                                Nome: element.Nome,
                                Tipo: element.TipoParticipante,
                                CPF: element.CPF
                            });
                            let rede = this.redes.find(
                                a => a.Rede == element.Rede
                            );
                            if (!rede) {
                                rede = { Parceiros: [], Rede: element.Rede };
                                this.redes.push(rede);
                            }
                            const p = { Nome: element.Nome, ID: element.ID, Tipo: element.TipoParticipante, CPF: element.CPF };
                            rede.Parceiros.push(p);
                        }
                    });

                    // this.model.Parceiro = this.parceiros.find((parceiro) => parceiro.ID === emissor);
                    if (lojas) {
                        for (const participante of lojas) {
                            this.selectedItemsParceiros.push(
                                this.parceiros.find(
                                    parceiro => parceiro.ID === participante
                                )
                            );
                            //   this.model.Participantes.push(this.parceiros.find((parceiro) => parceiro.ID === participante));
                        }
                        this.parceirosMultiCtrl.setValue(
                            this.selectedItemsParceiros
                        );
                    }
                    this.filteredParceirosMulti.next(
                        this.copyRedeGroups(this.redes)
                    );
                    this.parceirosMultiFilerCtrl.valueChanges
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe(() => {
                            this.filterParceiroMulti();
                        });


                }
            });
    }
    protected copyRedeGroups(redeGroups: Rede[]) {
        const redeGroupsCopy = [];
        redeGroups.forEach(redeGroup => {
            redeGroupsCopy.push({
                Rede: redeGroup.Rede,
                Parceiros: redeGroup.Parceiros.slice()
            });
        });
        return redeGroupsCopy;
    }

    exibirCpf(cpf){
        let res = '';
        if(cpf){
            for (let i = 0; i < 9; i++) {
                res = res + cpf[i];
                if(i < 8 && (i + 1) % 3 == 0){
                    res = res + '.'
                }
            }
            res = res + '-' + cpf.substring(9, 11);
            res = `CPF: ${res}`;
        }
        return res;
    }


    listarParticipantes(){
        this.model.Participantes.forEach(element => {
            console.log(element.ID + ' - ' + element.Nome);
        });
    }

    trocarCampanhaAtivo(event){
        if(event.checked && !window.confirm('Confirma a inativação da campanha?')){
            setTimeout(() => {
                this.model.Inativo = false;
            });
        }
    }
    validarPrazo(){
        if(this.model.Prazo <= 0){
            this.model.Prazo = null;
        }
    }
}

export interface Parceiro {
    ID: string;
    Nome: string;
    Tipo: number;
    CPF: string;
}
export interface Rede {
    Rede: string;
    Parceiros: Parceiro[];
}
