import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthGuard } from "./../../guards/auth.guard";
import { APIService } from "./../../services/api.service";
import { UIService } from "src/app/services/ui.service";
import { Router } from "@angular/router";

@Component({
    selector: "cs-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
    public menu = [];
    public dashboards: any[] = [];
    public nomeUsuarioLogado:string = "_";

    constructor(private api: APIService, public authGuard: AuthGuard, private ui: UIService, private router: Router) {}

    ngOnInit() {
        this.nomeUsuarioLogado = this.api.usuario.Nome;
        this.menu=this.api.menu
            // remover menu relatorios provisoriamente
            // .filter((f) => {
            //     if (f.Route == 'reports') {
            //         // se usuario tem menu reports, popula menuDashboards
            //         this.ui.block();
            //         this.api.obterDashboards({
            //             Take: 5000,
            //             Skip: 0
            //         }).subscribe((response: any) => {
            //             this.ui.unblock();
            //             if (!response.error && response.data.Dashboards && response.data.Dashboards.length > 0) {
            //                 this.dashboards = response.data.Dashboards;
            //             }
            //         });

            //     };
            //     return f.Route != 'reports';
            // });

       // console.log(this.menu);
        this.api.getMenu().subscribe(a => {
            this.menu = a.menu;


          //  console.log(this.menu);
        });


    }

    navigateTo(item) {
        // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        // this.router.navigate(['/reports', id]));
        if ((!item.Items || item.Items.length == 0) && item.Route) {
            this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
            this.router.navigate(['/'+item.Route]))
        }
    }

    logout() {
        this.ui.block("Encerrando...");
        this.api.logout({}).subscribe(
            () => {
                this.ui.unblock();
                this.router.navigate(['/login']);
            }
        );
    }
}
