import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

@Component({
    selector: 'cs-consultores',
    templateUrl: './consultores.component.html',
    styleUrls: ['./consultores.component.scss']
})
export class ConsultoresComponent implements OnInit {

    @Input() modal: string = undefined;

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public model: any = {
    };

    public consultores: any[] = [];

    public total: number;
    public dataSource = new MatTableDataSource<any>(this.consultores);
    public selection = new SelectionModel<any>(true, []);

    public consultorSelecionado: any;

    constructor(private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        setTimeout(() => {

            this._columns = [this.modal !== undefined ? 'Selecionado' : 'Acoes', 'Nome', 'CPF', 'Telefone', 'Email'];

            this.paginator.pageSize = this.modal ? 5 : 10;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.loadSession();
        });
    }

    limparFiltro() {

        this.model = {};
    }

    filtrar(page?: number) {

        this.ui.block('Pesquisando consultores...');

        const skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;

        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        if (this.sort && this.sort.direction) {

            parameter.Sort = this.sort.active + '|' + this.sort.direction;
        }
        else {

            parameter.Sort = 'Nome|asc';
        }

        this.api.obterConsultores(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    this.consultores = response.data.Consultores || [];

                    this.dataSource = new MatTableDataSource<any>(response.data.Consultores);
                    this.selection = new SelectionModel<any>(true, []);

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    newRecord() {

        this.saveSession();
        this.router.navigate(['/consultors', 0]);
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/consultors', element.ID]);
    }

    deleteRecord(element: any) {
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Nome';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    selecionaConsultor(consultor: any) {

        this.consultorSelecionado = consultor;
    }

    rowSelected(row: any) {

        if (!this.modal) {

            return;
        }

        if (this.modal === 'single') {

            this.selecionaConsultor(row);
        }
        else if (this.modal === 'multiple') {

            this.selection.toggle(row);
        }
    }

    telefoneMask(telefone: String): String {

        let tel: String = '';

        if (telefone.length < 2) return tel;

        tel += '(' + telefone[0] + telefone[1] + ') ';
        let x = 2;

        while (x < telefone.length) {
            if (x == 7) tel += '-';
            tel += telefone[x++];
        }

        return tel;
    }

    cpfMask(CPF: String): String {

        let cpf: String = '';
        let x = 0;

        while (x < CPF.length) {
            if (x % 3 == 0 && x > 0) cpf += '.';
            cpf += CPF[x++];
        }

        return cpf;
    }

}
