
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'cs-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss']
})

export class EsqueceuSenhaComponent implements OnInit {

  @ViewChild('email') email;

  public model: any = {};

  public errors: any = {};

  constructor(private ui: UIService, private router: Router, public api: APIService) {
  }

  ngOnInit() {
      setTimeout(() => { this.email.nativeElement.focus(); });
  }

  clearError(field: string) {

      this.errors[field] = undefined;
  }

  validate(field?: string) {

    if (!field || field === 'Email') {

        if (!this.model.Email) {

            this.errors.Email = 'Por favor informe seu e-mail.';
        }
        else {

            const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!expression.test(String(this.model.Email).toLowerCase())) {

                this.errors.Email = 'Endereço de e-mail inválido.';
            }
        }
    }

    return ((JSON.stringify(this.errors)).length - 2);
  }


  esqueceuSenha() {

    this.errors = {};
    const errors = this.validate();

    if (!errors) {

        const parameter: any = {
          Email: this.model.Email
        };

        this.ui.block();

        this.api.esqueceuSenha(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.errors.Email = response.error;
                }
                else {

                    this.ui.success('Um email com um link para redefinição de senha foi enviado para ' + this.model.Email + '.');
                    this.router.navigate(['/home']);
                }
            }
        );
    }
    else {

        this.ui.error('Por favor verifique os campos inválidos.');
    }
  }

  voltar() {

    this.router.navigate(['/login']);
  }
}
