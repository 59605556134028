import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { Observable } from "rxjs";
import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";

@Component({
    selector: "cs-rede-pagamento",
    templateUrl: "./rede-pagamento.component.html",
    styleUrls: ["./rede-pagamento.component.scss"]
})
export class RedePagamentoComponent implements OnInit, OnDestroy {
    @ViewChild("inputNome") inputNome: ElementRef;
    @Input() modal: string = undefined;

    private subscription: any;
    public chavePagoLivre: string;
    public acao: string;

    public model: any = {
        ID: '0',
        Nome: "",
        ListaCodigoRetorno: []
    };

    public redesFiltradas: Observable<string>;
    public listaAutorizadoras: any[];

    constructor(
        public dialog: MatDialog,
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];

            if (id != '0') {
                this.ui.block();

                const parameter: any = {
                    ID: id
                };

                this.api.obterRedePagamento(parameter).subscribe((response: any) => {
                    this.ui.unblock();

                    if (response.error) {
                        this.ui.error(response.error);
                        this.router.navigate(["/redepagamentos"]);
                    } else {
                        this.model = response.data.Rede;
                        this.acao = "Edição";

                        setTimeout(() => {
                            this.inputNome.nativeElement.focus();
                        });
                    }
                });
            } else {
                this.acao = "Inclusão";

                setTimeout(() => {
                    this.inputNome.nativeElement.focus();
                });
            }
        });

        this.api.listarAutorizadoras(JSON.parse(JSON.stringify({}))).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.listaAutorizadoras = response.data.Autorizadoras;
                }
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancelar() {
        this.router.navigate(["/redepagamentos"]);
    }

    validar() {
        let podeSalvar: boolean = true;
        let reason: string;

        if (!this.model.Nome) {
            podeSalvar = false;
            reason = "é necessário informar o nome.";
        } else if (this.model.ListaCodigoRetorno.length) {
            const todos: number = this.model.ListaCodigoRetorno.length;
            let codigoRepetido = false;
            let index = 0;            
            while (this.model.ListaCodigoRetorno.length > index + 1) {
                let c = this.model.ListaCodigoRetorno[index];
                for (let i = index + 1; i < this.model.ListaCodigoRetorno.length; i++) {
                    let c2 = this.model.ListaCodigoRetorno[i];
                    if (c.Codigo == c2.Codigo && c.RAutorizadoraID == c2.RAutorizadoraID) {
                        codigoRepetido = true;
                        break;
                    }
                }
                if (!codigoRepetido)
                    index++;
                else
                    break;
            }
            const semCodigo: boolean = false;//this.model.ListaCodigoRetorno.some(
            //     codigoRetorno => !codigoRetorno.Codigo
            // );

            const semMensagem: boolean = false;//this.model.ListaCodigoRetorno.some(
            //     codigoRetorno => !codigoRetorno.Mensagem
            // );

            const semDescricao: boolean = false;//this.model.ListaCodigoRetorno.some(
            //     codigoRetorno => !codigoRetorno.Descricao
            // );

            const semPermiteRetentar: boolean = this.model.ListaCodigoRetorno.some(
                codigoRetorno => codigoRetorno.NaoPermiteRetentativa == undefined
            );

            const semAutorizadora: boolean = this.model.ListaCodigoRetorno.some(
                codigoRetorno => codigoRetorno.RAutorizadoraID == undefined || codigoRetorno.RAutorizadoraID <= 0 || codigoRetorno.RAutorizadoraID == ''
            );

            if (codigoRepetido === true) {
                podeSalvar = false;
                reason = "existem Códigos de Retorno com o código repetido para a mesma autorizadora.";
            } else if (semCodigo || semMensagem || semDescricao || semPermiteRetentar || semAutorizadora) {
                podeSalvar = false;
                reason = "é obrigatório informar o Código, Mensagem, Descrição, Autorizadora e se permite ou não retentativa dos Códigos de Retorno.";
            }
        }

        if (!podeSalvar) {
            this.ui.error("Não foi possível salvar a rede de pagamento pois " + reason);
        }

        return podeSalvar;
    }

    salvar() {
        if (this.validar()) {
            this.ui.block();

            const parameter: any = {
                Rede: JSON.parse(JSON.stringify(this.model)),
            };

            this.api.salvarRedePagamento(parameter).subscribe((response: any) => {
                this.ui.unblock();

                if (response.error) {
                    this.ui.error(response.error);
                } else {
                    this.ui.success("Rede de Pagamento salva com sucesso.");

                    this.router.navigate(["/redepagamentos"]);
                }
            });
        }
    }
}
