import {
    Component,
    OnInit,
    OnDestroy,
    Input
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";

import { Observable } from "rxjs";

import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";
import {Location} from '@angular/common';

@Component({
    selector: 'cs-config-report',
    templateUrl: './config-report.component.html',
    styleUrls: ['./config-report.component.scss']
})
export class ConfigReportComponent implements OnInit, OnDestroy {
    @Input() modal: string = undefined;

    private subscription: any;
    public acao: string;

    public model: any = {
        ID: '0'
    };

    public listSimNao: any[] = [
        { ID: 'true', Descricao: 'Sim' },
        { ID: 'false', Descricao: 'Não' }
    ];
    public listTypes: any[] = [
        { ID: 'report', Descricao: 'Report' },
        { ID: 'dashboard', Descricao: 'Dashboard' }
    ];

    constructor(
        public dialog: MatDialog,
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) { }

    ngOnInit() {

        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];
            const dashboard = params["dashboard"];

            if (id != '0') {
                this.acao = "Edição";

                const parameter: any = {
                    ID: id
                };

                // nao tem metodo para pesquisar um dashboard, entao trazemos todos e filtramos
                this.ui.block();
                this.api.obterDashboards(parameter).subscribe(
                    (response: any) => {
                        this.ui.unblock();
                        if (response.error) {
                            this.ui.error(response.error);
                            this.cancelar();
                        }
                        else {
                            const dashboards = response.data.Dashboards;
                            const dashboard = dashboards.filter((f) => {
                                return f.ID == parameter.ID;
                            })[0];
                            this.model = dashboard;
                            this.model.Padrao = this.model.Padrao.toString();
                        }
                    }
                );

            } else {
                this.acao = "Inclusão";

                //setTimeout(() => {
                //    this.inputRede.nativeElement.focus();
                //});
            }
        });

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancelar() {
        this.location.back();
        //this.router.navigate(["/configreports"]);
    }

    validar() {
        let podeSalvar: boolean = true;
        let reason: string = '';
        const regex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;

        if (!this.model.Nome) {
            podeSalvar = false;
            reason = " é necessário informar o nome.";
        }
        else if (!this.model.Type) {
            podeSalvar = false;
            reason = " é necessário informar o Tipo.";
        }
        else if (this.model.ReportId && this.model.ReportId.trim().length>0 && !regex.test(this.model.ReportId)) {
            podeSalvar = false;
            reason = " é necessário informar o ReportId no formato correto.";
        }
        else if (this.model.ApplicationId.trim().length>0 && !regex.test(this.model.ApplicationId)) {
            podeSalvar = false;
            reason = " é necessário informar o ApplicationId no formato correto.";
        }
        else if (this.model.DashboardId && this.model.DashboardId.trim().length>0 && !regex.test(this.model.DashboardId)) {
            podeSalvar = false;
            reason = " é necessário informar o DashboardId no formato correto.";
        }
        else if (this.model.TileId && this.model.TileId.trim().length>0 && !regex.test(this.model.TileId)) {
            podeSalvar = false;
            reason = " é necessário informar o TileId no formato correto.";
        }
        else if (this.model.WorkspaceId && this.model.WorkspaceId.trim().length>0 && !regex.test(this.model.WorkspaceId)) {
            podeSalvar = false;
            reason = " é necessário informar o WorkspaceId no formato correto.";
        }

        if (!podeSalvar) {
            this.ui.error("Não foi possível salvar o relatório pois " + reason);
        }

        return podeSalvar;
    }

    salvar() {
        if (this.validar()) {
            this.ui.block();
            if (this.model.ID == '') this.model.ID = '00000000-0000-0000-0000-000000000000';
            if (this.model.ReportId == '') this.model.ReportId = '00000000-0000-0000-0000-000000000000';
            if (this.model.ApplicationId == '') this.model.ApplicationId = '00000000-0000-0000-0000-000000000000';
            if (this.model.DashboardId == '') this.model.DashboardId = '00000000-0000-0000-0000-000000000000';
            if (this.model.TileId == '') this.model.TileId = '00000000-0000-0000-0000-000000000000';
            if (this.model.WorkspaceId == '') this.model.WorkspaceId = '00000000-0000-0000-0000-000000000000';
            this.model.Padrao = this.model.Padrao == 'true';

            const parameter: any = {
                Dashboard: JSON.parse(JSON.stringify(this.model)),
            };

            this.api.AtualizarDashboards(parameter).subscribe((response: any) => {
                this.ui.unblock();

                if (response.error) {
                    this.ui.error(response.error);
                } else {
                    this.ui.success("Relatório salvo com sucesso.");

                    this.router.navigate(["/configreports"]);
                }
            });
        }
    }


}

