import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UIService } from '../../services/ui.service';

@Component({
    selector: 'cs-selecao-parceiros',
    templateUrl: './selecao-parceiros.component.html',
    styleUrls: ['./selecao-parceiros.component.scss']
})
export class SelecaoParceirosComponent implements OnInit {

    constructor(private ui: UIService, public dialogRef: MatDialogRef<SelecaoParceirosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }
}
