import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as pbi from 'powerbi-client';
import { APIService } from 'src/app/services/api.service';
import { UIService } from 'src/app/services/ui.service';

@Component({
    selector: 'cs-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
       // console.log(this.scrHeight, this.scrWidth);
    }

    @ViewChild('reportContainer') reportContainer: ElementRef;

    constructor(
        private api: APIService, private ui: UIService
    ) {}

    ngOnInit() {
        if(this.api.appCode=='1') return;
        if(this.api.appCode=='2') return;
        this.getScreenSize();

        this.ui.block();
        this.api.gerarTokenPowerBI(null).subscribe(

            (response: any) => {
                this.ui.unblock();
                if (response.error) {
                    this.ui.error(response.error);
                }
                else {
                    this.showReport(response.data.AccessToken, response.data.EmbedUrl, response.data.EmbedResourceId, response.data.Type);
                }
            }
        );

        setTimeout(() => {
            this.getScreenSize();
        });

    }

    showReport(accessToken: string, embedUrl: string, embedResourceId: string, type: string) {
        let models = pbi.models;
        let config = {
            type: type,
            tokenType: models.TokenType.Embed,
            accessToken: accessToken,
            embedUrl: embedUrl,
            permission: models.Permissions.All,
            id: embedResourceId,
            viewMode: models.ViewMode.View,
            settings: {}
        };

        const reportContainer = this.reportContainer.nativeElement;
        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        const report = powerbi.embed(reportContainer, config);
        report.off('loaded');
        report.on('loaded', () => {
         //   console.log('Loaded');
        });
        report.on('error', () => {
            //this.getToken();
        });
    }
}
