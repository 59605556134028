import { Component } from '@angular/core';

import { UIService } from '../services/ui.service';
import { APIService } from './../services/api.service';

@Component({
    selector: 'cs-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public initialized: boolean;
    public _blockingMessage: string = "..."

    constructor(public ui: UIService, private api: APIService) {
        this.api.uuid();
        this.api.initialize().subscribe(

            () => {
                this.initialized = true;
            }
        );
    }
    ngAfterViewChecked()
    {
        setTimeout(() => {
            this._blockingMessage = this.ui.blockingMessage;
        });
    }

}
