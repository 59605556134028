import { Injectable } from '@angular/core';

declare var SecureLS: any;

@Injectable()
export class StorageService {

    private _storage: any = new SecureLS();

    get(key: string): any {

        return this._storage.get(key);
    }

    set(key: string, data: any): void {

        if (data) {

            this._storage.set(key, data);
        }
        else {

            this._storage.remove(key);
        }
    }

    has(key: string): boolean {

        return this.get(key);
    }
}
