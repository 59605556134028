import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UIService } from "src/app/services/ui.service";
import { APIService } from "src/app/services/api.service";
import { loadElement } from "@angular/core/src/render3/instructions";

@Component({
    selector: "cs-lista-imagens",
    templateUrl: "./lista-imagens.component.html",
    styleUrls: ["./lista-imagens.component.scss"]
})
export class ListaImagensComponent implements OnInit {
    tipo = "Premio";
    items = [];
    itemsFiltrados = [];
    filtro = "";
    constructor(
        private api: APIService,
        private ui: UIService,
        public dialogRef: MatDialogRef<ListaImagensComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
       // console.log(this.data);
        // for (let i = 0; i < 20; i++) {
        //     this.items.push({
        //         img:
        //             "https://trybuimages.blob.core.windows.net/images/aa9bc37f-e3a9-4a49-adb7-5fe302575e94.png",
        //         text: "Cupom de desconto LABCSS"
        //     });
        // }
    }
    ngAfterViewInit() {
        if (this.data.tipo == "Premios") {
            this.filtrarVouchers(true, false);
        } else if (this.data.tipo == "Vouchers") {
            let colaboradores = false;
            if (this.data.publico == "Colaboradores") colaboradores = true;
            this.filtrarVouchers(colaboradores, !colaboradores);
        } else {
            this.filtrarComunicados();
        }
    }
    filtrarComunicados() {
        this.ui.block("Pesquisando Comunicados...");

        const parameter = {
            Take: 1000,
            Skip: 0,
            Colaboradores: true,
            Clientes: false,
            UnityIdEmissorVoucher: this.data.unityIdEmissorVoucher
        };

        this.api.obterComunicados(parameter).subscribe((response: any) => {
            this.ui.unblock();

            if (response.error) {
                this.ui.error(response.error);
            } else {
                response.data.Comunicados.forEach(element => {
                    this.items.push({
                        id: element.ID,
                        img: element.ImagemFrente,
                        text: element.Descricao,
                        dica: element.Dica
                    });
                });
                this.itemsFiltrados = this.items.slice();
            }
        });
    }
    filtrarVouchers(colaboradores: boolean, clientes: boolean) {
        this.ui.block("Pesquisando premios...");

        const parameter = {
            Take: 1000,
            Skip: 0,
            Colaboradores: colaboradores,
            Clientes: clientes
        };

        this.api.obterVouchers(parameter).subscribe((response: any) => {
            this.ui.unblock();

            if (response.error) {
                this.ui.error(response.error);
            } else {
                response.data.Vouchers.forEach(element => {
                    this.items.push({
                        id: element.ID,
                        img: element.ImagemFrente,
                        text: element.Descricao,
                        dica: element.Dica
                    });
                });
                this.itemsFiltrados = this.items.slice();
            }
        });
    }
    valuechange(newValue) {
        if (newValue == "") {
            this.itemsFiltrados = this.items;
        } else {
            newValue = newValue.toLowerCase();
            this.itemsFiltrados = this.items.filter(a =>
                a.text.toLowerCase().includes(newValue)
            );
        }
    }
    itemSelecionado(id) {
        this.dialogRef.close({ ID: id });
    }
}
