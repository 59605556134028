import { element } from '@angular/core/src/render3/instructions';
import { Component, OnInit,ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import {  MatSelect, MatSort } from "@angular/material";
import { takeUntil, take } from "rxjs/operators";
import * as moment from 'moment';

import { APIService } from "src/app/services/api.service";
import { UIService } from "src/app/services/ui.service";

@Component({
    selector: "cs-relatorios",
    templateUrl: "./relatorios.component.html",
    styleUrls: ["./relatorios.component.scss"]
})
export class RelatoriosComponent implements OnInit {
    public model: any = {
        Tipo: 1,
        GestoresParceria: [""],
        VisaoUnidade: false
    };
    @ViewChild("multiSelect") multiSelect: MatSelect;
    @ViewChild(MatSort) sort: MatSort;
    public vouchers: any[];
    public premios: any[];
    public itens: any[];
    public parceiros: any[];
    public mensagensParceiros: any[];
    public gestoresParceria: any[]=[];
    public campos: any[];
    public cabecalhos: string[];
    public listaGestoresParceria: Container[] = [];
    public containerMultiCtrl: FormControl = new FormControl();
    public containerMultiFilerCtrl: FormControl = new FormControl();
    public filteredContainerItemMulti: ReplaySubject<Container[]> = new ReplaySubject<Container[]>(1);
    protected _onDestroy = new Subject<void>();

    public _columns1: string[] = [
        "DataVenda",
        "Cliente",
        "CPF",
        "Telefone",
        "GestorParceria",
        "LojaVenda",
        "VendedorVenda",
        "LinkVoucher",
        "DescricaoVoucher",
        "Status",
        "DataTroca",
        "LojaTroca",
        "VendedorTroca"
    ];

    public _colunas = ['Rede', 'Grupo', 'Nome', 'Contato', 'Telefone', 'GestorParceria'];

    public _colunasMensagem = ['Data', 'Parceiro', 'Colaborador', 'Funcao', 'Status', 'Mensagem'];

    public _columns2: string[] = [

        // "Rede",
        // "Grupo",
        // "TelefoneParceiro",
        "LojaResgate",
        "DescricaoPremio",
        "NomeParceiro",
        "Colaborador",
        "CPF",
        "Telefone",
        "Banco",
        "Agencia",
        "Conta",
        "TipoConta",
        "LinkPremio",
        // "LojaPremio",
        // "DataEntrega",
        // "HoraEntrega",
        // "Pontos",
        // "Validade",
        "Status"
        // "DataTroca",
        // "HoraTroca",
        // "VendedorResgate"
    ];

    public columnsItens: string[] = ["Data"];
    public relatorios=[];
    public usuarios
    public carregarGestorParceria = true;

    // public _columns2: string[] = ['DataTroca', 'Colaborador', 'Telefone', 'CPF', 'Banco', 'Agencia', 'Conta', 'Loja', 'LinkPremio', 'DescricaoPremio', 'Status', 'DataResgate', 'LojaResgate', 'VendedorResgate'];

    constructor(public api: APIService, public ui: UIService) {}

    ngOnInit() {
        this.api
        .listarRelatoriosUsuario()
        .subscribe((response: any) => {
            if (!response.error) {
                this.relatorios = response.data.relatorios;
            }
        });
        this.setInitialValue();
    }

    ngOnDestroy() {
        this._onDestroy.next();
    }

    limpar(){
        this.vouchers = null;
        this.premios = null;
        this.itens = null;
        this.parceiros = null;
        this.mensagensParceiros = null;
    }

    filtrar() {
        this.vouchers = null;
        this.premios = null;
        this.itens = null;
        this.parceiros = null;

        if (this.model.Tipo === 1) {
            this.ui.block();

            this.api
                .obterRelatorioVouchers(this.model.DataDe, this.model.DataAte)
                .subscribe((response: any) => {
                    if (response.error) {
                        this.ui.error(response.error);
                    }
                    else {
                        this.vouchers = response.data;
                    }

                    this.ui.unblock();
                });
        } else if (this.model.Tipo === 2) {
            this.ui.block();

            this.api
                .obterRelatorioPremios(this.model.DataDe, this.model.DataAte)
                .subscribe((response: any) => {
                    if (response.error) {
                        this.ui.error(response.error);
                    } else this.premios = response.data;

                    this.ui.unblock();
                });
        } else if (this.model.Tipo === 3) {
            this.ui.block();

            this.api
                .obterRelatorioDistribuidos(
                    this.model.DataDe,
                    this.model.DataAte
                )
                .subscribe((response: any) => {
                    if (!response.error) {
                        this.trataRelatorio(
                            response.data.itens,
                            response.data.campos
                        );
                    } else {
                        this.ui.error(response.error);
                    }
                    this.ui.unblock();
                });
        }
        else if (this.model.Tipo === 4) {
            this.ui.block();

            this.api
                .obterRelatorioRecebidos(this.model.DataDe, this.model.DataAte)
                .subscribe((response: any) => {
                    if (!response.error) {
                        this.trataRelatorio(
                            response.data.itens,
                            response.data.campos
                        );
                    } else {
                        this.ui.error(response.error);
                    }
                    this.ui.unblock();
                });
        }
        else if (this.model.Tipo === 6) {
            let de = this.model.DataDe, ate = this.model.DataAte;
            let gestoresSelecionados = this.containerMultiCtrl.value.map(gestor => gestor ? gestor.Name: "");
            const parameter: any = {
                DataInicial: de
                    ? (moment(de).startOf('day') as any).toNET()
                    : undefined,
                DataFinal: ate
                    ? (moment(ate).endOf('day') as any).toNET()
                    : undefined,
                GestoresParceria: gestoresSelecionados,
                VisaoUnidade: this.model.VisaoUnidade
            };

            this.ui.block();

            this.api
                .listaParceiros(parameter)
                .subscribe((response: any) => {
                    if (response.error) {
                        this.ui.error(response.error);
                    } else{
                        this.parceiros = response.data.Itens;
                        this.parceiros.forEach(parceiro=>{
                            parceiro.GestorParceria = parceiro.GestorParceria;
                            if(this.carregarGestorParceria && parceiro.Responsavel && parceiro.Responsavel != ""){
                                if(!this.gestoresParceria.some(e => e.Name === parceiro.Responsavel)){
                                    this.gestoresParceria.push({Name: parceiro.Responsavel});
                                }
                            }
                            delete parceiro.Responsavel;
                        })

                        if(this.carregarGestorParceria){
                            this.gestoresParceria.sort((a, b) => (a.Name > b.Name) ? 1 : -1)
                            this.carregarGestorParceria = false;

                            this.listaGestoresParceria = [{
                                Name: "Gestor Parceria",
                                ContainerItems: this.gestoresParceria
                            }]
                        }

                        this.filteredContainerItemMulti.next(
                            this.copyContainerGroups(this.listaGestoresParceria)
                        );
                        this.containerMultiFilerCtrl.valueChanges
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe(() => {
                                this.filterItemsContainerMulti();
                        });

                    }
                    this.ui.unblock();
                }
            );
        }
        else if (this.model.Tipo === 7) {
            let de = this.model.DataDe, ate = this.model.DataAte;
            let parameter: any = {
                DataInicial: de
                    ? (moment(de).startOf('day') as any).toNET()
                    : undefined,
                DataFinal: ate
                    ? (moment(ate).endOf('day') as any).toNET()
                    : undefined
            };


            if (this.sort && this.sort.direction) {

                parameter.Sort = this.sort.active + '|' + this.sort.direction;
            }
            else {

                parameter.Sort = 'Parceiro|asc';
            }

            this.ui.block();

            this.api
                .listarMensagemParceiros(parameter)
                .subscribe((response: any) => {
                    if (response.error) {
                        this.ui.error(response.error);
                    } else{
                        this.mensagensParceiros = response.data.MensagensParceiros;
                        this.mensagensParceiros.forEach(element=>{
                            element.Data = this.dateFormat(element.Data);
                        })
                    }
                    this.ui.unblock();
                }
            );
        }
        else
        {
            this.ui.block();
            const relatorio=this.relatorios.find(a=>a.ID==this.model.Tipo);
            this.api
                .executarRelatorio(relatorio.Metodo, this.model.DataDe, this.model.DataAte)
                .subscribe((response: any) => {
                    if (!response.error) {
                        this.trataRelatorio(
                            response.data.itens,
                            response.data.campos
                        );
                    } else {
                        this.ui.error(response.error);
                    }
                    this.ui.unblock();
                }
            );
        }
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    protected setInitialValue() {
        this.containerMultiCtrl.setValue(
            []
        )
    }

    protected copyContainerGroups(containerGroups: Container[]) {
        const containerGroupsCopy = [];
        containerGroups.forEach(containerGroup => {
            containerGroupsCopy.push({
                Name: containerGroup.Name,
                ContainerItems: containerGroup.ContainerItems.slice()
            });
        });
        return containerGroupsCopy;
    }
    protected filterItemsContainerMulti() {
        let search = this.containerMultiFilerCtrl.value;
        const containerGroupsCopy = this.copyContainerGroups(this.listaGestoresParceria);
        if (!search) {
            this.filteredContainerItemMulti.next(containerGroupsCopy);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the recs
        this.filteredContainerItemMulti.next(
            containerGroupsCopy.filter(containerGroup => {
                const showRedeGroup =
                    containerGroup.Name.toLowerCase().indexOf(search) > -1;
                if (!showRedeGroup) {
                    containerGroup.ContainerItems = containerGroup.ContainerItems.filter(
                        a => a.Name.toLowerCase().indexOf(search) > -1
                    );
                }
                return containerGroup.ContainerItems.length > 0;
            })
        );
    }


    dateFormat(date){
        return moment(date).format('DD/MM/YYYY HH:mm')
    }

    sortChanged(sort: any) {

        this.filtrar();
    }


    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    trataRelatorio(itens: any[], campos: any[]) {
        this.columnsItens = [];

        campos.forEach(element => {
            this.columnsItens.push(element.Propriedade);
        });
        this.cabecalhos = [];
        campos.forEach(element => {
            this.cabecalhos.push(element.Cabecalho);
        });
        this.campos = campos;
        this.itens = itens;
    }
    limparFiltro() {
        this.model = {
            Tipo: this.model.Tipo
        };

        this.vouchers = null;
        this.premios = null;
        this.itens = null;
        this.parceiros = [];
        this.listaGestoresParceria = [];
        this.containerMultiCtrl.setValue(
            []
        );
        this.carregarGestorParceria = true;
    }

    excel() {
        var items = [];

        if (this.model.Tipo === 1) items = this.vouchers.slice();
        else if (this.model.Tipo === 2) items = this.premios.slice();
        else if (this.model.Tipo === 6) items = this.parceiros.slice();
        else if (this.model.Tipo === 7) items = this.mensagensParceiros.slice();
        else {
            this.itens.forEach(linha => {
                let novoItem = {};
                this.campos.forEach(coluna => {
                    novoItem[coluna.Cabecalho] = linha[coluna.Propriedade];
                });
                items.push(novoItem);
            });
        }

        this.api.exportAsExcelFile(items, "Relatorio");
    }
}

export interface GestorParceria {
    ID: string;
    Name: string;
}
export interface Container {
    Name: string;
    ContainerItems: GestorParceria[];
}
