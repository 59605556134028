import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { APIService } from '../../services/api.service';

@Component({
    selector: 'cs-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnDestroy {

    private subscription: any;

    public voucher: any;
    public lado: number = 1;

    constructor(private api: APIService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.subscription = this.route.params.subscribe(

            (params: any) => {

                const codigo: string = params['id'];

                if (codigo) {

                    this.api.obterVoucherCliente(codigo).subscribe(

                        (response: any) => {

                            if (!response.error) {

                                response.data.Voucher.Code = '000000' + (response.data.Voucher.Codigo || response.data.Voucher.ID).toString();
                                response.data.Voucher.Code = response.data.Voucher.Code.substr(response.data.Voucher.Code.length - 6);

                                this.voucher = response.data.Voucher;

                                const qrCode: string[] = this.voucher.Template.QRCode.split('|');

                                this.voucher.QRCode = {
                                    Top: qrCode[0],
                                    Left: qrCode[1],
                                    HalfTop: (Number(qrCode[0].replace('px', '')) / 2).toFixed(0) + 'px',
                                    HalfLeft: (Number(qrCode[1].replace('px', '')) / 2).toFixed(0) + 'px',
                                    Front: qrCode[2],
                                    Back: qrCode[3]
                                };
                            }
                        }
                    );
                }
            }
        );
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

    flip() {

        if (this.lado === 1) {

            this.lado = 2;
        }
        else {

            this.lado = 1;
        }
    }
}
