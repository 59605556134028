import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UIService } from '../../services/ui.service';

@Component({
    selector: 'cs-selecao-premios',
    templateUrl: './selecao-premios.component.html',
    styleUrls: ['./selecao-premios.component.scss']
})
export class SelecaoPremiosComponent implements OnInit {

    constructor(private ui: UIService, public dialogRef: MatDialogRef<SelecaoPremiosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }
}
