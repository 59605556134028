import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './../guards/auth.guard';

import { LoginComponent } from '../pages/login/login.component';
import { HomeComponent } from './../pages/home/home.component';

import { ComunicadosComponent } from './../pages/comunicados/comunicados.component';
import { ComunicadoComponent } from './../pages/comunicado/comunicado.component';

import { ParceirosComponent } from './../pages/parceiros/parceiros.component';
import { ParceiroComponent } from './../pages/parceiro/parceiro.component';

import { EstabelecimentosComponent } from './../pages/estabelecimentos/estabelecimentos.component';
import { EstabelecimentoComponent } from './../pages/estabelecimento/estabelecimento.component';

import { VendasComponent } from './../pages/vendas/vendas.component';
import { VendaComponent } from '../pages/venda/venda.component';

import { ParcelasComponent } from './../pages/parcelas/parcelas.component';
import { ReportsComponent } from './../pages/reports/reports.component';
import { ConfigReportsComponent } from './../pages/config-reports/config-reports.component';
import { ConfigReportComponent } from './../pages/config-report/config-report.component';

import { VouchersComponent } from './../pages/vouchers/vouchers.component';
import { VoucherComponent } from './../pages/voucher/voucher.component';

import { PremiosComponent } from './../pages/premios/premios.component';
import { PremioComponent } from './../pages/premio/premio.component';

import { GincanaComponent } from './../pages/gincana/gincana.component';
import { GincanasComponent } from './../pages/gincanas/gincanas.component';

import { CampanhaComponent } from './../pages/campanha/campanha.component';
import { CampanhasComponent } from './../pages/campanhas/campanhas.component';

import { OperacoesComponent } from './../pages/operacoes/operacoes.component';

import { RelatoriosComponent } from '../pages/relatorios/relatorios.component';

import { ConfiguracoesComponent } from './../pages/configuracoes/configuracoes.component';

import { ViewComponent } from '../pages/view/view.component';

import { ColaboradoresComponent } from '../pages/pages-parceiro/colaboradores/colaboradores.component';
import { ConfirmarEmailComponent } from '../pages/confirmar-email/confirmar-email.component';
import { CriarContaComponent } from '../pages/criar-conta/criar-conta.component';
import { EsqueceuSenhaComponent } from '../pages/esqueceu-senha/esqueceu-senha.component';
import { RedefinirSenhaComponent } from '../pages/redefinir-senha/redefinir-senha.component';
import { ConsultoresComponent } from '../pages/consultores/consultores.component';
import { ConsultorComponent } from '../pages/consultor/consultor.component';
import { ConfCobrancasComponent } from '../pages/conf-cobrancas/conf-cobrancas.component';
import { ConfCobrancaComponent } from '../pages/conf-cobranca/conf-cobranca.component';
import { RedePagamentosComponent } from '../pages/rede-pagamentos/rede-pagamentos.component';
import { RedePagamentoComponent } from '../pages/rede-pagamento/rede-pagamento.component';
import { ExecucaoAcaoCobrancasComponent } from '../pages/exec-acao-cobrancas/exec-acao-cobrancas.component';
import { ExecucaoAcaoCobrancasComunicadosComponent } from '../pages/exec-acao-cobrancas-comunicados/exec-acao-cobrancas-comunicados.component';
import { GrupoComponent } from '../pages/grupos/grupo.component';

const routes: Routes = [

    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },

    { path: 'comunicados', component: ComunicadosComponent, canActivate: [AuthGuard] },
    { path: 'comunicados/:id', component: ComunicadoComponent, canActivate: [AuthGuard] },
    { path: 'comunicados/:id/:modelo', component: ComunicadoComponent, canActivate: [AuthGuard] },

    { path: 'parceiros', component: ParceirosComponent, canActivate: [AuthGuard] },
    { path: 'parceiros/:id', component: ParceiroComponent, canActivate: [AuthGuard] },

    { path: 'estabelecimentos', component: EstabelecimentosComponent, canActivate: [AuthGuard] },
    { path: 'estabelecimentos/:id', component: EstabelecimentoComponent, canActivate: [AuthGuard] },

    { path: 'consultavendas', component: VendasComponent, canActivate: [AuthGuard] },
    { path: 'consultavendas/:id', component: VendaComponent, canActivate: [AuthGuard] },

    { path: 'consultaparcelas', component: ParcelasComponent, canActivate: [AuthGuard] },

    { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'reports/:id', component: ReportsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'configreports', component: ConfigReportsComponent, canActivate: [AuthGuard] },
    { path: 'configreports/:id', component: ConfigReportComponent, canActivate: [AuthGuard] },

    { path: 'vouchers', component: VouchersComponent, canActivate: [AuthGuard] },
    { path: 'vouchers/:id', component: VoucherComponent, canActivate: [AuthGuard] },
    { path: 'vouchers/:id/:modelo', component: VoucherComponent, canActivate: [AuthGuard] },

    { path: 'premios', component: PremiosComponent, canActivate: [AuthGuard] },
    { path: 'premios/:id', component: PremioComponent, canActivate: [AuthGuard] },
    { path: 'premios/:id/:modelo', component: PremioComponent, canActivate: [AuthGuard] },

    { path: 'gincanas', component: GincanasComponent, canActivate: [AuthGuard] },
    { path: 'gincana/:id', component: GincanaComponent, canActivate: [AuthGuard] },

    { path: 'campanhas', component: CampanhasComponent, canActivate: [AuthGuard] },
    { path: 'campanhas/:id', component: CampanhaComponent, canActivate: [AuthGuard] },

    { path: 'operacoes', component: OperacoesComponent, canActivate: [AuthGuard] },

    { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [AuthGuard] },

    { path: 'relatorios', component: RelatoriosComponent, canActivate: [AuthGuard] },

    { path: 'pages-parceiro/:id/colaboradores', component: ColaboradoresComponent, canActivate: [AuthGuard] },

    { path: 'confirmar-email/:codigoConfirmacao', component: ConfirmarEmailComponent },

    { path: 'redefinir-senha/:codigoRedefinicaoSenha', component: RedefinirSenhaComponent },

    // { path: 'registrar', component: CriarContaComponent },

    { path: 'recuperar-senha', component: EsqueceuSenhaComponent },

    { path: 'consultores', component: ConsultoresComponent, canActivate: [AuthGuard] },
    { path: 'consultors/:id', component: ConsultorComponent, canActivate: [AuthGuard] },

    { path: 'confcobrancas', component: ConfCobrancasComponent, canActivate: [AuthGuard] },
    { path: 'confcobrancas/:id', component: ConfCobrancaComponent, canActivate: [AuthGuard] },

    { path: 'redepagamentos', component: RedePagamentosComponent, canActivate: [AuthGuard] },
    { path: 'redepagamentos/:id', component: RedePagamentoComponent, canActivate: [AuthGuard] },

    { path: 'exec-acao-cobrancas', component: ExecucaoAcaoCobrancasComponent, canActivate: [AuthGuard] },

    { path: 'exec-acao-cobrancas-comunicados', component: ExecucaoAcaoCobrancasComunicadosComponent, canActivate: [AuthGuard] },

    { path: 'grupos', component: GrupoComponent, canActivate: [AuthGuard] },
    { path: 'grupos/:id', component: GrupoComponent, canActivate: [AuthGuard] },

    { path: 'view/:id', component: ViewComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', redirectTo: '/home' },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
