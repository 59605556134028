import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'cs-vendas',
    templateUrl: './vendas.component.html',
    styleUrls: ['./vendas.component.scss']
})
export class VendasComponent implements OnInit {

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public model: any = {
        TipoTransacao: 0,
        Status: 0,
        TipoAcao: 0,
        FiltroCliente: {},
        FiltroEstabelecimento: {},
        FiltroVendedor: {}
    };

    public StatusVenda: any[] = [
        { ID: 0, Descricao: '', Cor: '' },
        { ID: 1, Descricao: 'Pendente', Cor: '#ffff99' },
        { ID: 2, Descricao: 'Adimplente', Cor: '#ccff99' },
        { ID: 3, Descricao: 'Inadimplente', Cor: '#ff9999' },
        { ID: 4, Descricao: 'Finalizada', Cor: '' },
        { ID: 5, Descricao: 'Pedido Cancelado', Cor: '#d9d9d9' },
        { ID: 6, Descricao: 'Pagamento Cancelado', Cor: '#d9d9d9' },
        { ID: 7, Descricao: '', Cor: '' },
        { ID: 8, Descricao: 'Parcialmente Pago', Cor: '#086A87' },
        { ID: 9, Descricao: 'Cancelamento Pendente', Cor: '#B404AE' },
        { ID: 10, Descricao: 'Venda Cancelada', Cor: '#dc7b7b' },
        { ID: 11, Descricao: 'Cobrança Suspensa', Cor: '#f7e6ff' }

    ];
    public TiposPagamento: any[] = [
        { ID: 1, Descricao: 'Credito', Cor: '' },
        { ID: 2, Descricao: 'Credito Parcelado', Cor: '' },
        { ID: 3, Descricao: 'Credito Recorrente', Cor: '' },
        { ID: 4, Descricao: 'Debito', Cor: '' },
        { ID: 5, Descricao: 'Boleto', Cor: '' },
        { ID: 6, Descricao: 'Em Loja', Cor: '' },
        { ID: 7, Descricao: 'Outros', Cor: '' }
    ];
    public TiposAcaoVenda: any[] = [
        { ID: 1, Descricao: 'Venda', Cor: '' },
        // { ID: 10, Descricao: 'Cancelamento', Cor: '' },
        { ID: 8, Descricao: 'Renegociação', Cor: '' },
        // { ID: 9, Descricao: 'Alteração de Vencimentos', Cor: '' },
        { ID: 7, Descricao: 'Cancelamento Parcial', Cor: '' },
        { ID: 14, Descricao: 'Renovação', Cor: '' }
    ];

    public itens: any[] = [];
    public listRedes: any[] = [];
    public listGrupos: any[] = [];
    public listTipos: any[] = [];
    public listStatus: any[] = [];
    public listAcoes: any[] = [];

    public total: number;
    public dataSource = new MatTableDataSource<any>(this.itens);

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService,
        private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        this._columns = ['Acoes', 'Contrato', 'Numero', 'Data', 'Valor', 'TipoPagamento', 'Status', 'Pago', 'Parcelas', 'Sessoes', 'Cliente', 'Estabelecimento', 'Vendedor'];

        this.paginator.pageSize = 10;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.loadSession();

        this.listTipos = [{
            ID: 0,
            Descricao: 'Todos',
            Cor: ''
        }].concat(this.TiposPagamento);

        this.listStatus = [{
            ID: 0,
            Descricao: 'Todos',
            Cor: ''
        }].concat(this.StatusVenda.filter(a=> a.Descricao && a.Descricao != ''));

        this.listAcoes = [{
            ID: 0,
            Descricao: 'Todas',
            Cor: ''
        }].concat(this.TiposAcaoVenda);

        this.ui.block('Pesquisando redes...');
        setTimeout(() => {
            this.api.listarRedes({}).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {

                        if (response.data.Redes.length > 1) {
                            this.listRedes = [{
                                ID: '',
                                Nome: 'Todas'
                            }].concat(response.data.Redes);
                            this.model.FiltroEstabelecimento.RedeID = '';
                        } else {
                            this.listRedes = response.data.Redes;
                            this.model.FiltroEstabelecimento.RedeID = this.listRedes[0].ID;
                            this.listGrupos = this.listRedes[0].Grupos;
                        }

                    }

                }
            );
        });
    }

    limparFiltro() {

        this.model = {
            TipoTransacao: 0,
            Status: 0,
            TipoAcao: 0,
            FiltroCliente: {},
            FiltroEstabelecimento: {},
            FiltroVendedor: {}
        };
    }

    filtrar(page?: number, reset = false, gerarExcel = false) {

        this.ui.block('Pesquisando vendas...');

        let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        if (reset) {
            skip = 0;
            this.paginator.pageIndex = 0;
        }
        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;
        parameter.GerarExcel = gerarExcel;

        //if (this.sort && this.sort.direction) {
        //    parameter.Sort = this.sort.active + '|' + this.sort.direction;
        //}
        //else {
        //    parameter.Sort = 'Nome|asc';
        //}

        if (parameter.DataVendaDe)
            parameter.DataVendaDe = this.dateJS2DOTNET(parameter.DataVendaDe)
        else delete parameter.DataVendaDe;
        if (parameter.DataVendaAte)
            parameter.DataVendaAte = this.dateJS2DOTNET(parameter.DataVendaAte)
        else delete parameter.DataVendaAte;
        if (!parameter.Status) delete parameter.Status;
        if (!parameter.TipoTransacao) delete parameter.TipoTransacao;
        if (!parameter.TipoAcao) delete parameter.TipoAcao;

         if (parameter.FiltroEstabelecimento) {
            if (!parameter.FiltroEstabelecimento.GrupoID) parameter.FiltroEstabelecimento.GrupoID = '00000000-0000-0000-0000-000000000000';
            if (!parameter.FiltroEstabelecimento.RedeID) parameter.FiltroEstabelecimento.RedeID = '00000000-0000-0000-0000-000000000000';
        }

        this.api.obterVendas(parameter).subscribe(

            (response: any) => {
                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    //Gerar a planilha
                    if(response.data.Base64){
                        const linkSource = 'data:application/xlsx;base64,' + response.data.Base64;
                        const downloadLink = document.createElement("a");
                        const fileName = "vendas.xlsx";

                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    }

                    this.total = response.data.Total;
                    this.itens = response.data.Itens;

                    this.dataSource = new MatTableDataSource<any>(response.data.Itens);

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    showRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/consultavendas', element.VendaID]);
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            if (!this.model.FiltroCliente)
                this.model.FiltroCliente = {};
            if (!this.model.FiltroEstabelecimento)
                this.model.FiltroEstabelecimento = {};
            if (!this.model.FiltroVendedor)
                this.model.FiltroVendedor = {};

            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Numero';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    rowSelected(row: any) {
    }

    redeSelected(e) {
        var grupos = [];
        if (!e.value) {
            this.model.FiltroEstabelecimento.GrupoID = '';
            this.listGrupos = grupos;
        } else {
            const rede = this.listRedes.filter((r) => {
                return r.ID == e.value;
            });
            if (!rede.length) {
                this.model.FiltroEstabelecimento.GrupoID = '';
                this.listGrupos = grupos;
            } else {
                grupos = rede[0].Grupos;
                if (grupos.length == 0) {
                    this.listGrupos = grupos;
                } else if (grupos.length == 1) {
                    this.model.FiltroEstabelecimento.GrupoID = grupos[0].ID;
                    this.listGrupos = grupos;
                } else {
                    this.model.FiltroEstabelecimento.GrupoID = '';
                    this.listGrupos = [{
                        "ID": "",
                        "Nome": "Todos"
                    }].concat(grupos);

                }
            }

        }

    }

    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    dateDOTNET2JS(d) {
        const t = (d || '').match(/\/Date\((\d+)(\+|\-)(\d{2})(\d{2})\)\//) || [];
        if (t.length==5) {
          return new Date(parseInt(t[1]) + parseInt(t[2]+t[3])*3600000 + parseInt(t[2]+t[4])*60000);
        }
        const u = (d || '').match(/\/Date\((\d+)\)\//) || [];
        if (u.length==3) {
          return new Date(parseInt(u[1])/1000);
        }
        return null;
    }

    dateJS2DOTNET(d) {
        if (d) {
            const t: any = new Date(d);
            return '/Date(' + (t * 1) + '+0000)/';
        }
        return null;
    }

}
