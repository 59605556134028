import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy,
    Input,
    Inject
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { Observable } from "rxjs";

import { UIService } from "../../services/ui.service";
import { APIService } from "../../services/api.service";
import {Location} from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DialogMessageComunicadoCobrancaComponent } from "../conf-cobranca/conf-cobranca.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "cs-venda",
    templateUrl: "./venda.component.html",
    styleUrls: ["./venda.component.scss"],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})
export class VendaComponent implements OnInit, OnDestroy {
    @ViewChild("inputRede") inputRede: ElementRef;

    private subscription: any;
    public chavePagoLivre: string;
    public linkPagamento: string;
    private vendaID: string;

    public model: any = {
        ID: '0',
        Detalhes: {
            Venda: {
                Vendedor: {},
                Items: []
            },
            TransacaoOriginal: {
                Parcelas: []
            },
            PagamentosNegados: [],
            ExecucoesAcaoCobranca:[]
        }
    };

    public StatusVenda: any[] = [
        { ID: 1, Descricao: 'Pendente', Cor: '#d4d40a' },
        { ID: 2, Descricao: 'Adimplente', Cor: '#23a523' },
        { ID: 3, Descricao: 'Inadimplente', Cor: '#f34141' },
        { ID: 4, Descricao: 'Finalizada', Cor: '#abb1b7' },
        { ID: 5, Descricao: 'Pedido Cancelado', Cor: '#dc7b7b' },
        { ID: 6, Descricao: 'Pagamento Cancelado', Cor: '#dc7b7b' },
        { ID: 7, Descricao: 'Agendamento Cancelado', Cor: '#dc7b7b' },
        { ID: 8, Descricao: 'Parcialmente Pago', Cor: '#68a6e8' },
        { ID: 9, Descricao: 'Cancelamento Pendente', Cor: '#B404AE' },
        { ID: 10, Descricao: 'Venda Cancelada', Cor: '#dc7b7b' },
        { ID: 11, Descricao: 'Cobrança Suspensa', Cor: '#f7e6ff' }
    ];
    public StatusParcela: any[] = [
        { ID: 1, Descricao: 'Agendada', Cor: '#eaea90' },
        { ID: 2, Descricao: 'Efetivada', Cor: '#65d87f' },
        { ID: 3, Descricao: 'Negada', Cor: '#f443368a' },
        { ID: 4, Descricao: 'Renegociada', Cor: '#d9d9d9' },
        { ID: 5, Descricao: 'Substituida', Cor: '#d9d9d9' },
        { ID: 6, Descricao: 'Pagamento Cancelado', Cor: '#dc7b7b' },
        { ID: 7, Descricao: 'Aguardando Reprocessamento', Cor: '#eaea90' },
        { ID: 8, Descricao: 'Agendamento Cancelado', Cor: '#dc7b7b' },
        { ID: 9, Descricao: 'Cancelado Teste', Cor: '#dc7b7b' },
        { ID: 10, Descricao: 'Agendado Manual', Cor:  '#eaea90'},
        { ID: 11, Descricao: 'PagoEmLoja', Cor: '#65d87f' },
        { ID: 12, Descricao: 'Aguardando Cancelamento Pagamento', Cor: '#eaea90' },
        { ID: 13, Descricao: 'Pago em Boleto', Cor: '#99ff33' },
    ];
    public TiposPagamento: any[] = [
        { ID: 1, Descricao: 'Credito', Cor: '' },
        { ID: 2, Descricao: 'Credito Parcelado', Cor: '' },
        { ID: 3, Descricao: 'Credito Recorrente', Cor: '' },
        { ID: 4, Descricao: 'Debito', Cor: '' },
        { ID: 5, Descricao: 'Boleto', Cor: '' },
        { ID: 6, Descricao: 'Em Loja', Cor: '' },
        { ID: 7, Descricao: 'Outros', Cor: '' }
    ];
    public TipoTransacao=[
        'Indefinido',
        'Venda',
        'CobrancaComTrocaDeCartao',
        'CobrancaComOutroCartao',
        'PagamentoAgendado',
        'PagamentoEmLoja',
        'PagamentoViaRetentativaAutomatica',
        'CancelamentoParcial',
        'Renegociacao'
    ];
    public StatusPagamento: any[] = [
        { ID: 1, Descricao: 'Pendente', Cor: '#d4d40a' },
        { ID: 2, Descricao: 'Pago', Cor: '#23a523' },
        { ID: 3, Descricao: 'Em Andamento', Cor: '#68a6e8' },
        { ID: 4, Descricao: 'Solicitação Cancelada', Cor: '#dc7b7b' },
        { ID: 5, Descricao: 'Pagamento Cancelado', Cor: '#dc7b7b' },
        { ID: 6, Descricao: 'Cancelamento Pendente', Cor: '#d4d40a' },
        { ID: 7, Descricao: 'Substituído', Cor: 'darkgray' },
        { ID: 8, Descricao: 'Excluído', Cor: 'red' }
    ];

    public listStatusParcela: any[] = [];
    public listStatusVenda: any[] = [];
    public listTipos: any[] = [];
    public listStatusPagamento: any[] = [];

    public _columnsTransacoes: string[] = [];
    public _columnsItens: string[] = [];
    public _columnsNegados: string[] = [];
    public _columnsExecucoesCobrancas: string[] = [];

    constructor(
        public dialog: MatDialog,
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private sanitize : DomSanitizer
    ) {}

    ngOnInit() {

        this.listTipos = [{
            ID: '',
            Descricao: '',
            Cor: ''
        }].concat(this.TiposPagamento);

        this.listStatusParcela = [{
            ID: '',
            Descricao: '',
            Cor: ''
        }].concat(this.StatusParcela);

        this.listStatusVenda = [{
            ID: '',
            Descricao: '',
            Cor: ''
        }].concat(this.StatusVenda);

        this.listStatusPagamento = [{
            ID: '',
            Descricao: '',
            Cor: ''
        }].concat(this.StatusPagamento);

        this._columnsTransacoes = ['Numero', 'Status', 'Valor', 'DataVencimento', 'DataPagamento', 'Autorizadora', 'Rede', 'Cartao', 'Autorizacao', 'NSUESitef', 'NSUHost', 'Recibo'];
        this._columnsItens = ['Codigo', 'Descricao', 'Valor', 'Desconto', 'Quantidade', 'Total'];
        this._columnsNegados = ['Data', 'Valor', 'Rede', 'Autorizadora', 'Cartao', 'Retorno', 'Mensagem'];
        this._columnsExecucoesCobrancas = ['Acoes', 'Valor', 'InicioProcessamento', 'Status', 'Parcelas', 'NumeroTentativas',
            'Cartao', 'DiasAtraso', 'TipoDeCobranca', 'Erro'];

        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];

            if (id != '0') {
                this.vendaID = id;
                this.ui.block();

                const parameter: any = {
                    ID: id
                };

                this.api.obterVenda(parameter).subscribe((response: any) => {
                    this.ui.unblock();

                    if (response.error) {
                        this.ui.error(response.error);
                        this.close();
                    } else {
                        this.model.Detalhes = response.data.Detalhes;

                        if (!this.model.Detalhes.Venda) {
                            this.model.Detalhes.Venda = {
                                Vendedor: {},
                                Items: []
                            };
                        }
                        let _data = this.dateDOTNET2JS(response.data.Detalhes.Venda.Data);
                        this.model._Data = _data ? _data.toLocaleDateString() : null;
                        this.model._Valor = this.valor(response.data.Detalhes.Venda.Valor);
                        this.model._Tipo = response.data.Detalhes.TransacaoOriginal ? this.listTipos[response.data.Detalhes.TransacaoOriginal.Tipo].Descricao : null;
                        this.model._Status = this.listStatusVenda[response.data.Detalhes.Status].Descricao;
                        this.model._Sessoes = (response.data.Detalhes.TotalSessoes || 0)
                            ? `${response.data.Detalhes.SessaoAtual || 0} de ${response.data.Detalhes.TotalSessoes}`
                            : '';

                        this.model.Detalhes.PagamentosNegados.map((p) => {
                            p._Data = this.dateDOTNET2JS(p.Data).toLocaleDateString();
                            return p;
                        });
                        this.obterTicketsParcelas();
                        //setTimeout(() => {
                        //    this.inputRede.nativeElement.focus();
                        //});
                    }
                });

            } else {

                //setTimeout(() => {
                //    this.inputRede.nativeElement.focus();
                //});
            }
        });
    }

    private obterTicketsParcelas() {
        let parcelas = [];
        if (this.model.Detalhes.AcoesVenda) {
            this.model.Detalhes.AcoesVenda.forEach(element => {
                if (element.Transacoes) {
                    element.Transacoes.forEach(transacao => {
                        if (transacao.Parcelas) {
                            transacao.Parcelas.forEach(parcela => {
                                parcelas.push(parcela);
                            });
                        }
                    });
                }
            });
            if (!parcelas || parcelas.length == 0)
                return;
            const parameter: any = {
                ListParcelasID: parcelas.map(p => p.ID)
            };

            this.ui.block();

            this.api.obterTicketsParcelas(parameter).subscribe((response: any) => {
                this.ui.unblock();

                if (response.error) {
                    this.ui.error(response.error);
                    this.close();
                } else {
                    if (response.data.Tickets) {
                        response.data.Tickets.forEach(element => {
                            let p = parcelas.find(a => a.ID == element.ParcelaID);
                            if (p) {
                                p.Recibo = element.Recibo;
                                p.ReciboCancelamento = element.ReciboCancelamento;
                            }
                        });
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    rowSelected(row: any) {
    }

    close() {
        this.location.back();
    }
    gerarLinkPagmento(){
        this.ui.block();
        this.api.gerarLinkPagamento(this.vendaID).subscribe((response: any) => {
            this.ui.unblock();

            if (response.error) {
                this.ui.error(response.error);

            } else {
                this.linkPagamento = response.data.URL;
            }});

    }
    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    valor(valor: number){
        return (valor).toLocaleString(undefined, {
            minimumFractionDigits : 2,
            maximumFractionDigits : 2,
            style                 : "decimal"
        })
    }

    dateDOTNET2JS(d) {
        const t = (d || '').match(/\/Date\((\d+)(\+|\-)(\d{2})(\d{2})\)\//) || [];
        if (t.length==5) {
          return new Date(parseInt(t[1]) + parseInt(t[2]+t[3])*3600000 + parseInt(t[2]+t[4])*60000);
        }
        const u = (d || '').match(/\/Date\((\d+)\)\//) || [];
        if (u.length==3) {
          return new Date(parseInt(u[1])/1000);
        }
        return null;
    }

    dateJS2DOTNET(d) {
        if (d) {
            const t: any = new Date(d);
            return '/Date(' + (t * 1) + '+0000)/';
        }
        return null;
    }

    getColorByStatusExecucao(e) {
        switch (e.StatusID) {
            case 1://Pendente
                return "#ffff99";
            case 2://Executada com Efetivação
                return "#ccffcc";
            case 3://Executada com Negada
                return "#ff9999";
            case 3://Executada sem Transação
                return "#0FCB33";
            default:
                return "";
        }
    }

    openMessageComunicado(e){
        const dialogRef = this.dialog.open(DialogMessageComunicadoCobrancaComponent, {
            width: '55%',
            height: '40%',
            data: { Mensagem: this.sanitize.bypassSecurityTrustHtml(e.MensagemComunicado),//JSON.parse(JSON.stringify(this.sanitize.bypassSecurityTrustHtml(e.MensagemComunicado))),
                    TipoEmail: e.TipoComunicadoID == 1 || e.TipoComunicadoID == 5,
                    ReadOnly: true,
                    ParametrosMensagem: {}
                  }
        },);
    }

    recuperarMensagensEventos() {
        this.model.Detalhes.MensagemEventos = [];
        const parameter: any = {
            RVendaID: this.vendaID
        };
        this.ui.block();
        this.api.listarMensagemEventos(parameter).subscribe((response: any) => {
            this.ui.unblock();

            if (response.error) {
                this.ui.error(response.error);

            } else {
                if (response.data.MensagemEventos && response.data.MensagemEventos.length > 0) {
                    for (let i = 0; i < response.data.MensagemEventos.length; i++) {
                        let item = response.data.MensagemEventos[i];
                        let index = 0;
                        if (!this.model.Detalhes.MensagemEventos.find(a => a.ID == item.MensagemID)) {
                            this.model.Detalhes.MensagemEventos.push({ ID: item.MensagemID, Texto: item.TextoMensagem });
                        }
                        index = this.model.Detalhes.MensagemEventos.findIndex(a => a.ID == item.MensagemID);

                        if (!this.model.Detalhes.MensagemEventos[index].Eventos)
                            this.model.Detalhes.MensagemEventos[index].Eventos = [];
                        this.model.Detalhes.MensagemEventos[index].Eventos.push({ DataStr: item.DataStr, ID: item.ID, MensagemID: item.MensagemID, Status: item.Status, StatusProvedor: item.StatusProvedor });
                    }
                }
            }
        });
    }

    exibirRecibo(e: any, title: string) {
        this.dialog.open(DialogReciboComponent, {
            width: '25%',
            height: '75%',
            data: {
                Recibo: e,
                Title: title
            }
        });
    }
}


@Component({
    selector: 'cs-venda',
    //template:'',
    templateUrl:'./recibo.html',
    styleUrls: ['./venda.component.scss']
})

export class DialogReciboComponent {

    constructor(public dialogRef: MatDialogRef<DialogReciboComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api: APIService) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
