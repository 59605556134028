import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

import { UIService } from '../../../services/ui.service';
import { APIService } from './../../../services/api.service';

@Component({
  selector: 'cs-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.scss']
})

export class ColaboradoresComponent implements OnInit, OnDestroy {

  private subscription: any;

  public model: any = {
    Colaboradores: []
  };

  constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.subscription = this.route.params.subscribe(

        (response: any) => {

            this.ui.block();

            const parameter: any = {
                ID: this.api.usuario.ParceiroID
            };

            this.api.obterParceiro(parameter).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.ui.error(response.error);
                        this.router.navigate(['/home']);
                    }
                    else {

                        this.model = response.data.Parceiro;

                    }
                }
            );
        }
    );
  }

  ngOnDestroy() {

    this.subscription.unsubscribe();
}
  cancelar() {

    this.router.navigate(['/home']);
  }

  validar() {

      let podeSalvar: boolean = true;
      let reason: string;

      if (this.model.Colaboradores.length) {

          const todos: number = this.model.Colaboradores.length;
          const telefones: number = Array.from(new Set(this.model.Colaboradores.map((colaborador: any) => colaborador.Telefone))).length;
          const nomes: number = Array.from(new Set(this.model.Colaboradores.map((colaborador: any) => colaborador.Nome))).length;

          const semTelefone: boolean = this.model.Colaboradores.some((colaborador) => !colaborador.Telefone);
          const semNome: boolean = this.model.Colaboradores.some((colaborador) => !colaborador.Nome);

          if (todos !== telefones || todos !== nomes) {

              podeSalvar = false;
              reason = 'existem colaboradores com o nome e/ou telefone repetidos.';
          }
          else if (semNome || semTelefone) {

              podeSalvar = false;
              reason = 'é obrigatório informar pelo menos o nome e o telefone dos colaboradores.';
          }
      }

      if (!podeSalvar) {

          this.ui.error('Não foi possível salvar os colaboradores pois ' + reason);
      }

      return podeSalvar;
  }

  salvar() {

      if (this.validar()) {

          this.ui.block();

          const parameter: any = {
              Parceiro: JSON.parse(JSON.stringify(this.model))
          };

          this.api.salvarParceiro(parameter).subscribe(

              (response: any) => {

                  this.ui.unblock();

                  if (response.error) {

                      this.ui.error(response.error);
                  }
                  else {

                      this.ui.success('Funcionários salvos com sucesso.');

                      this.router.navigate(['/home']);
                  }
              }
          );
      }
  }

  pesquisaColaborador(colaborador: any) {

    if (colaborador.Telefone && colaborador.Telefone.length === 11) {

        const parameter: any = {
            Telefone: colaborador.Telefone
        };

        this.api.obterColaborador(parameter).subscribe(

            (response: any) => {

                if (response.data && response.data.Colaborador) {

                    colaborador.ID = response.data.Colaborador.ID;
                    colaborador.Nome = response.data.Colaborador.Nome;
                    colaborador.CPF = response.data.Colaborador.CPF;
                    colaborador.Email = response.data.Colaborador.Email;
                }
            }
        );
    }
}
}

