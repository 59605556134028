import { Component, OnInit } from '@angular/core';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';
import { AuthGuard } from './../../guards/auth.guard';

@Component({
    selector: 'cs-configuracoes',
    templateUrl: './configuracoes.component.html',
    styleUrls: ['./configuracoes.component.scss']
})
export class ConfiguracoesComponent implements OnInit {

    model: any = {};
    errors: any = {};

    constructor(private ui: UIService, public api: APIService, public authGuard: AuthGuard) {

        this.ui.block();

        this.api.obterParametros().subscribe(

            (response: any) => {

                this.model = response.data.Parametros;
                this.ui.unblock();
            }
        );
    }

    ngOnInit() {
    }

    salvar() {

        this.ui.block();

        this.api.salvarParametros(this.model).subscribe(

            (response: any) => {

                this.ui.unblock();
                this.ui.success('Configurações salvas com sucesso.');
            }
        );
    }

    clearError(field: string) {

        this.errors[field] = undefined;
    }

    validate(field?: string) {

        if (!field || field === 'Senha') {

            if (!this.model.Senha) {

                this.errors.Senha = 'A senha é obrigatória.';
            }
        }

        if (!field || field == this.model.NovaSenha || field == this.model.ConfirmarNovaSenha)
        {
            if (this.model.NovaSenha != this.model.ConfirmarNovaSenha) {

                this.errors.NovaSenha = 'Nova senha e confirmação de nova senha não conferem.';
                this.errors.ConfirmarNovaSenha = 'Nova senha e confirmação de nova senha não conferem.';
            }
        }

        return ((JSON.stringify(this.errors)).length - 2);
    }

    trocarSenha() {

        this.errors = {};
        const errors = this.validate();

        if (!errors) {

            const parameter: any = {
              Email: this.api.usuario.Email,
              Senha: this.model.Senha,
              NovaSenha: this.model.NovaSenha
            };

            this.ui.block();

            this.api.trocarSenha(parameter).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.errors.Email = response.error;
                    }
                    else {

                        this.ui.success('Senha redefinida com sucesso.');
                    }
                }
            );
        }
        else {

            this.ui.error('Por favor verifique os campos inválidos.');
        }
      }
}
