import { style } from '@angular/animations';
import { Parceiro } from './../voucher/voucher.component';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy,
    Input
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";

import { Observable } from "rxjs";

import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";

import { MapDialogComponent } from "./../../components/map-dialog/map-dialog.component";

export interface Usuario {
    ID: string;
    Nome: string;
  }

@Component({
    selector: "cs-parceiro",
    templateUrl: "./parceiro.component.html",
    styleUrls: ["./parceiro.component.scss"]
})
export class ParceiroComponent implements OnInit, OnDestroy {
    @ViewChild("inputRede") inputRede: ElementRef;
    @Input() modal: string = undefined;
    public formControlGestorParceria = new FormControl();

    private subscription: any;
    public addingRede = false;
    public addingGrupo = false;
    public chavePagoLivre: string;
    public acao: string;
    public redes: any[] = [];
    public grupos: any[] = [];
    public IncluirColaboradoresInativos = false;
    public documentMask:string;


    public model: any = {
        ID: '0',
        Colaboradores: [],
        ListasDistribuidor: [],
        CEP:''
    };


    public sugestoes: any = {
        Redes: [],
        Grupos: [],
        Segmentos: [],
        Complexos: []
    };

    removeDocumentMask(){
        if(this.model.CNPJ.length < 11) {
            this.documentMask = null
        }
    }

    cpfcnpjmask(field) {

        let doc =  field != null ? field.currentTarget.value.replace(/[^\d]+/g,''): this.model.CNPJ.replace(/[^\d]+/g,'');

        if(!doc){
            this.documentMask = ""
        }else{
            let errMsg = "";
            if(doc.length == 14) {
                if(!this.ui.validarCNPJ(this.model.CNPJ)){
                    errMsg = "O CNPJ " + doc + "é inválido";
                }else{
                    this.documentMask = '00.000.000/0000-00'
                }
            }
            else if(doc.length == 11){
                if(!this.ui.validarCPF(doc)){
                    errMsg = "O CPF " + doc + "é inválido";
                }else{
                    this.documentMask = '000.000.000-00'
                }
            }else{
                errMsg = "Documento Inválido";
            }
            if(errMsg != ""){
                this.ui.error("Por favor verifique o documento informado!", errMsg);
                if(field != null){
                    field.target.focus();
                }
            }else{
                this.model.CNPJ = doc
            }
        }
    }


    public redesFiltradas: Observable<string>;

    public estados: string[] = [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO"
    ];

    public usuarios = [];
    public usuariosSelecionados = [];

    constructor(
        public dialog: MatDialog,
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    displayFn(usu:Usuario): string {
        return usu && usu.Nome ? usu.Nome : '';
    }

    ngOnInit() {
        this.ui.block();
        this.api.obterSugestoes().subscribe((response: any) => {
            if (!response.error) {
                this.sugestoes = response.data;
            }
        });

        // this.api.listarUsuarios({}).subscribe((response: any) => {
        //     if (response.error) {
        //     }
        //     else{
        //         this.usuarios = response.data.Usuarios;
        //         this.usuariosSelecionados = response.data.Usuarios;
        //     }
        // });


        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];
            if (id != '0') {
                this.model.ID = id;
                this.carregarParceiroExistente();
            } else {
                this.acao = "Inclusão";
                const parameterParceiro: any = JSON.parse(JSON.stringify(this.model));
                this.api.obterRedesParceiros(parameterParceiro).subscribe(
                    (response: any) => {

                        if (response.error) {

                            this.ui.error(response.error);
                        }
                        else {

                            //this.total = response.data.Total;
                            this.redes = response.data.RedesParceiros;
                        }
                    }
                );

                this.api.listarListasDistribuidor(parameterParceiro).subscribe(
                    (response: any) => {

                        if (response.error) {

                            this.ui.error(response.error);
                        }
                        else {

                            //this.total = response.data.Total;
                            this.model.ListasDistribuidor = response.data.ListasDistribuidor;
                        }
                    }
                );

                // setTimeout(() => {
                //     this.inputRede.nativeElement.focus();
                // });
            }
        });
    }//oninit fim

    ngAfterViewInit() {
        this.ui.unblock();
    }

    listarUsuarios(redeParceiroID){
        this.ui.block();
        this.api.listarUsuarios({RedeParceiroID: redeParceiroID}).subscribe((response: any) => {
            if (response.error) {
            }
            else{
                this.usuarios = response.data.Usuarios;
                this.usuariosSelecionados = response.data.Usuarios;

                if(this.model.GestorParceria){
                    this.setFormControlGestorParceria(this.model.GestorParceria);
                }
            }
            this.ui.unblock();
        });
    }


    setFormControlGestorParceria(id){
        this.ui.block();
        let us = this.usuarios.find(usu => usu.ID == id);
        this.formControlGestorParceria.setValue(us);
        this.ui.unblock();
    }

    carregarParceiroExistente(){
        const parameter: any = {
            ID: this.model.ID,
            IncluirColaboradoresInativos: this.IncluirColaboradoresInativos
        };
        const parameterParceiro: any = JSON.parse(JSON.stringify(this.model));

        this.api.obterRedesParceiros(parameterParceiro).subscribe(

            (response: any) => {

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    //this.total = response.data.Total;
                    this.redes = response.data.RedesParceiros;
                    this.api.obterParceiro(parameter).subscribe((response: any) => {

                        if (response.error) {
                            this.ui.unblock();
                            this.ui.error(response.error);
                            this.router.navigate(["/parceiros"]);
                        } else {
                            this.model = response.data.Parceiro;
                            this.model.ListasDistribuidor = response.data.ListasDistribuidor;
                            this.cpfcnpjmask(null);
                            this.model.Colaboradores = response.data.Colaboradores;
                            if (this.model.PossuiPagoLivre)
                                this.chavePagoLivre =
                                    "*****************************";

                            this.redes.forEach(it => {
                               if(it.Nome == response.data.Parceiro.NomeRede){
                                   this.model.RedeID = it.ID;
                                   this.listarUsuarios(it.ID);
                                   this.api.obterGruposParceiros (this.model.RedeID).subscribe(

                                    (response: any) => {

                                        if (response.error) {

                                            this.ui.error(response.error);
                                        }
                                        else {
                                            //this.total = response.data.Total;
                                            this.grupos = response.data.GruposParceiros;
                                            this.grupos.forEach(gr=> {
                                                if (gr.Nome == this.model.NomeGrupo){
                                                    this.model.GrupoID = gr.ID;
                                                }
                                            })
                                        }
                                    }
                                );
                               }
                            });

                            this.acao = "Edição";

                            // setTimeout(() => {
                            //     this.inputRede.nativeElement.focus();
                            // });
                        }
                    });
                }
            }
        );
    }


    getUserAndID(usuario){
        return `ID: ${usuario.ID}`;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    selecionarCoordenadas() {
        const dialogRef = this.dialog.open(MapDialogComponent, {
            width: "80vw",
            height: "85vh",
            data: {
                showRadius: false,
                coordinates: {
                    latitude: +this.model.Latitude,
                    longitude: +this.model.Longitude
                },
                address: null
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.model.Latitude = result.coordinates.latitude;
                this.model.Longitude = result.coordinates.longitude;

                if (result.address) {
                    this.model.Endereco = result.address.endereco;
                    this.model.Numero = result.address.numero;
                    this.model.Bairro = result.address.bairro;
                    this.model.Cidade = result.address.cidade;
                    this.model.Estado = result.address.estado;
                    this.model.CEP = result.address.cep;
                    this.model.EnderecoCompleto =
                        result.address.enderecoCompleto;
                }
            }
        });
    }

    cancelar() {
        this.router.navigate(["/parceiros"]);
    }

    validar() {
        let podeSalvar: boolean = true;
        let reason: string = '';

        if (!this.model.CNPJ || this.model.CNPJ.length < 11) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,"É necessário informar um documento: CNPJ ou CPF.");
        }

        if (!this.model.RedeID || this.model.RedeID === "00000000-0000-0000-0000-000000000000") {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason, "É necessário informar uma Rede.");
        }
        if (!this.model.GrupoID || this.model.GrupoID === "00000000-0000-0000-0000-000000000000") {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason, "É necessário informar um Grupo.");
        }

        if (!this.model.Nome) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,"É necessário informar uma Unidade.");
        }

        if (this.model.Colaboradores.length) {
            const todos: number = this.model.Colaboradores.length;
            const telefones: number = Array.from(
                new Set(
                    this.model.Colaboradores.map(
                        (colaborador: any) => colaborador.Telefone
                    )
                )
            ).length;
            const nomes: number = Array.from(
                new Set(
                    this.model.Colaboradores.map(
                        (colaborador: any) => colaborador.Nome
                    )
                )
            ).length;

            const cpfs: number = Array.from(
                new Set(
                    this.model.Colaboradores.map(
                        (colaborador: any) => colaborador.CPF
                    )
                )
            ).length;

            const semCPF: boolean = this.model.Colaboradores.some(
                colaborador => !colaborador.CPF
            );

            const semTelefone: boolean = this.model.Colaboradores.some(
                colaborador => !colaborador.Telefone
            );
            const semNome: boolean = this.model.Colaboradores.some(
                colaborador => !colaborador.Nome
            );

            if (semCPF) {
                podeSalvar = false;
                reason = this.ui.buildMessage(reason,"Existem colaboradores sem CPF.");
            }

            if (todos !== cpfs) {
                podeSalvar = false;
                reason = this.ui.buildMessage(reason,"Existem colaboradores com o CPF repetido.");
            }
            if (todos !== nomes) {
                podeSalvar = false;
                reason = this.ui.buildMessage(reason,"Existem colaboradores com o Nome repetido.");
            }
            if (todos !== telefones) {
                podeSalvar = false;
                reason = this.ui.buildMessage(reason,"Existem colaboradores com o Telefone repetido.");
            }

            if (semNome || semTelefone || semCPF) {
                podeSalvar = false;
                reason = this.ui.buildMessage(reason,"É necessário informar o CPF, o Nome e o Telefone dos colaboradores.");
            }
        }
        if (!podeSalvar) {
            this.ui.error(reason, "Não foi possível salvar o parceiro", {
                 "positionClass": "toast-top-right",//"toast-top-full-width",
                // "tapToDismiss": false,
                // "onclick": null,
                "fadeIn": 300,
                "fadeOut": 1000,
                "timeOut": 10000,
                "extendedTimeOut": 10000,
                // "closeButton": true,
              });
        }

        return podeSalvar;
    }

    salvar() {
        if(this.formControlGestorParceria && this.formControlGestorParceria.value){
            this.model.GestorParceria = this.formControlGestorParceria.value.ID;
        }
        if (this.validar()) {
            this.ui.block();
            if (this.chavePagoLivre == "*****************************")
                this.chavePagoLivre = null;
            if(this.model.ID=='0')
            {
                this.model.ID= '00000000-0000-0000-0000-000000000000';
            }
            const colaboradores=this.model.Colaboradores;
            const listasDistribuidor=this.model.ListasDistribuidor;
            //this.model.Colaboradores=[];
            const parameter: any = {
                ChavePagoLivre: this.chavePagoLivre,
                Parceiro: JSON.parse(JSON.stringify(this.model)),
                Colaboradores: colaboradores,
                ListasDistribuidor: listasDistribuidor
            };

            this.api.salvarParceiro(parameter).subscribe((response: any) => {
                this.ui.unblock();
                if (response.error) {
                    this.ui.error(response.error);
                } else {
                    this.ui.success("Parceiro salvo com sucesso.");

                    this.router.navigate(["/parceiros"]);
                }
            });
        }
    }

    onKey(value) {
        if(value.length > 2){
            this.usuariosSelecionados = this.search(value);
        }else{
            this.usuariosSelecionados = this.usuarios;
        }
    }
    search(value: string) {
        let filter = value.toLowerCase();
        return this.usuarios.filter(option => option.Nome.toLowerCase().startsWith(filter));
    }

    pesquisaColaborador(colaborador: any) {
        if (colaborador.CPF && colaborador.CPF.length === 11) {
            const parameter: any = {
                CPF: colaborador.CPF
            };

            this.api.obterColaborador(parameter).subscribe((response: any) => {
                if (response.data && response.data.Colaborador) {
                    colaborador.Nome = response.data.Colaborador.Nome;
                    colaborador.CPF = response.data.Colaborador.CPF;
                    colaborador.Email = response.data.Colaborador.Email;
                    colaborador.Telefone = response.data.Colaborador.Telefone;
                    colaborador.ExibeReenviarMensagem = true;
                }
            });
        }
    }

    selectionChanged(event)
    {
        this.api.obterGruposParceiros (event.value).subscribe(

            (response: any) => {

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    //this.total = response.data.Total;
                    this.grupos = response.data.GruposParceiros;
                }
            }
        );
    }

    addRede(event){
        this.addingRede = !this.addingRede;
    }
    saveRede(nome){
        const parameter: any = {
            Nome: nome
        };
        this.api.salvarRedeParceiro (parameter).subscribe(

            (response: any) => {

                if (response.error) {

                    this.ui.error(response.error);
                }
                 else {
                     var rd = response.RedeParceiro;
                     this.redes.push(rd);
                 }
                this.addingRede = !this.addingRede
            }
        );
    }

    addGrupo(event){
        this.addingGrupo = !this.addingGrupo
    }
    saveGrupo(nome){
        const parameter: any = {
            GrupoParceiro: {
                Nome: nome,
                Rede: {
                    ID: this.model.RedeID
                }
            }
        };
        this.api.salvarGrupoParceiro (parameter).subscribe(

            (response: any) => {
                if (response.error) {
                    this.ui.error(response.error);
                }
                else {
                    let g = response.GrupoParceiro;
                    this.grupos.push(g);
                }
                this.addingGrupo = !this.addingGrupo
            }
        );
    }

    linkResetSenha(colaboradorCPF){
        this.ui.block();
        const parameter = {
            Usuario: colaboradorCPF,
            ParceiroID: this.model.ID
        };

        this.api.obterLinkRedefinicaoSenhaTrybu(parameter).subscribe(
            (response: any) => {
                this.ui.unblock();
                if (response.error) {
                    this.ui.error(response.error);
                }
                else {
                    this.model.LinkResetSenha = response.data.Link;
                    console.log('Link Reset Senha: ' + this.model.LinkResetSenha );
                    this.ui.success('O Link foi enviado com sucesso','Redefinir Senha'
                                    ,{ timeOut: 15000 });

                }
            }
        );
    }

    copiarLink() {
        const textAreaElement = document.createElement('textarea');
        textAreaElement.style.position = 'fixed';
        textAreaElement.style.left = '0';
        textAreaElement.style.top = '0';
        textAreaElement.style.opacity = '0';
        textAreaElement.value = this.model.LinkResetSenha;
        document.body.appendChild(textAreaElement);
        textAreaElement.focus();
        textAreaElement.select();
        document.execCommand('copy');
        document.body.removeChild(textAreaElement);

        this.ui.success("Link copiado.");
    }

    buscarCEP() {
        this.ui.block();
        const cep = this.model.CEP;

        this.api.consultarCEP(cep).subscribe(
            (response: any) => {
                this.ui.unblock();
                if (response.error) {
                    this.ui.error(response.error);
                }
                else {
                    const dados = response.data;
                    this.model.Endereco = dados.logradouro;
                    this.model.Bairro = dados.bairro;
                    this.model.Cidade = dados.localidade;
                    this.model.Estado = dados.uf.toUpperCase();
                }
            }
        );
    }

    validarCPF(event, colaborador){
        if(colaborador.Inativo){
            return;
        }
        let cpf = event.target.value == undefined ? "": event.target.value;
        let res = this.ui.validarCPF(cpf);
        if(!res){
            this.ui.error("O cpf " + cpf + " é inválido", "Por favor, Corrija a informação");
            setTimeout(() => {
                event.target.focus();
                colaborador.Nome = "";
                colaborador.Email = "";
                colaborador.Telefone = "";
                colaborador.ExibeReenviarMensagem = false;
            },100);

        }else{
            this.pesquisaColaborador(colaborador);
        }
    }

}
