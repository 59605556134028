import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

@Component({
    selector: 'cs-comunicados',
    templateUrl: './comunicados.component.html',
    styleUrls: ['./comunicados.component.scss']
})
export class ComunicadosComponent implements OnInit {

    @Input() modal: string = undefined;
    @Input() publico: string = undefined;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    public model: any = {
        Clientes: true,
        Colaboradores: true
    };

    public total: number;
    public comunicados: any[] = [];

    public comunicadosSelecionados: any[] = [];

    public quantidade: number = 10;

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        if (this.publico === 'Clientes') {

            this.model.Clientes = true;
            this.model.Colaboradores = false;
        }
        else if (this.publico === 'Colaboradores') {

            this.model.Clientes = false;
            this.model.Colaboradores = true;
        }

        setTimeout(() => {

            this.paginator.pageSize = 10;

            this.loadSession();
        });
    }

    limparFiltro() {

        this.model = {
            Clientes: true,
            Colaboradores: true
        };
    }

    filtrar(page?: number) {

        this.ui.block('Pesquisando comunicados...');

        const skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;

        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        this.api.obterComunicados(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    this.comunicados = response.data.Comunicados;

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    newRecord(modelo?: number) {

        this.saveSession();

        if (!modelo) {

            this.router.navigate(['/comunicados', 0]);
        }
        else {

            this.router.navigate(['/comunicados', 0, modelo]);
        }
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/comunicados', element.ID]);
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session && (session.model.Clientes || session.model.Colaboradores)) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    selecionaComunicado(comunicado: any) {

        if (this.modal === 'single') {

            this.comunicadosSelecionados = [comunicado];
        }
        else {

            this.dialog.open(DialogVisualizarComunicadosComponent, {
                width: '414px',
                height: '736px',
                data: { comunicado: JSON.parse(JSON.stringify(comunicado)) }
            });
        }
    }

    comunicadoSelecionado(comunicado: any) {

        return this.comunicadosSelecionados.some((_comunicado) => _comunicado.ID === comunicado.ID);
    }
}

@Component({
    selector: 'cs-visualizar-comunicados',
    template: `
        <div style="position: relative; cursor: alias;" (click)="flip()">

            <img [src]="data.comunicado.Lado !== 2 ? data.comunicado.ImagemFrente : data.comunicado.ImagemVerso">

        </div>
    `,
})
export class DialogVisualizarComunicadosComponent {

    constructor(public dialogRef: MatDialogRef<DialogVisualizarComunicadosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    flip(): void {

        if (this.data.comunicado.Lado === 2) {

            this.data.comunicado.Lado = 1;
        }
        else {

            this.data.comunicado.Lado = 2;
        }
    }
}
