import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// Config model
export interface IAppConfig {
    baseUrl: string;
    baseUrlReports: string;
    applicationCode: string;
}

@Injectable()
export class AppConfigService {
    private _settings: IAppConfig;

    constructor(private http: HttpClient) {}

    load() {
        const jsonFile = './assets/config/app.config.json';
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile)
            .toPromise()
            .then((response: IAppConfig) => {
                this._settings = <IAppConfig>response;
                resolve();
            })
            .catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }

    get settings() {
        if (!this._settings) {
            throw Error('Config file not loaded yet!');
        }
        return this._settings;
    }


}