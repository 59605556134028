import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

import { MapDialogComponent } from './../../components/map-dialog/map-dialog.component';

@Component({
    selector: 'cs-parceiros',
    templateUrl: './parceiros.component.html',
    styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit {

    @Input() modal: string = undefined;

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public model: any = {
        Inativos: true
    };

    public unidades: any[] = [];

    public redes: any[] = [];
    public grupos: any[] = [];

    public estados: string[] = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

    public total: number;
    public dataSource = new MatTableDataSource<any>(this.unidades);
    public selection = new SelectionModel<any>(true, []);

    public unidadeSelecionada: any;

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {
        this.model.Raio = 1000; 

        setTimeout(() => {

            this._columns = [this.modal !== undefined ? 'Selecionado' : 'Acoes', 'Rede', 'Grupo', 'Nome', 'Contato', 'Telefone', 'Gestor Parceria'];

            this.paginator.pageSize = 10;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            if (this.modal) {

                this.model.Inativos = false;
            }

            this.loadSession();
        });
    }

    selecionarCoordenadas() {

        const dialogRef = this.dialog.open(MapDialogComponent, {
            width: '80vw',
            height: '85vh',
            data: {
                showRadius: true,
                coordinates: {
                    latitude: +this.model.Latitude,
                    longitude: +this.model.Longitude
                },
                radius: this.model.Raio
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {

                this.model.Latitude = result.coordinates.latitude;
                this.model.Longitude = result.coordinates.longitude;

                this.model.Raio = result.radius;

                this.filtrar();
            }
        });
    }

    limparFiltro() {

        this.model = {
            Inativos: true
        };
    }

    filtrar(page?: number, reset=false) {

        this.ui.block('Pesquisando parceiros...');

        let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        if(reset)
        {
            skip=0;
            this.paginator.pageIndex=0;
        }
        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        if (this.sort && this.sort.direction) {

            parameter.Sort = this.sort.active + '|' + this.sort.direction;
        }
        else {

            parameter.Sort = 'Nome|asc';
        }

        this.api.obterRedesParceiros(parameter).subscribe(

            (response: any) => {

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    //this.total = response.data.Total;
                    this.redes = response.data.RedesParceiros;
                }
            }
        );

        if(parameter.Latitude){
            parameter.Latitude = parseFloat(parameter.Latitude)
        }
        if(parameter.Longitude){
            parameter.Longitude = parseFloat(parameter.Longitude)
        }

        this.api.obterParceiros(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {
                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    this.unidades = response.data.Parceiros;

                    this.dataSource = new MatTableDataSource<any>(response.data.Parceiros);
                    this.selection = new SelectionModel<any>(true, []);

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );

    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    newRecord() {

        this.saveSession();
        this.router.navigate(['/parceiros', 0]);
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/parceiros', element.ID]);
    }

    deleteRecord(element: any) {
    }

    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Nome';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    kilometros() {

        if (!this.model.Raio) {

            return null;
        }

        return '~ ' + (this.model.Raio / 1000).toFixed(0);
    }

    selecionaUnidade(unidade: any) {

        this.unidadeSelecionada = unidade;
    }

    rowSelected(row: any) {

        if (!this.modal) {

            return;
        }

        if (this.modal === 'single') {

            this.selecionaUnidade(row);
        }
        else if (this.modal === 'multiple') {

            this.selection.toggle(row);
        }
    }

    selectionChanged(event)
    {
        this.api.obterGruposParceiros (event.value).subscribe(

            (response: any) => {

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    //this.total = response.data.Total;
                    this.grupos = response.data.GruposParceiros;
                }
            }
        );
    }

}
