import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

import { UIService } from './../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

import { SelecaoVouchersComponent } from './../../components/selecao-vouchers/selecao-vouchers.component';
import { SelecaoComunicadosComponent } from '../../components/selecao-comunicados/selecao-comunicados.component';

declare var window: any;

@Component({
    selector: 'cs-gincana',
    templateUrl: './gincana.component.html',
    styleUrls: ['./gincana.component.scss']
})
export class GincanaComponent implements OnInit, OnDestroy {

    @ViewChild('inputNome') inputNome: ElementRef;

    private subscription: any;

    public acao: string;

    public model: any = {
        ID: 0,
        Incentivos: [{
            Retornos: [{ Funcao: 1, Retornos: 0 }, { Funcao: 2, Retornos: 0 }, { Funcao: 3, Retornos: 0 }]
        }]
    };

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute, private storage: StorageService) {
    }

    ngOnInit() {

        this.subscription = this.route.params.subscribe(

            (params: any) => {

                const id = params['id'];

                if (id) {

                    this.ui.block();

                    const parameter: any = {
                        ID: id
                    };

                    this.api.obterGincana(parameter).subscribe(

                        (response: any) => {

                            this.ui.unblock();

                            if (response.error) {

                                this.ui.error(response.error);
                                this.router.navigate(['/gincanas']);
                            }
                            else {

                                const gincana: any = response.data.Gincana;

                                this.model.ID = gincana.ID;
                                this.model.Nome = gincana.Nome;

                                this.api.obterVouchers({ ID: gincana.BoasVindas }).subscribe(

                                    (response: any) => {

                                        if (response.error) {

                                            this.ui.error(response.error);
                                            this.router.navigate(['/gincanas']);
                                        }
                                        else {

                                            this.model.BoasVindas = response.data.Vouchers[0];
                                        }
                                    }
                                );

                                this.model.Incentivos = [];

                                for (const incentivo of gincana.Incentivos) {

                                    this.model.Incentivos.push(incentivo);

                                    this.api.obterVouchers({ ID: incentivo.Voucher }).subscribe(

                                        (response: any) => {

                                            if (response.error) {

                                                this.ui.error(response.error);
                                                this.router.navigate(['/gincanas']);
                                            }
                                            else {

                                                incentivo.Voucher = response.data.Vouchers[0];
                                            }
                                        }
                                    );
                                }

                                this.acao = 'Manutenção';
                            }
                        }
                    );
                }
                else {

                    this.acao = 'Criação';
                }
            }
        );
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

    cancelar() {

        this.router.navigate(['/gincanas']);
    }

    salvar() {

        const parameter: any = this.parameter();

        if (this.validar(parameter)) {

            this.ui.block();

            this.api.salvarGincana(parameter).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {

                        this.ui.success(this.model.ID ? 'Gincana atualizada com sucesso.' : 'Gincana criada com sucesso.');

                        this.router.navigate(['/gincanas']);
                    }
                }
            );
        }
    }

    selecionarVoucher(tipo: number, nivel?: any) {

        if (this.model.ID) {

            return;
        }

        this.storage.set('session', null);

        const dialogRef = this.dialog.open(SelecaoVouchersComponent, {
            minWidth: '1255px',
            width: '1255px',
            height: 'calc(820px + 92px)',
            data: {
                selection: 'single',
                publico: tipo == 1 ? 'Clientes' : 'Colaboradores'
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {

                if (tipo === 2) {

                    this.model.BoasVindas = result;
                }
                else if (tipo === 3) {

                    nivel.Voucher = result;

                    this.model.Incentivos.push({
                        Retornos: [{ Funcao: 1, Retornos: 0 }, { Funcao: 2, Retornos: 0 }, { Funcao: 3, Retornos: 0 }]
                    });
                }
            }
        });
    }

    selecionarComunicado() {

        if (this.model.ID) {

            return;
        }

        this.storage.set('session', null);

        const dialogRef = this.dialog.open(SelecaoComunicadosComponent, {
            minWidth: '1255px',
            width: '1255px',
            height: 'calc(820px + 92px)',
            data: {
                selection: 'single'
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {

                this.model.Regras = result;
            }
        });
    }

    onKeypress(event, decimals) {

        if (event.key === '.' || event.key === ',') {

            if (!decimals || decimals === 0) {

                event.preventDefault();
                return;
            }

            if (event.target.value.substring(event.target.selectionStart, event.target.selectionEnd).indexOf(',') >= 0 || event.target.value.indexOf(',') < 0) {

                let value = event.target.value.substr(0, event.target.selectionStart) + ',' + event.target.value.substr(event.target.selectionEnd);

                if (value.startsWith(',')) {

                    value = '0' + value;
                }

                event.target.value = value;
            }

            event.preventDefault();
        }
        else {

            const numeric = /^\d+$/.test(event.key);

            if (!numeric) {

                event.preventDefault();
            }
        }
    }

    formatNumber(event, decimals: number) {

        let currentValue = event.target.value;

        if (currentValue === ('0,')) {

            currentValue = '0';
        }

        if (currentValue.indexOf(',') >= 0) {

            currentValue = currentValue.split('.').join('').replace(',', '.');
        }

        let newValue = Number(currentValue).toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: decimals, maximumFractionDigits: decimals });

        if (newValue === 'NaN') {

            currentValue = 0;
            newValue = Number(currentValue).toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        }

        currentValue = Number(Number(currentValue).toFixed(decimals));

        setTimeout(() => { event.target.value = newValue; });
    }

    onPaste(event) {

        let pastedText;

        if (window.clipboardData && window.clipboardData.getData) {

            pastedText = window.clipboardData.getData('Text');
        }
        else if (event.clipboardData && event.clipboardData.getData) {

            pastedText = event.clipboardData.getData('text/plain');
        }

        if (isNaN(Number(pastedText))) {

            return false;
        }
    }

    parameter() {

        const _parameter: any = {
            ID: Number(this.model.ID),
            Nome: this.model.Nome,
            BoasVindas: this.model.BoasVindas ? Number(this.model.BoasVindas.ID) : 0,
            Regras: this.model.Regras ? Number(this.model.Regras.ID) : 0,
            Incentivos: []
        };

        for (const incentivo of this.model.Incentivos) {

            if (!incentivo.Voucher) {

                continue;
            }

            _parameter.Incentivos.push({
                ID: incentivo.ID,
                Nivel: incentivo.Nivel,
                Voucher: incentivo.Voucher.ID,
                Limite: incentivo.Limite,
                Prazo: incentivo.Prazo,
                Retornos: incentivo.Retornos
            });
        }

        return { Gincana: _parameter };
    }

    validar(parameter: any) {

        let podeSalvar: boolean = true;
        let reason: string;

        if (!parameter.Gincana.Nome) {

            podeSalvar = false;
            reason = 'é necessário informar um nome para a mesma.';
        }

        if (!podeSalvar) {

            this.ui.error('Não foi possível salvar a gincana pois ' + reason);
        }

        return podeSalvar;
    }
}
