import {Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, Inject} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from "@angular/material";

import { Observable } from "rxjs";

import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";
import { SelectionModel } from "@angular/cdk/collections";
import Swal from 'sweetalert2';

@Component({
    selector: 'cs-conf-cobranca',
    templateUrl: './conf-cobranca.component.html',
    styleUrls: ['./conf-cobranca.component.scss']
})
export class ConfCobrancaComponent implements OnInit {

    @Input() modal: string = undefined;

    public _acoesColumns: string[] = [];

    private subscription: any;
    public acao: string;

    public model: any = {
        ID: '0',
        Acoes:[]
    };

    public dataSourceAcoes = new MatTableDataSource<any>(this.model.Acoes);
    public selectionAcoes = new SelectionModel<any>(true, []);
    public unidadeAcaoSelecionada: any;

    constructor(  public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {

        this._acoesColumns = ['Operacoes','Descricao', 'TipoLista', 'DiasRetentativa', 'PermiteRetentarManual', 'PermiteTrocarCartao', 'ObrigaTrocarCartao', 'PermitePagarBoleto', 'Inativa'];

        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];

            if (id != '0') {
                this.ui.block();

                const parameter: any = {
                    ID: id
                };

                this.api.obterConfiguaracaoCobranca(parameter).subscribe((response: any) => {
                    this.ui.unblock();

                    if (response.error) {
                        this.ui.error(response.error);
                        this.router.navigate(["/confcobrancas"]);
                    } else {
                        this.model = response.data.Configuracao;
                        this.dataSourceAcoes = new MatTableDataSource<any>(this.model.Acoes);
                        this.selectionAcoes = new SelectionModel<any>(true, []);
                        this.acao = "Edição";
                    }
                });
            } else {
                this.acao = "Inclusão";
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancelar() {
        this.router.navigate(["/confcobrancas"]);
    }

    salvarConfiguracaoDeCobranca(){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.Configuracao = JSON.parse(JSON.stringify(this.model));
        parameter.Configuracao.Acoes.forEach(item => {
            let cr = [];
            if(item.CodigosRetorno){
                item.CodigosRetorno.forEach(element => {
                    if(!element.CodigoRetorno){
                        let aux = JSON.parse(JSON.stringify(element));
                        let obj = {CodigoRetorno : {}};
                        obj.CodigoRetorno = aux;
                        cr.push(obj);
                    }
                    else{
                        cr.push(JSON.parse(JSON.stringify(element)));
                    }
                });
                item.CodigosRetorno = cr;
            }
        });
        this.ui.block();

        this.api.atualizarConfiguracaoCobranca(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.ui.success("Registro salvo com sucesso.");
                }
            }
        );
    }

    getDescricaoTipo(value){
        switch (value) {
            case 1:
                return 'Permite Retentativa';
            case 2:
                return 'Lista de Códigos';//'Selecionados';
            case 3:
                return 'Restantes';
            case 4:
                return 'Erro Técnico';
            case 5:
                return 'Recorrência Agendada';
            case 6:
                return 'Boleto';
            default:
                return 'Outro';
        }

    }

    selecionaUnidade(unidade: any) {

        this.unidadeAcaoSelecionada = unidade;
    }

    rowSelected(row: any) {

        if (!this.modal) {
            return;
        }

        if (this.modal === 'single') {

            this.selecionaUnidade(row);
        }
        else if (this.modal === 'multiple') {

            this.selectionAcoes.toggle(row);
        }
    }

    isDisabled(element, checkInativa) {
        if (this.model.Inativa == true)
            return true;
        if (element.TipoLista == 3)
            return true;
        if (!checkInativa && element.Inativa)
            return true;
        if (!checkInativa && element.TipoLista != 2)
            return true;
        if (checkInativa && element.TipoLista != 2)
            return true;
    }

    isDisabledDias(element) {
        if (this.model.Inativa == true)
            return true;
        if (element.Inativa)
            return true;
        if (element.TipoLista == 3)
            return true;

        return false;
    }

    getMainGridStyle(element) {
        if (this.isDisabled(element, false))
            return 'color:red; cursor:unset;'
        else
            return 'color:gray; ';
    }

    excluirAcao(index){
        if(this.model.Acoes != null){
            Swal.fire({
                title: 'Atenção!',
                text: "Deseja mesmo realizar a exclusão? A ação será irreversível.",
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, deletar!'
            }).then((result) => {
                if (result.value) {
                    this.excluirAcaoConfiguracaoCobranca(index);
                }
            });
        }
    }

    private excluirAcaoConfiguracaoCobranca(index){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.ConfiguracaoID = this.model.ID;
        parameter.Acao = JSON.parse(JSON.stringify(this.model.Acoes[index]));

        this.ui.block();

        this.api.excluirAcaoConfiguracaoCobranca(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.model.Acoes.splice(index,1);
                    this.dataSourceAcoes = new MatTableDataSource<any>(this.model.Acoes);
                    Swal.fire(
                        'Sucesso!',
                        'Registro deletado com sucesso.',
                        'success'
                      );
                }
            }
        );
    }

    newRecordAcao(){
        if(this.model.Acoes == null)
            this.model.Acoes = [];

        this.unidadeAcaoSelecionada = {
            ConfiguracaoID: this.model.ID,
            Inativa: false,
            TipoLista: 2,
            Descricao: null,
            DiasRetentativa: null,
            PermiteTrocarCartao: false,
            PermiteRetentarManual: false,
            PermitePagarBoleto: false,
            ObrigaTrocarCartao: false,
            Itens: [],
            CodigosRetorno: []
        };
        this.openAcao(this.unidadeAcaoSelecionada);
    }

    openAcao(acao) {

        if (this.modal === 'single') {

            this.unidadeAcaoSelecionada = [acao];
        }
        else {

            const dialogRef = this.dialog.open(DialogVisualizarAcoesCobrancaComponent, {
                width: '30%',
                height: '55%',
                data: { acao: JSON.parse(JSON.stringify(acao)) }
            },);

            dialogRef.afterClosed().subscribe(result => {
                if (result != null) {
                    if (result.Descricao != null && result.Descricao != undefined && result.Descricao != ''
                        && result.DiasRetentativa != null && result.DiasRetentativa != undefined && result.DiasRetentativa != '') {
                        this.unidadeAcaoSelecionada.Descricao = result.Descricao;
                        this.unidadeAcaoSelecionada.DiasRetentativa = result.DiasRetentativa;
                        this.salvarAcaoConfiguracaoCobranca();
                    }
                    else {
                        this.ui.error("Forneça a Descrição e Dias Retentativa para inclusão de nova ação de cobrança.");
                    }
                }
            });
        }
    }

    private salvarAcaoConfiguracaoCobranca(){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.Acao = JSON.parse(JSON.stringify(this.unidadeAcaoSelecionada));
        parameter.Acao.Itens = [];
        parameter.Acao.CodigosRetorno = [];

        this.ui.block();

        this.api.salvarAcaoConfiguracaoCobranca(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.unidadeAcaoSelecionada = response.data.Acao;
                    this.unidadeAcaoSelecionada.ID = response.data.Acao.ID;
                    this.unidadeAcaoSelecionada.Inativa = response.data.Acao.Inativa;
                    this.unidadeAcaoSelecionada.TipoLista = response.data.Acao.TipoLista;
                    this.unidadeAcaoSelecionada.Descricao = response.data.Acao.Descricao;
                    this.unidadeAcaoSelecionada.DiasRetentativa = response.data.Acao.DiasRetentativa;
                    this.unidadeAcaoSelecionada.PermiteTrocarCartao = response.data.Acao.PermiteTrocarCartao;
                    this.unidadeAcaoSelecionada.PermiteRetentarManual = response.data.Acao.PermiteRetentarManual;
                    this.unidadeAcaoSelecionada.PermitePagarBoleto = response.data.Acao.PermitePagarBoleto;
                    this.unidadeAcaoSelecionada.ObrigaTrocarCartao = response.data.Acao.ObrigaTrocarCartao;

                    if (this.model.Acoes.filter(a => a.ID == this.unidadeAcaoSelecionada.ID).length > 0) {
                        let item = this.model.Acoes.find(a => a.ID == this.unidadeAcaoSelecionada.ID);
                        item = this.unidadeAcaoSelecionada;
                        this.dataSourceAcoes._updateChangeSubscription();
                    }
                    else {
                        this.model.Acoes.push(JSON.parse(JSON.stringify(this.unidadeAcaoSelecionada)));
                        this.dataSourceAcoes = new MatTableDataSource<any>(this.model.Acoes);
                    }
                    this.ui.success("Registro salvo com sucesso.");
                }

            }
        );
    }

    addCodigoRetorno(acao) {
        this.unidadeAcaoSelecionada = acao;
        let codigos = [];
        if(this.unidadeAcaoSelecionada.CodigosRetorno == null){
            this.unidadeAcaoSelecionada.CodigosRetorno = [];
        }
        this.unidadeAcaoSelecionada.CodigosRetorno.forEach(item => {
            if (item.CodigoRetorno != null) {
                codigos.push({
                    RedeID: item.CodigoRetorno.Rede.ID,
                    Nome: item.CodigoRetorno.Rede.Nome,
                    Codigo: item.CodigoRetorno.Codigo,
                    Descricao: item.CodigoRetorno.Descricao,
                    ID: item.CodigoRetorno.ID,
                    Mensagem: item.CodigoRetorno.Mensagem,
                    NaoPermiteRetentativa: item.CodigoRetorno.NaoPermiteRetentativa
                });
            }
            else{
                codigos.push(item);
            }
        });
        const dialogRef = this.dialog.open(DialogCodigoRetornoAcoesCobrancaComponent, {
            width: '80%',
            height: '90%',
            data: { CodigosRetorno: JSON.parse(JSON.stringify(codigos)) }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                acao.CodigosRetorno = result.CodigosRetorno;
                this.salvarAcaoCobrancaCodigoRetorno(acao.CodigosRetorno);
            }
        });
    }

    private salvarAcaoCobrancaCodigoRetorno(codigosRetorno){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.AcaoCobrancaID = this.unidadeAcaoSelecionada.ID;
        parameter.CodigosRetorno = this.parseAcaoCobrancaCodigoRetorno(codigosRetorno);

        this.ui.block();

        this.api.salvarAcaoCobrancaCodigoRetorno(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.ui.success("Códigos de retornos salvos com sucesso.");
                }

            }
        );
    }

    private parseAcaoCobrancaCodigoRetorno(codigosRetorno){
        let resultList = [];
        codigosRetorno.forEach(element => {
            resultList.push({
                AcaoCobrancaID: this.unidadeAcaoSelecionada.ID,
                CodigoRetorno:{
                    Nome: element.Nome,
                    Codigo: element.Codigo,
                    Descricao: element.Descricao,
                    ID: element.ID,
                    Mensagem: element.Mensagem,
                    NaoPermiteRetentativa: element.NaoPermiteRetentativa,
                    Rede: {
                        Nome:element.Nome,
                        ID: element.RedeID
                    }
                }
            })
        });
        return resultList;
    }

    addComunicado(acao: any) {
        if (acao.Itens == null) {
            acao.Itens = [];
        }
        const dialogRef = this.dialog.open(DialogComunicacaoAcoesCobrancaComponent, {
            width: '90%',
            height: '90%',
            data:{ID: acao.ID,
                Itens: JSON.parse(JSON.stringify(acao.Itens))
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                acao.Itens = result.Itens;
            }
        });
    }

    saveAcaoCobranca(e){
        this.unidadeAcaoSelecionada = e;
        this.salvarAcaoConfiguracaoCobranca();
    }

    openTemplateEmail() {
        const parameter: any = JSON.parse(JSON.stringify({}));
        this.ui.block();

        this.api.recuperarParamentrosMensagem(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    const dialogRef = this.dialog.open(DialogMessageComunicadoCobrancaComponent, {
                        width: '70%',
                        height: '90%',
                        data: {
                            Mensagem: JSON.parse(JSON.stringify(this.model.TemplateEmail)),
                            TipoEmail: true,
                            ReadOnly: false,
                            Linhas: 40,
                            ParametrosMensagem: response.data.Parametros
                        }
                    },
                    );
                    dialogRef.afterClosed().subscribe(result => {
                        if (result != null) {
                            this.model.TemplateEmail = result.Mensagem;
                        }
                    });
                }
            }
        );
    }
}

@Component({
    selector: 'cs-conf-cobranca',
    //selector: 'main-dialog-acao-cobranca',
    templateUrl:'./main-dialog-acao-cobranca.html',
    //template: './main-dialog-acao-cobranca.html',
    styleUrls: ['./conf-cobranca.component.scss']
})

export class DialogVisualizarAcoesCobrancaComponent {

    constructor(public dialogRef: MatDialogRef<DialogVisualizarAcoesCobrancaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public api: APIService) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-conf-cobranca',
    templateUrl:'./dialog-codigo-retorno.html',
    styleUrls: ['./conf-cobranca.component.scss']
})

export class DialogCodigoRetornoAcoesCobrancaComponent {

    public _codigoRetornoColumns: string[] = [];

    private subscription: any;

    public model: any = {
        ListaCodigoRetorno: []
    };

    public filtroCodigoRetorno: any = {
        Inativos: true,
        Descricao: null,
        ID: 0,
        RAutorizadoraID: 0
    };

    public listRedes: any [];
    public listaAutorizadoras: any[];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sortCodigoRetorno: MatSort;

    public dataSourceListaCodigoRetorno = new MatTableDataSource<any>();
    public selectionListaCodigoRetorno = new SelectionModel<any>(true, []);

    public total: number;

    constructor(public dialogRef: MatDialogRef<DialogCodigoRetornoAcoesCobrancaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute) {

        dialogRef.beforeClose().subscribe(result => {
            this.data.CodigosRetorno = this.selectionListaCodigoRetorno.selected;
        });
    }

    ngOnInit() {

        this._codigoRetornoColumns = ['Selecionado', 'Nome', 'Codigo', 'Descricao', 'Autorizadora', 'Mensagem', 'NaoPermiteRetentativa'];

        this.subscription = this.route.params.subscribe((params: any) => {

            this.filtrarCodigoRetorno(0,true, true)

        });
        this.listarRedesPagamento();

        this.api.listarAutorizadoras(JSON.parse(JSON.stringify({}))).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.listaAutorizadoras = response.data.Autorizadoras;
                }
            }
        );
    }

    getDescricaoAutorizadora(id) {
        if (this.listaAutorizadoras != null) {
            for (let i = 0; i < this.listaAutorizadoras.length; i++) {
                if (this.listaAutorizadoras[i].ID == id)
                    return this.listaAutorizadoras[i].Nome;
            }
        }
        return '';
    }

    filtrarCodigoRetorno(page?: number, reset = false, todos?: boolean) {

        //this.ui.block('Pesquisando Códigos de Retorno...');

        // let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        // if (reset) {
        //     skip = 0;
        //     this.paginator.pageIndex = 0;
        // }

        if(this.filtroCodigoRetorno.ID == 0)
            todos = true;

        const parameter: any = JSON.parse(JSON.stringify(this.filtroCodigoRetorno));
        parameter.Todos = todos;

        // parameter.Take = this.paginator.pageSize;
        // parameter.Skip = skip;

        this.api.obterRedePagamento(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    let result = [];
                    this.model.ListaCodigoRetorno = [];
                    this.selectionListaCodigoRetorno.clear();
                    if (todos == true)
                        result = response.data.ResultList;
                    else
                        result.push(response.data.Rede);

                    for(let item of result){
                        for (let subitem of item.ListaCodigoRetorno) {
                            this.model.ListaCodigoRetorno.push({
                                RedeID: item.ID,
                                Nome: item.Nome,
                                Codigo: subitem.Codigo,
                                Descricao: subitem.Descricao,
                                ID: subitem.ID,
                                Mensagem: subitem.Mensagem,
                                NaoPermiteRetentativa: subitem.NaoPermiteRetentativa,
                                RAutorizadoraID: subitem.RAutorizadoraID
                            });
                            if(this.data.CodigosRetorno.find(a=> a.ID == subitem.ID)){
                                this.selectionListaCodigoRetorno.select(this.model.ListaCodigoRetorno[this.model.ListaCodigoRetorno.length-1]);
                            }
                        }
                    }

                    this.dataSourceListaCodigoRetorno = new MatTableDataSource<any>(this.model.ListaCodigoRetorno);
                    this.dataSourceListaCodigoRetorno._updateChangeSubscription();

                    // if (page) {

                    //     this.paginator.pageIndex = page;
                    // }
                }
            }
        );
    }

    listarRedesPagamento(){
        const parameter: any = JSON.parse(JSON.stringify({}));

        parameter.Sort = 'Nome|asc';

        this.ui.block();

        this.api.listarRedePagamento(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.listRedes = response.data.RedesPagamentos;
                }

            }
        );
    }

    redeSelected(e) {
        if (e.value > 0) {
            this.filtrarCodigoRetorno(0,true, false);
        }
    }

    pageChanged(page: any) {

    }

    limparFiltroCodigoRetorno(){
        this.filtroCodigoRetorno = {
            Inativos: true,
            ID: 0,
            RAutorizadoraID: 0,
            Descricao: null
        };
    }

    isAllSelected(origem) {
        if (origem == 'codigoRetorno') {
            const numSelected = this.selectionListaCodigoRetorno.selected.length;
            const numRows = this.dataSourceListaCodigoRetorno.data.length;
            return numSelected === numRows;
        }
    }

    masterToggle(origem) {
        if (origem == 'codigoRetorno') {
            this.isAllSelected(origem) ? this.selectionListaCodigoRetorno.clear() : this.dataSourceListaCodigoRetorno.data.forEach(row => this.selectionListaCodigoRetorno.select(row));
            this.data.CodigosRetorno = this.selectionListaCodigoRetorno.selected;
        }
    }

    rowSelectedCodigoRetorno(row: any) {

        this.selectionListaCodigoRetorno.toggle(row);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-conf-cobranca',
    templateUrl:'./dialog-comunicados-acao-cobranca.html',
    styleUrls: ['./conf-cobranca.component.scss']
})

export class DialogComunicacaoAcoesCobrancaComponent {

    public _comunicadosColumns: string[] = [];
    public _itemColumns: string[] = [];
    public tiposComunicados = [
        { ID: 1, Descricao: 'Email Cliente' },
        { ID: 2, Descricao: 'SMS' },
        { ID: 3, Descricao: 'WhatsApp' },
        { ID: 4, Descricao: 'CRM' },
        { ID: 5, Descricao: 'Email Central Atendimento' },
        { ID: 7, Descricao: 'Push no App' }]

    private subscription: any;

    public model: any = {
        DiasAtraso: 0,
        Todos: false,
        Comunicados: []
    };
    public unidadeComunicado: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sortCodigoRetorno: MatSort;

    public dataSourceListaComunicados = new MatTableDataSource<any>();
    public selectionListaComunicados = new SelectionModel<any>(true, []);
    public dataSourceItem = new MatTableDataSource<any>();
    public selectionItem = new SelectionModel<any>(true, []);

    public total: number;
    public visualizarComunicados: boolean = false;
    private indexItem: number = -1;
    private parametrosMensagemComunicado: any[];

    constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DialogComunicacaoAcoesCobrancaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {

        this._comunicadosColumns = ['Operacoes', 'TipoComunicadoCobranca', 'Descricao', 'Assunto', 'AssuntoES', 'Mensagem','MensagemES', 'EmailCentralAtendimento'];
        this._itemColumns = ['Operacoes', 'DiasAtraso', 'TotalComunicados', 'EmailCliente', 'SMS', 'Whatsapp', 'CRM', 'EmailCentralAtendimento'];

        if (this.data.Itens == null) {
            this.data.Itens = [];
        }
        this.dataSourceItem = new MatTableDataSource<any>(this.data.Itens);

        this.subscription = this.route.params.subscribe((params: any) => {
        });

        this.recuperarParamentrosMensagem();

    }

    pageChanged(page: any) {

    }

    isAllSelected(origem) {
        const numSelected = this.selectionListaComunicados.selected.length;
        const numRows = this.dataSourceListaComunicados.data.length;
        this.data.Comunicados = this.selectionListaComunicados.selected;
        return numSelected === numRows;
    }

    masterToggle(origem) {
        this.isAllSelected(origem) ? this.selectionListaComunicados.clear() : this.dataSourceListaComunicados.data.forEach(row => this.selectionListaComunicados.select(row));
    }

    rowSelectedComunicado(row: any) {

        this.selectionListaComunicados.toggle(row);
    }

    getTotalByTipo(element, tipo){
        return element.Comunicados.filter(a=> a.TipoComunicadoCobranca == tipo).length;
    }

    tipoComunicadoSelected(value, index) {
        if (value != null && index >= 0) {
            switch (value) {
                case 1:
                    this.model.Comunicados[index].EmailCentralAtendimento = null;
                    break;
                case 2:
                    this.model.Comunicados[index].Assunto = null;
                    this.model.Comunicados[index].EmailCentralAtendimento = null;
                    break;
                case 3:
                    this.model.Comunicados[index].Assunto = null;
                    this.model.Comunicados[index].EmailCentralAtendimento = null;
                    break;
                default:
                    break;
            }
        }
    }

    isDisabledByTipo(item, field){
        if (item != null) {
            if(item.TipoComunicadoCobranca != 5 && field == 'emailCentral'){
                return true;
            }
        }
    }

    newRecordComunicado(){
        this.unidadeComunicado = {
            TipoComunicadoCobranca:0,
            Descricao: null,
            Assunto:null,
            Mensagem: null,
            EmailCentralAtendimento: null
        };
        this.model.Comunicados.push(this.unidadeComunicado);
        if(this.indexItem == -1)
            this.dataSourceListaComunicados.data.push(this.unidadeComunicado);// = new MatTableDataSource<any>(this.model.Comunicados);
        this.dataSourceListaComunicados._updateChangeSubscription();
    }

    excluirComunicado(index) {
        if (this.model.Comunicados != null) {
            Swal.fire({
                title: 'Atenção!',
                text: "Deseja mesmo realizar a exclusão? A ação será irreversível.",
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, deletar!'
            }).then((result) => {
                if (result.value) {
                    this.excluiComunicadoCobranca(index);
                }
            });
        }
    }

    private excluiComunicadoCobranca(index){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.ComunicadoCobranca = JSON.parse(JSON.stringify(this.model.Comunicados[index]));

        this.ui.block();

        this.api.excluiComunicadoCobranca(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.model.Comunicados.splice(index,1);
                    this.dataSourceListaComunicados.data.splice(index,1);// = new MatTableDataSource<any>(this.model.Acoes);
                    this.dataSourceListaComunicados._updateChangeSubscription();
                    Swal.fire(
                        'Sucesso!',
                        'Registro deletado com sucesso.',
                        'success'
                      );
                }
            }
        );
    }

    private resetModel() {
        this.model = {
            DiasAtraso: 0,
            Todos: false,
            Comunicados: []
        };
        this.unidadeComunicado = null;

        this.dataSourceListaComunicados = new MatTableDataSource<any>();
        this.selectionListaComunicados = new SelectionModel<any>(true, []);
    }

    newRecordItem(){
        this.resetModel();
        this.visualizarComunicados = true;
    }

    editRecord(item, index){
        this.model = item;
        this.indexItem = index;
        this.visualizarComunicados = true;
        this.dataSourceListaComunicados = new MatTableDataSource<any>(item.Comunicados);
        this.dataSourceListaComunicados._updateChangeSubscription();

    }

    excluirItem(index){
        if (this.data.Itens != null) {
            Swal.fire({
                title: 'Atenção!',
                text: "Deseja mesmo realizar a exclusão? A ação será irreversível.",
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, deletar!'
              }).then((result) => {
                if (result.value) {
                    this.excluirItemAcaoCobrancaComunicado(index);
                }
              });
        }
    }

    private excluirItemAcaoCobrancaComunicado(index){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.ItemAcaoCobranca = JSON.parse(JSON.stringify(this.data.Itens[index]));

        this.ui.block();

        this.api.excluirItemAcaoCobrancaComunicado(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.data.Itens.splice(index,1);
                    this.dataSourceItem._updateChangeSubscription();
                    Swal.fire(
                        'Sucesso!',
                        'Registro deletado com sucesso.',
                        'success'
                      );
                }
            }
        );
    }

    salvarComunicado() {
        if (this.data.Itens == null)
            this.data.Itens = [];

        if(!this.isValidSalvarComunicado()){
            return;
        }
        this.salvarItemAcaoCobrancaComunicado();
    }

    private salvarItemAcaoCobrancaComunicado(){
        const parameter: any = JSON.parse(JSON.stringify({}));
        parameter.Itens = [];
        parameter.Itens.push(JSON.parse(JSON.stringify(this.model)));
        parameter.AcaoCobrancaID = this.data.ID;

        this.ui.block();


        this.api.salvarItemAcaoCobrancaComunicado(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.data.Itens = response.data.Itens;
                    // if(this.indexItem < 0){
                    //     this.data.Itens.push(JSON.parse(JSON.stringify(this.model)))
                    // }
                    // else{
                    //     this.data.Itens[this.indexItem].DiasAtraso = this.model.DiasAtraso;
                    //     this.data.Itens[this.indexItem].Todos = this.model.Todos;
                    //     this.data.Itens[this.indexItem].Comunicados = this.model.Comunicados;
                    // }
                    //this.dataSourceItem._updateChangeSubscription();
                    this.dataSourceItem = new MatTableDataSource<any>(this.data.Itens);
                    this.visualizarComunicados = false;
                    this.resetModel();
                    this.indexItem = -1;
                }
            }
        );
    }

    private isValidSalvarComunicado(){
        let error = '';
        if(!this.model.Todos && (this.model.DiasAtraso == null || this.model.DiasAtraso == undefined || parseInt(this.model.DiasAtraso) < 0)){
            error = 'Forneça o valor para os Dias de Atraso. São aceitos valores a partir de 0. ';
        }
        if(this.model.Comunicados == null || this.model.Comunicados == undefined || this.model.Comunicados.length == 0){
            error += '<br/>Forneça pelo menos um comunicado.';
        }
        else{
            this.model.Comunicados.forEach(element => {
                if(element.TipoComunicadoCobranca == null || element.TipoComunicadoCobranca < 1){
                    error += '<br/>Existe(m) comunicado(s) sem tipo definido.';
                }
                if(element.Mensagem == null || element.Mensagem == ''){
                    error += '<br/>Existe(m) comunicado(s) sem mensagem definida.';
                }
                if((!element.Assunto || element.Assunto == '') && (element.TipoComunicadoCobranca == 1 || element.TipoComunicadoCobranca == 5)){
                    error += '<br/>Existe(m) comunicado(s) de envio de email sem assunto.';
                }
                
            });
        }
        if(error != ''){
            this.ui.error(error);
            return false;
        }
        return true;
    }

    newMessageComunicado(e){
        const dialogRef = this.dialog.open(DialogMessageComunicadoCobrancaComponent, {
            width: '50%',
            height: '80%',
            data: { Mensagem: JSON.parse(JSON.stringify(e.Mensagem)),
                    //MensagemES: JSON.parse(JSON.stringify(e.MensagemES)),
                    TipoEmail: e.TipoComunicadoCobranca == 1 || e.TipoComunicadoCobranca == 5,
                    ParametrosMensagem: JSON.parse(JSON.stringify(this.parametrosMensagemComunicado))
                  }
        },);

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                e.Mensagem = result.Mensagem;
                e.MensagemES = result.MensagemES;
            }
        });
    }

    private recuperarParamentrosMensagem(){
        const parameter: any = JSON.parse(JSON.stringify({}));

        this.ui.block();

        this.api.recuperarParamentrosMensagem(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {
                    this.parametrosMensagemComunicado = response.data.Parametros;
                }
            }
        );
    }

    cancelItem(){
        this.visualizarComunicados = false;
        this.resetModel();
        this.indexItem = -1;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-conf-cobranca',
    //template:'',
    templateUrl:'./dialog-message-comunicado-cobranca.html',
    styleUrls: ['./conf-cobranca.component.scss']
})

export class DialogMessageComunicadoCobrancaComponent {

    constructor(public dialogRef: MatDialogRef<DialogMessageComunicadoCobrancaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api: APIService) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
