import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UIService } from '../../services/ui.service';

@Component({
    selector: 'cs-selecao-vouchers',
    templateUrl: './selecao-vouchers.component.html',
    styleUrls: ['./selecao-vouchers.component.scss']
})
export class SelecaoVouchersComponent implements OnInit {

    constructor(private ui: UIService, public dialogRef: MatDialogRef<SelecaoVouchersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }
}
