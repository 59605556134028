import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { MatDialog, MatSelect } from "@angular/material";
import { ReplaySubject, Subject } from "rxjs";
import { Font, FontPickerService } from "ngx-font-picker";

import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";
import { StorageService } from "./../../services/storage.service";

import html2canvas from "html2canvas";

import * as moment from "moment";
import { FormControl } from "@angular/forms";
import { takeUntil, take } from "rxjs/operators";
import { ParceiroComponent } from "../parceiro/parceiro.component";

declare var window: any;

@Component({
    selector: "cs-voucher",
    templateUrl: "./voucher.component.html",
    styleUrls: ["./voucher.component.scss"]
})
export class VoucherComponent implements OnInit, OnDestroy {
    @ViewChild("cropperFrente") cropperFrente: any;
    @ViewChild("opacityFrente") opacityFrente: any;

    @ViewChild("cropperVerso") cropperVerso: any;
    @ViewChild("opacityVerso") opacityVerso: any;
    @ViewChild("multiSelect") multiSelect: MatSelect;
    @ViewChild("singleSelect") singleSelect: MatSelect;
    public isReady: boolean;

    private subscription: any;
    //public configuracoes = [];
    public acao: string;

    public model: any = {
        Parceiro: {},
        Lojas: [],
        ValorVenda: 0,
        RetornoVendedores: 0,
        RetornoCaixas: 0,
        RetornoGerentes: 0,
        AlvoClientes: true
    };

    public parameter: any = {};
    public parceirosMultiCtrl: FormControl = new FormControl();
    public parceirosMultiFilerCtrl: FormControl = new FormControl();
    public filteredParceirosMulti: ReplaySubject<Rede[]> = new ReplaySubject<
        Rede[]
    >(1);

    protected _onDestroy = new Subject<void>();

    public emissorMultiCtrl: FormControl = new FormControl();
    public emissorMultiFilerCtrl: FormControl = new FormControl();
    public filteredEmissorMulti: ReplaySubject<Parceiro[]> = new ReplaySubject<
        Parceiro[]
    >(1);

    protected _onDestroyEmissor = new Subject<void>();

    public parceirosEmissores: Parceiro[] = [];
    public parceirosReceptores: Parceiro[] = [];
    public redes: Rede[] = [];
    public ladoVoucher: number = 1;

    public cropperReadyFrente: boolean;
    public croppedImageFrente: any;

    public cropperReadyVerso: boolean;
    public croppedImageVerso: any;

    public robotoFiles: any = {
        "100": "http://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgWxP.ttf",
        "300":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5vAw.ttf",
        "500":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9vAw.ttf",
        "700":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlvAw.ttf",
        "900":
            "http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmYUtvAw.ttf",
        "100italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOiCnqEu92Fr1Mu51QrIzc.ttf",
        "300italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TjARc9.ttf",
        regular: "http://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Me5Q.ttf",
        italic:
            "http://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu52xP.ttf",
        "500italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51S7ABc9.ttf",
        "700italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBhc9.ttf",
        "900italic":
            "http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TLBBc9.ttf"
    };

    public robotoStyles: any[] = [
        "100",
        "100italic",
        "300",
        "300italic",
        "regular",
        "italic",
        "500",
        "500italic",
        "700",
        "700italic",
        "900",
        "900italic"
    ];

    public camposFrente: any[] = [
        {
            Nome: "Campo 1",
            Texto: "",
            Fonte: {
                size: "32px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 2",
            Texto: "",
            Fonte: {
                size: "28px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 3",
            Texto: "",
            Fonte: {
                size: "20px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 4",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 5",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 6",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 7",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 8",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 9",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 10",
            Texto: "",
            Fonte: {
                size: "12px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        }
    ];

    public modelosFrente: any[] = [
        {
            ID: 0,
            Nome: "Nenhum"
        },
        {
            ID: 4,
            Nome: "Apenas Texto",
            Campos: this.camposFrente
        },
        {
            ID: 1,
            Nome: "Modelo 1",
            Imagem: "../../../assets/images/modelo1.png",
            Campos: this.camposFrente,
            QRCode: {
                Frente: "black",
                Fundo: "transparent",
                PosicaoY: 348,
                PosicaoX: 38
            }
        },
        {
            ID: 2,
            Nome: "Modelo 2",
            Imagem: "../../../assets/images/modelo2.png",
            Campos: this.camposFrente,
            QRCode: {
                Frente: "white",
                Fundo: "black",
                PosicaoY: 594,
                PosicaoX: 20
            }
        },
        {
            ID: 3,
            Nome: "Modelo 3",
            Imagem: "../../../assets/images/modelo3.png",
            Campos: this.camposFrente,
            QRCode: {
                Frente: "black",
                Fundo: "white",
                PosicaoY: 604,
                PosicaoX: 10
            }
        }
    ];

    public camposVerso: any[] = [
        {
            Nome: "Campo 1",
            Texto: "",
            Fonte: {
                size: "20px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 2",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 3",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 4",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 5",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 6",
            Texto: "",
            Fonte: {
                size: "16px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        },
        {
            Nome: "Campo 7",
            Texto: "",
            Fonte: {
                size: "14px",
                style: "regular",
                family: "Roboto",
                files: this.robotoFiles,
                styles: this.robotoStyles
            }
        }
    ];

    public modelosVerso: any[] = [
        {
            ID: 0,
            Nome: "Nenhum"
        },
        {
            ID: 1,
            Nome: "Modelo 1",
            Campos: this.camposVerso
        }
    ];

    public modeloSelecionadoFrente: any = this.modelosFrente[0];
    public campoSelecionadoFrente: any;

    public modeloSelecionadoVerso: any = this.modelosVerso[0];
    public campoSelecionadoVerso: any;

    public imageChangedEventFrente: any;
    public imageChangedEventVerso: any;

    public selectedItemsParceiros = [];
    public selectedItemsEmissor = [];
    public dropdownSettingsEmissor = {};
    public dropdownSettingsParceiros = {};

    public qrCode: any = {
        Tamanho: 100
    };

    public salvando: boolean;

    constructor(
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute,
        private storage: StorageService,
        private fontService: FontPickerService,
        public dialog: MatDialog
    ) {}

    layout() {
        const layout: any = {
            camposFrente: this.camposFrente,
            modelosFrente: this.modelosFrente,
            camposVerso: this.camposVerso,
            modelosVerso: this.modelosVerso,
            indexModeloFrente: this.modelosFrente.indexOf(
                this.modeloSelecionadoFrente
            ),
            indexModeloVerso: this.modelosVerso.indexOf(
                this.modeloSelecionadoVerso
            ),
            croppedImageFrente: this.croppedImageFrente,
            opacityFrente: this.opacityFrente.value,
            croppedImageVerso: this.croppedImageVerso,
            opacityVerso: this.opacityVerso.value,
            qrCode: this.qrCode
        };

        return JSON.stringify(layout);
    }

    ngOnInit() {
        this.ui.block();

        this.subscription = this.route.params.subscribe((params: any) => {
            const id = params["id"];
            const modelo: number = +params["modelo"];
            // this.api
            //     .listarConfiguracoesPagamento()
            //     .subscribe((response: any) => {
            //         if (!response.error) {
            //             this.configuracoes = response.data.configuracoes;
            //         }
            //     });
            if (id != "0") {
                this.api.obterVoucher(id).subscribe((response: any) => {
                    if (!response.error) {
                        response.data.Voucher.Parceiro = {};
                        response.data.Voucher.Lojas = [];

                        const emissor = response.data.Voucher.Emissor;
                        const lojas: string[] =
                            response.data.Voucher.Participantes;

                        this.carregarParceiros(lojas, emissor);

                        response.data.Voucher.AlvoClientes =
                            response.data.Voucher.Publico === 1 ||
                            response.data.Voucher.Publico === 3;
                        response.data.Voucher.AlvoColaboradores =
                            response.data.Voucher.Publico === 2 ||
                            response.data.Voucher.Publico === 3;

                        this.model = response.data.Voucher;
                        this.acao = "Edição";

                        const layout: any = JSON.parse(
                            response.data.Voucher.Layout
                        );

                        this.camposFrente = layout.camposFrente;
                        this.modelosFrente = layout.modelosFrente;
                        this.camposVerso = layout.camposVerso;
                        this.modelosVerso = layout.modelosVerso;

                        this.qrCode = layout.qrCode;

                        setTimeout(() => {
                            this.ladoVoucher = 2;

                            this.cropperVerso.imageBase64 =
                                layout.croppedImageVerso;
                            this.opacityVerso.value = layout.opacityVerso;
                            this.croppedImageVerso = layout.croppedImageVerso;
                            this.cropperReadyVerso = true;

                            setTimeout(() => {
                                this.ladoVoucher = 1;

                                this.cropperFrente.imageBase64 =
                                    layout.croppedImageFrente;
                                this.opacityFrente.value = layout.opacityFrente;
                                this.croppedImageFrente =
                                    layout.croppedImageFrente;
                                this.cropperReadyFrente = true;

                                this.load(
                                    layout.indexModeloFrente,
                                    layout.indexModeloVerso
                                );
                            }, 1000);
                        }, 1000);
                    }
                });
            } else {
                this.carregarParceiros(null, null);
                if (modelo) {
                    this.api.obterVoucher(modelo).subscribe((response: any) => {
                        if (!response.error) {
                            this.acao = "Criação";

                            const layout: any = JSON.parse(
                                response.data.Voucher.Layout
                            );

                            this.camposFrente = layout.camposFrente;
                            this.modelosFrente = layout.modelosFrente;
                            this.camposVerso = layout.camposVerso;
                            this.modelosVerso = layout.modelosVerso;

                            this.qrCode = layout.qrCode;

                            setTimeout(() => {
                                this.ladoVoucher = 2;

                                this.cropperVerso.imageBase64 =
                                    layout.croppedImageVerso;
                                this.opacityVerso.value = layout.opacityVerso;
                                this.croppedImageVerso =
                                    layout.croppedImageVerso;
                                this.cropperReadyVerso = true;

                                setTimeout(() => {
                                    this.ladoVoucher = 1;

                                    this.cropperFrente.imageBase64 =
                                        layout.croppedImageFrente;
                                    this.opacityFrente.value =
                                        layout.opacityFrente;
                                    this.croppedImageFrente =
                                        layout.croppedImageFrente;
                                    this.cropperReadyFrente = true;

                                    this.load(
                                        layout.indexModeloFrente,
                                        layout.indexModeloVerso
                                    );
                                }, 1000);
                            }, 1000);
                        }
                    });
                } else {
                    this.acao = "Criação";

                    this.load(0, 0);
                }
            }
        });
    }
    carregarParceiros(lojas: string[], emissor: string) {
        this.api.listarParceirosPorPerfil(1).subscribe((response: any) => {
            if (!response.error) {
                // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fapp.component.html
                // exemplos de multiselect

                // this.parceiros = response.data.Parceiros;
                response.data.Parceiros.forEach(element => {
                    if (element.Nome) {
                        this.parceirosEmissores.push({
                            ID: element.ID,
                            Nome: element.Nome
                        });
                    }
                });

                // this.model.Parceiro = this.parceiros.find((parceiro) => parceiro.ID === emissor);

                if (emissor)
                    this.emissorMultiCtrl.setValue(
                        this.parceirosEmissores.find(parceiro => parceiro.ID === emissor)
                    );

                this.filteredEmissorMulti.next(this.parceirosEmissores.slice());
                this.emissorMultiFilerCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroyEmissor))
                    .subscribe(() => {
                        this.filterEmissorMulti();
                    });
            }
        });
        this.api.listarParceirosPorPerfil(3).subscribe((response: any) => {
            //3 => Receptor: DEVE SER ATRELADO AOS ESTABELECIMENTOS PARTICIPANTES QUE PODEM RECEBÊ-LO
            if (!response.error) {
                // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fapp.component.html
                // exemplos de multiselect
                // this.parceiros = response.data.Parceiros;

                response.data.Parceiros.forEach(element => {
                    if (element.Nome) {
                        this.parceirosReceptores.push({
                            ID: element.ID,
                            Nome: element.Nome
                        });
                    }
                });

                response.data.Parceiros.forEach(element => {
                    if (element.Nome) {
                        let rede = this.redes.find(a => a.Rede == element.Rede);
                        if (!rede) {
                            rede = { Parceiros: [], Rede: element.Rede };
                            this.redes.push(rede);
                        }
                        const p = { Nome: element.Nome, ID: element.ID };
                        rede.Parceiros.push(p);
                    }
                });

                if (lojas) {
                    for (const loja of lojas) {
                        this.selectedItemsParceiros.push(
                            this.parceirosReceptores.find(
                                parceiro => parceiro.ID === loja
                            )
                        );
                        //   this.model.Lojas.push(this.parceiros.find((parceiro) => parceiro.ID === loja));
                    }
                    this.parceirosMultiCtrl.setValue(
                        this.selectedItemsParceiros
                    );
                }
                this.filteredParceirosMulti.next(
                    this.copyRedeGroups(this.redes)
                );
                this.parceirosMultiFilerCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterParceiroMulti();
                    });
            }
        });
    }

    protected copyRedeGroups(redeGroups: Rede[]) {
        const redeGroupsCopy = [];
        redeGroups.forEach(redeGroup => {
            redeGroupsCopy.push({
                Rede: redeGroup.Rede,
                Parceiros: redeGroup.Parceiros.slice()
            });
        });
        return redeGroupsCopy;
    }

    load(indexModeloFrente: number, indexModeloVerso: number) {
        for (const modelo of this.modelosFrente) {
            if (modelo.Campos) {
                for (const campo of modelo.Campos) {
                    if (campo.Fonte) {
                        campo.Fonte = new Font(campo.Fonte);

                        this.fontService.loadFont(campo.Fonte);
                    }
                }
            }
        }

        this.modeloSelecionadoFrente = this.modelosFrente[indexModeloFrente];
        if (this.modeloSelecionadoFrente.Campos)
            this.campoSelecionadoFrente = this.modeloSelecionadoFrente.Campos[0];

        for (const modelo of this.modelosVerso) {
            if (modelo.Campos) {
                for (const campo of modelo.Campos) {
                    if (campo.Fonte) {
                        campo.Fonte = new Font(campo.Fonte);
                        this.fontService.loadFont(campo.Fonte);
                    }
                }
            }
        }

        this.modeloSelecionadoVerso = this.modelosVerso[indexModeloVerso];
        if (this.modeloSelecionadoVerso.Campos)
            this.campoSelecionadoVerso = this.modeloSelecionadoVerso.Campos[0];

        this.isReady = true;

        this.ui.unblock();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this._onDestroy.next();
        this._onDestroy.complete();
        this._onDestroyEmissor.next();
        this._onDestroyEmissor.complete();
    }

    cancelar() {
        this.router.navigate(["/vouchers"]);
    }

    getImages() {
        return Observable.create((observer: Observer<boolean>) => {
            const ladoVoucher: number = this.ladoVoucher;

            this.ladoVoucher = 1;
            this.salvando = true;

            setTimeout(() => {
                html2canvas(
                    document.querySelector("#imagem-voucher-frente")
                ).then(canvas => {
                    this.model.ImagemFrente = canvas.toDataURL();
                });

                this.ladoVoucher = 2;

                setTimeout(() => {
                    html2canvas(
                        document.querySelector("#imagem-voucher-verso")
                    ).then(canvas => {
                        this.model.ImagemVerso = canvas.toDataURL();

                        setTimeout(() => {
                            this.salvando = false;
                            this.ladoVoucher = ladoVoucher;

                            observer.next(true);
                        });
                    });
                });
            });
        });
    }

    salvar() {
        if (this.validar()) {
            this.getImages().subscribe(() => {
                this.ui.block(
                    !this.model.ID
                        ? "Gerando vouchers..."
                        : "Atualizando voucher..."
                );

                this.parameter = this.loadParameterData();

                const data: any = JSON.parse(JSON.stringify(this.parameter));

                this.api.salvarVoucher(data).subscribe((response: any) => {
                    this.ui.unblock();

                    if (response.error) {
                        this.ui.error(response.error);
                    } else {
                        this.ui.success("Vouchers criados com sucesso!");

                        this.router.navigate(["/vouchers"]);
                    }
                });
            });
        }
    }

    fileChangeEventFrente(event: any): void {
        this.imageChangedEventFrente = event;
    }

    imageCroppedFrente(image: string) {
        this.croppedImageFrente = image;
        this.cropperReadyFrente = true;
    }

    mudouModeloFrente() {
        if (this.modeloSelecionadoFrente.QRCode) {
            this.salvando = true;

            setTimeout(() => {
                this.qrCode = this.modeloSelecionadoFrente.QRCode;

                setTimeout(() => {
                    this.salvando = false;
                });
            });
        }

        if (
            this.modeloSelecionadoFrente &&
            this.modeloSelecionadoFrente.Campos
        ) {
            this.campoSelecionadoFrente = this.modeloSelecionadoFrente.Campos[0];
        } else {
            this.campoSelecionadoFrente = undefined;
        }
    }

    fileChangeEventVerso(event: any): void {
        this.imageChangedEventVerso = event;
    }

    imageCroppedVerso(image: string) {
        this.croppedImageVerso = image;
        this.cropperReadyVerso = true;
    }

    mudouModeloVerso() {
        if (this.modeloSelecionadoVerso && this.modeloSelecionadoVerso.Campos) {
            this.campoSelecionadoVerso = this.modeloSelecionadoVerso.Campos[0];
        } else {
            this.campoSelecionadoVerso = undefined;
        }
    }

    // selecionarParceiro() {

    //     const dialogRef = this.dialog.open(SelecaoParceirosComponent, {
    //         width: '80vw',
    //         height: '85vh',
    //         data: {
    //             selection: 'single'
    //         }
    //     });

    //     dialogRef.afterClosed().subscribe((result: any) => {

    //         if (result) {

    //             this.model.Parceiro = result;
    //         }
    //     });
    // }

    // selecionarLojas() {

    //     const dialogRef = this.dialog.open(SelecaoParceirosComponent, {
    //         width: '80vw',
    //         height: '85vh',
    //         data: {
    //             selection: 'multiple'
    //         }
    //     });

    //     dialogRef.afterClosed().subscribe((result: any) => {

    //         if (result) {

    //             this.model.Lojas = result;
    //         }
    //     });
    // }

    validar() {
        let podeSalvar: boolean = true;
        let reason: string = '';

        if (!this.model.Descricao) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,"É necessário informar uma descrição para o mesmo");
        }
        if (!this.emissorMultiCtrl.value || !this.emissorMultiCtrl.value.ID) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason, "E necessário selecionar o parceiro emissor do mesmo");
        }
        if (!this.model.ID && !this.model.Quantidade) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,"É necessário informar a quantidade de vouchers a ser criada");
        }
        if (!this.model.AlvoClientes && !this.model.AlvoColaboradores) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason, "É necessário selecionar o público alvo");
        }
        if (this.model.LimitePorCPF < 1) {
            podeSalvar = false;
            reason = this.ui.buildMessage(reason,"Limite por CPF deve ser maior ou igual a 1");
        }

        if (!podeSalvar) {
            this.ui.error(reason, ' Não foi possível salvar o voucher! ', {tapToDismiss: false});
        }

        return podeSalvar;
    }

    onKeypress(event, decimals) {
        if (event.key === "." || event.key === ",") {
            if (!decimals || decimals === 0) {
                event.preventDefault();
                return;
            }

            if (
                event.target.value
                    .substring(
                        event.target.selectionStart,
                        event.target.selectionEnd
                    )
                    .indexOf(",") >= 0 ||
                event.target.value.indexOf(",") < 0
            ) {
                let value =
                    event.target.value.substr(0, event.target.selectionStart) +
                    "," +
                    event.target.value.substr(event.target.selectionEnd);

                if (value.startsWith(",")) {
                    value = "0" + value;
                }

                event.target.value = value;
            }

            event.preventDefault();
        } else {
            const numeric = /^\d+$/.test(event.key);

            if (!numeric) {
                event.preventDefault();
            }
        }
    }

    formatNumber(event, decimals: number) {
        let currentValue = event.target.value;

        if (currentValue === "0,") {
            currentValue = "0";
        }

        if (currentValue.indexOf(",") >= 0) {
            currentValue = currentValue
                .split(".")
                .join("")
                .replace(",", ".");
        }

        let newValue = Number(currentValue).toLocaleString("pt-BR", {
            useGrouping: true,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });

        if (newValue === "NaN") {
            currentValue = 0;
            newValue = Number(currentValue).toLocaleString("pt-BR", {
                useGrouping: true,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            });
        }

        currentValue = Number(Number(currentValue).toFixed(decimals));

        setTimeout(() => {
            event.target.value = newValue;
        });
    }

    onPaste(event) {
        let pastedText;

        if (window.clipboardData && window.clipboardData.getData) {
            pastedText = window.clipboardData.getData("Text");
        } else if (event.clipboardData && event.clipboardData.getData) {
            pastedText = event.clipboardData.getData("text/plain");
        }

        if (isNaN(Number(pastedText))) {
            return false;
        }
    }

    loadParameterData() {
        let lojas = this.parceirosMultiCtrl.value.map(loja => loja ? loja.ID: "");
        lojas = lojas.filter((id) => id != '');
        let param:any = {
            ID: this.model.ID,
            Descricao: this.model.Descricao,
            Parceiro: this.emissorMultiCtrl.value.ID || null,
            // ConfiguracaoPagamentoID: this.model.ConfiguracaoPagamentoID,
            Quantidade: this.model.Quantidade,
            Clientes: this.model.AlvoClientes,
            ValorVenda: this.model.ValorVenda,
            Colaboradores: this.model.AlvoColaboradores,
            Vendedores: this.model.RetornoVendedores,
            Caixas: this.model.RetornoCaixas,
            Gerentes: this.model.RetornoGerentes,
            ImagemFrente: this.model.ImagemFrente,
            ImagemVerso: this.model.ImagemVerso,
            Lojas: lojas,//this.parceirosMultiCtrl.value.map(loja => loja ? loja.ID: ""),
            LimitePorCPF: this.model.LimitePorCPF,
            QRCode:
                (this.qrCode.PosicaoY
                    ? this.qrCode.PosicaoY + "px"
                    : "calc(50% - 50px)") +
                "|" +
                (this.qrCode.PosicaoX
                    ? this.qrCode.PosicaoX + "px"
                    : "calc(50% - 50px)") +
                "|" +
                (this.qrCode.Frente ? this.qrCode.Frente : "black") +
                "|" +
                (this.qrCode.Fundo ? this.qrCode.Fundo : "white"),
            Layout: this.layout(),
            CodigoExterno: this.model.CodigoExterno
        };
        return param;
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    protected setInitialValue() {
        this.filteredParceirosMulti
            .pipe(
                take(1),
                takeUntil(this._onDestroy)
            )
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                this.multiSelect.compareWith = (a: Parceiro, b: Parceiro) =>
                    a && b && a.ID === b.ID;
            });
        this.filteredEmissorMulti
            .pipe(
                take(1),
                takeUntil(this._onDestroyEmissor)
            )
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                this.singleSelect.compareWith = (a: Parceiro, b: Parceiro) =>
                    a && b && a.ID === b.ID;
            });
    }
    protected filterParceiroMulti() {
        if (!this.parceirosReceptores) {
            return;
        }
        // get the search keyword
        let search = this.parceirosMultiFilerCtrl.value;
        const redeGroupsCopy = this.copyRedeGroups(this.redes);
        if (!search) {
            this.filteredParceirosMulti.next(redeGroupsCopy);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredParceirosMulti.next(
            redeGroupsCopy.filter(redeGroup => {
                const showRedeGroup =
                    redeGroup.Rede.toLowerCase().indexOf(search) > -1;
                if (!showRedeGroup) {
                    redeGroup.Parceiros = redeGroup.Parceiros.filter(
                        a => a.Nome.toLowerCase().indexOf(search) > -1
                    );
                }
                return redeGroup.Parceiros.length > 0;
            })
        );
    }
    protected filterEmissorMulti() {
        if (!this.parceirosEmissores) {
            return;
        }
        // get the search keyword
        let search = this.emissorMultiFilerCtrl.value;
        if (!search) {
            this.filteredEmissorMulti.next(this.parceirosEmissores.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredEmissorMulti.next(
            this.parceirosEmissores.filter(
                a => a.Nome.toLowerCase().indexOf(search) > -1
            )
        );
    }
}

export interface Parceiro {
    ID: string;
    Nome: string;
}
export interface Rede {
    Rede: string;
    Parceiros: Parceiro[];
}
