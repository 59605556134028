import { AppConfigService } from './app.config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, Observer, Subject, ReplaySubject, of, throwError } from 'rxjs';
import { concatMap, delay, map, mergeMap, retryWhen, timeout, take } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { UIService } from './ui.service';

import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { UUID } from 'angular2-uuid';

@Injectable()
export class APIService {
    private log: boolean = false;
    public appName: string;
    public menu = [];
    public listPermissoes = [];
    public permissoes = {
        parceiroDistribuidor: false,
        parceiroEmissor: false,
        parceiroReceptor: false,
        parceiroInfluencer: false,
        relatorioVoucher: false,
        relatorioPermio: false,
        relatorioDistribuicao: false,
        relatorioRecebimento: false,
        configuracaoEnvio: false,
        campanhaParceiro: false,
        campanhaAlterar: false,
        campanhaCadastrar: false,
        gestorParceria: false,
    };
    public menuUpdate = new Subject<any>();

    private _token: string;
    private _tokenRenew: string;
    private _keepConnected: boolean;

    private _usuario: any;

    /* Define o tempo (ms) mínimo entre uma validação e outra do Token.
    Quando um Token é validado ele deve ter no mínimo este tempo sobrando ou será renovado.
   */
    private readonly tokenValidationWindowTime: number = 1000 * 10;

    /* Caso várias abas sejam abertas simultâneamente o método "Login" será executado para cada aba com o mesmo Token,
        assim apenas uma das telas conseguirá realizar o Login propriamente. Para evitar que as demais telas matem o Token
        esse primeiro login nunca vai invalidar o Token.
    */
    private primeiraRenovacao: boolean = true;

    // ReplaySubject garente que as novas requisições receberão o último resultado da janela de tempo.
    private tokenSubject: ReplaySubject<any> = new ReplaySubject<any>(1, this.tokenValidationWindowTime);
    private isUpdatingToken: boolean = false;

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private router: Router,
        private ui: UIService,
        private location: Location,
        private appConfig: AppConfigService
    ) {
        this._token = this.storage.get("token");
        this._tokenRenew = this.storage.get("tokenRenew");
    }

    public uuid() {
        if (!this.storage.has('UUID')) {
            let uuid = UUID.UUID();
            this.storage.set('UUID', uuid);
        }
        this.appName = this.storage.get('UUID');
        return this.appName;
    }

    public get appCode() {
        return this.appConfig.settings.applicationCode;
    }

    public get baseUrl() {
        return this.appConfig.settings.baseUrl;
    }

    public get baseUrlReports() {
        return this.appConfig.settings.baseUrlReports;
    }

    public get token() {
        return this._token;
    }

    public set token(token: string) {
        this._token = token;
        this.storage.set('token', this._token);
    }

    public get tokenRenew() {
        return this._tokenRenew;
    }

    public set tokenRenew(tokenRenew: string) {
        this._tokenRenew = tokenRenew;
        this.storage.set("tokenRenew", this._tokenRenew);
    }

    public get usuario() {
        return this.storage.get('usuario');
    }

    public set usuario(usuario: any) {
        this._usuario = usuario;
        this.storage.set('usuario', this._usuario);
    }

    public get keepConnected() {
        return this.storage.get('keepConnected');
    }

    public set keepConnected(keedConnected: boolean) {
        this._keepConnected = keedConnected;
        this.storage.set('keepConnected', this._keepConnected);
    }

    private execute(
        service: string,
        action: string,
        parameter: any
    ): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            let base = this.baseUrl;
            if (action == 'RecuperarExecucaoCobrancas' || action == 'RecuperarListasExecucaoCobranca')
                base = this.baseUrlReports;
            const url =
                base +
                (service === 'Smart' ? '/Smart/' : '/Generated/') +
                service +
                'SVC.svc/json/' +
                action;


            const metodosSemToken = ["ConfirmarEmail", "TrocarSenha", "EsqueceuSenha", "ObterEmailRedefinicaoSenha", "RedefinirSenha", "LoginExterno"];

            if (metodosSemToken.some(metodo => metodo.toLocaleLowerCase() === action.toLocaleLowerCase())) {
                parameter.Token = "";
            }
            else {
                parameter.Token = this.token;
            }

            if (parameter.TokenRenew) {
                this._tokenRenew = this.storage.get("tokenRenew");
                parameter.TokenRenew = this.tokenRenew;
            }

            // Caso Token seja == ""
            if (!parameter.Token) delete parameter.Token;

            // if (this.log) console.log('=> ' + url, parameter);

            this.http.post(url, parameter).pipe(timeout(10 * 60 * 1000)).subscribe(
                (response: any) => {

                    // if (this.log) console.log('<= ' + url, response);

                    const data = response;

                    if (data.Errors.length === 0) {
                        delete data.Errors;

                        observer.next(data);
                    } else {
                        const errors: string[] = [];

                        // Caso o erro seja referente ao Token expirado é necessário tentar renovar o Token e tentar novamente antes de exibir o erro.
                        let shouldNext = true;

                        for (const error of data.Errors) {
                            if (error.Exception === "System.Security.SecurityException") {
                                this.token = undefined;
                                this.router.navigate(["/login"]);
                            }
                            else if (error.Exception === 'Trybu.Utils.ExpiredTokenException') {
                                shouldNext = false;

                                // Tenta renovar o token
                                this.tokenSubject.pipe(take(1)).subscribe({
                                    next: (result: any) => {
                                        if (result.error) {
                                            observer.next({ Errors: [result.error] });

                                            if (result.status == null || result.status != 0) {
                                                this.token = null;
                                            }

                                            this.router.navigate(["/login"]);

                                        }
                                        // Token Renovado !
                                        else {
                                            this.execute(service, action, parameter).subscribe({
                                                next: (response: any) => {
                                                    observer.next(response);
                                                }
                                            });
                                        }
                                    }
                                });
                                this.renewToken();
                            }

                            errors.push(error.Description);
                        }

                        if (shouldNext)
                            observer.next({ Errors: errors });
                    }
                },

                () => {
                    observer.next({
                        Errors: ["Serviço indisponível ou requisição inválida."],
                    });
                }
            );
        });
    }

    private renewToken() {
        // Garante que será executado apenas uma vez para todas as chamadas.
        if (this.isUpdatingToken)
            return;

        this.isUpdatingToken = true;

        // Atualiza os tokens com os valores em sessão
        this._token = this.storage.get("token");
        this._tokenRenew = this.storage.get("tokenRenew");

        let url = this.baseUrl;

        of(url + "/Generated/AuthSVC.svc/json/RenewToken")
            .pipe(
                concatMap((url) => {
                    this._tokenRenew = this.storage.get("tokenRenew");
                    return this.http.post(url, { TokenRenew: this.tokenRenew })
                }),
                timeout(15 * 1000),
                map((result: any) => {
                    if (result.Errors && result.Errors.length > 0)
                        throw result;
                    return result;
                }),
                retryWhen(error => {
                    return error
                        .pipe(
                            mergeMap((error: any, i) => {
                                if (i + 1 >= 3) {
                                    if (error.name && error.name == "TimeoutError")
                                        error.status = 0;
                                    return throwError(error);
                                }
                                return of(error.status).pipe(delay(3000))
                            })
                        )
                }
                )
            )
            .subscribe(
                (resultado: any) => {
                    if (resultado.Errors.length > 0) {
                        this.tokenSubject.next({ error: resultado.Errors[0].Description });
                    } else {
                        this.token = resultado.Token;
                        this.tokenRenew = resultado.TokenRenew;
                        this.tokenSubject.next({ error: "" });
                    }

                    setTimeout(() => {
                        this.isUpdatingToken = false;
                    }, this.tokenValidationWindowTime);
                },
                (e) => {
                    if (e.Errors && e.Errors.length > 0) {
                        this.tokenSubject.next({ error: e.Errors[0].Description, status: e.status });
                    } else {
                        this.tokenSubject.next({ error: "Serviço indisponível ou requisição inválida.", status: e.status });
                    }
                    setTimeout(() => {
                        this.isUpdatingToken = false;
                    }, this.tokenValidationWindowTime);
                }
            );
    }

    public initialize() {
        return Observable.create((observer: Observer<any>) => {
            this.ui.block('Inicializando...');

            this._keepConnected = this.storage.get('keepConnected');
            this._token = this.storage.get('token');
            if (!this.keepConnected) this.token = null;

            const rotasSemLogin = ["confirmar-email", "recuperar-senha", "redefinir-senha", "registrar"];
            const rota = this.location.path().split("/")[1];

            if (this.keepConnected && this._token && (!rota || !rotasSemLogin.some(rt => rota.includes(rt)))) {
                this._tokenRenew = this.storage.get("tokenRenew");
                this._usuario = this.storage.get('usuario');
                this.login({ Token: this._token, Code: this.appCode }, true).subscribe(
                    (response: any) => {
                        this.ui.unblock();
                        if (response.error || (response.data && response.data.SenhaExpirada)) {
                            this.router.navigate(["/login"]);
                        }
                        else {
                            if (this.router.url == "/login")
                                this.router.navigate(["/home"]);
                        }
                        observer.next(response);
                    }
                );
            } else {
                this.ui.unblock();
                observer.next(true);
            }
        });
    }
    public getMenu(): Observable<any> {
        return this.menuUpdate.asObservable();
    }
    public login(parameter: any, keepConnected: boolean) {
        parameter.Code = this.appCode;
        parameter.DeviceID = this.appName;
        parameter.TokenRenew = this.tokenRenew;
        return this.execute('Auth', 'Login', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {

                    if (response.Token && response.TokenRenew) {
                        this.token = response.Token;
                        this.tokenRenew = response.TokenRenew;
                    }

                    this.keepConnected = keepConnected;

                    if (response.Menu && response.Menu.length > 0) {
                        this.permissoes = {
                            parceiroDistribuidor: false,
                            parceiroEmissor: false,
                            parceiroReceptor: false,
                            parceiroInfluencer: false,
                            relatorioVoucher: false,
                            relatorioPermio: false,
                            relatorioDistribuicao: false,
                            relatorioRecebimento: false,
                            configuracaoEnvio: false,
                            campanhaParceiro: false,
                            campanhaAlterar: false,
                            campanhaCadastrar: false,
                            gestorParceria: false
                        };
                        this.menu = response.Menu[0].Items;
                        this.listPermissoes = response.Permissoes;

                        if (this.listPermissoes.find(a => a.FeatureCode == '06.1'))
                            this.permissoes.campanhaCadastrar = true;
                        if (this.listPermissoes.find(a => a.FeatureCode == '06.2'))
                            this.permissoes.campanhaAlterar = true;
                        if (this.listPermissoes.find(a => a.FeatureCode == '06.3'))
                            this.permissoes.campanhaParceiro = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '02.1'
                            )
                        )
                            this.permissoes.parceiroEmissor = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '02.2'
                            )
                        )
                            this.permissoes.parceiroDistribuidor = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '02.3'
                            )
                        )
                            this.permissoes.parceiroReceptor = true;

                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '02.4'
                            )
                        )
                            this.permissoes.parceiroInfluencer = true;


                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '08.1'
                            )
                        )
                            this.permissoes.relatorioVoucher = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '08.2'
                            )
                        )
                            this.permissoes.relatorioPermio = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '08.3'
                            )
                        )
                            this.permissoes.relatorioDistribuicao = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '08.4'
                            )
                        )
                            this.permissoes.relatorioRecebimento = true;

                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '02.7'
                            )
                        )
                            this.permissoes.gestorParceria = true;
                        if (
                            this.listPermissoes.find(
                                a => a.FeatureCode == '10.1'
                            )
                        )
                            this.permissoes.configuracaoEnvio = true;

                        response.Menu[0].Items.forEach(element => {
                            if (element.Items)
                                element.Items = element.Items.filter(a => a.Route);
                        });

                        this.menuUpdate.next({ menu: response.Menu[0].Items });
                    }
                    response.Usuario.Menu = this.menu;
                    this.usuario = response.Usuario;
                    return { data: { Usuario: response.Usuario } };
                } else {
                    if (parameter.Token && !this.primeiraRenovacao) {
                        this.token = null;
                    }
                    this.primeiraRenovacao = false;

                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public confirmarEmail(parameter: any) {
        return this.execute('Auth', 'ConfirmarEmail', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { ID: response.ID, Email: response.Email } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public criarConta(parameter: any) {
        return this.execute('Auth', 'CadastrarUsuario', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { ID: response.ID } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public esqueceuSenha(parameter: any) {
        return this.execute('Auth', 'EsqueceuSenha', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { ID: response.ID } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterEmailRedefinicaoSenha(parameter: any) {
        return this.execute(
            'Auth',
            'ObterEmailRedefinicaoSenha',
            parameter
        ).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Email: response.Email } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public redefinirSenha(parameter: any) {
        return this.execute('Auth', 'RedefinirSenha', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Email: response.Email } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public trocarSenha(parameter: any) {
        return this.execute('Auth', 'TrocarSenha', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Email: response.Email } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarComunicado(data: any) {
        const parameter: any = {
            Comunicado: {
                ID: data.ID,
                Descricao: data.Descricao,
                Titulo: data.Titulo,
                BoasVindas: data.BoasVindas,
                ImagemFrente: data.ImagemFrente,
                ImagemVerso: data.ImagemVerso,
                Info: data.Info,
                Publico:
                    data.Clientes && data.Colaboradores
                        ? 3
                        : data.Clientes
                            ? 1
                            : 2,
                Layout: data.Layout
            }
        };

        return this.execute('Main', 'SalvarComunicado', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterComunicados(parameter: any) {
        return this.execute(
            'Main',
            'RecuperarListaComunicados',
            parameter
        ).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Comunicados: response.Comunicados
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterComunicado(id) {
        return this.execute('Main', 'ObterComunicado', { ID: id }).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Comunicado: response.Comunicado } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarParceiro(parameter: any) {
        return this.execute('Main', 'SalvarParceiro', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterParceiros(parameter: any) {
        return this.execute('Main', 'RecuperarListaParceiros', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Parceiros: response.Parceiros
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarListasDistribuidor(parameter: any){

        return this.execute('Main', 'ListarListasDistribuidor', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { ListasDistribuidor: response.ListasDistribuidor } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRedesParceiros(parameter: any) {
        return this.execute('Main', 'RecuperarListaRedesParceiros', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            RedesParceiros: response.RedesParceiros
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterGruposParceiros(redeID: string) {
        this.ui.block();
        const parameter = {
            RedeParceiroID: redeID
        };

        return this.execute('Main', 'RecuperarListaGruposParceiros', parameter).pipe(
            map((response: any) => {
                this.ui.unblock();
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            GruposParceiros: response.GruposParceiros
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarRedeParceiro(parameter: any) {

        return this.execute('Main', 'SalvarRedePareceiro', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                     return response;
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarGrupoParceiro(parameter: any) {
        return this.execute('Main', 'SalvarGrupoParceiro', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return response;
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarParceirosPorPerfil(perfil: number) {
        const parameter = {
            Perfil: perfil
        };

        return this.execute('Main', 'ListarParceirosPorPerfil', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Parceiros: response.Parceiros } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarEntidadePorTipo(tipo: string, isInfluencer: boolean, unityIdEmissor:number) {
        const parameter = {
            Tipo: tipo,
            IsInfluencer: isInfluencer,
            UnityIdEmissor:unityIdEmissor
        };

        return this.execute('Main', 'ListarEntidadePorTipo', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Parceiros: response.Parceiros } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterParceiro(parameter: any) {
        return this.execute('Main', 'ObterParceiro', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterSugestoes() {
        return this.execute('Main', 'ObterSugestoes', {}).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Redes: response.Redes,
                            Grupos: response.Grupos,
                            Segmentos: response.Segmentos,
                            Complexos: response.Complexos
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterColaborador(parameter: any) {
        return this.execute('Main', 'ObterColaborador', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Colaborador: response.Colaborador } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarVoucher(data: any) {
        const parameter: any = {
            Voucher: {
                ID: data.ID,
                Descricao: data.Descricao,
//                Validade: data.Validade,
                ImagemFrente: data.ImagemFrente,
                ImagemVerso: data.ImagemVerso,
                QRCode: data.QRCode,
                Emissor: data.Parceiro,
                Participantes: data.Lojas,
                Quantidade: data.Quantidade,
                Publico:
                    data.Clientes && data.Colaboradores
                        ? 3
                        : data.Clientes
                            ? 1
                            : 2,
                Layout: data.Layout,
//                Pontos: data.Pontos,
                LimitePorCPF: data.LimitePorCPF,
                CodigoExterno: data.CodigoExterno,
                ConfiguracaoPagamentoID: data.ConfiguracaoPagamentoID,
                Limite: data.Limite,
                Utilizados: data.Utilizados,
                EstoqueIlimitado: data.EstoqueIlimitado
            }
        };

        return this.execute('Main', 'SalvarVoucher', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterVouchers(parameter: any) {
        return this.execute('Main', 'RecuperarListaVouchers', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Vouchers: response.Vouchers
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterVoucher(id) {
        return this.execute('Main', 'ObterVoucher', { ID: id }).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Voucher: response.Voucher } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public gerarMaisVouchers(parameter: any) {
        return this.execute('Main', 'GerarMaisVouchers', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Gerado: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterGincanas(parameter: any) {
        return this.execute('Main', 'RecuperarListaGincanas', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Gincanas: response.Gincanas
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterGincana(parameter: any) {
        return this.execute('Main', 'ObterGincana', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Gincana: response.Gincana } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarGincana(parameter: any) {
        return this.execute('Main', 'SalvarGincana', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salva: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterCampanhas(parameter: any) {

        if(parameter.Inicio){
            parameter.Inicio = (moment(parameter.Inicio).startOf('day') as any).toNET()
        }
        if(parameter.Termino){
            parameter.Termino = (moment(parameter.Termino).startOf('day') as any).toNET()
        }

        return this.execute('Main', 'RecuperarListaCampanhas', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Campanhas: response.Campanhas
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterCampanha(parameter: any) {
        return this.execute('Main', 'ObterCampanha', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Campanha: response.Campanha } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarCampanha(parameter: any) {
        // console.log(JSON.stringify(parameter, null, 4));
        if (parameter.Campanha.ID == 0)
            parameter.Campanha.ID = '00000000-0000-0000-0000-000000000000';
        if (parameter.Campanha.Comunicado == 0)
            parameter.Campanha.Comunicado =
                '00000000-0000-0000-0000-000000000000';
        return this.execute('Main', 'SalvarCampanha', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: {
                        Salva: true,
                        Alertas: response.Alertas
                    } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    //obsoleto: utilizar listarColaboradorComEvento
    public filtrarColaboradores(parameter: any) {
        return this.execute('Main', 'FiltrarColaboradores', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Colaboradores: response.Colaboradores
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarColaboradorComEvento(parameter: any) {
        return this.execute('Main', 'ListarColaboradorComEvento', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Colaboradores: response.Colaboradores
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public enviarVoucher(parameter: any) {
        return this.execute('Main', 'EnviarVoucher', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Enviado: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public enviarComunicado(parameter: any) {
        return this.execute('Main', 'EnviarComunicado', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Enviado: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterVoucherCliente(codigo: string) {
        return this.execute('Cross', 'ObterVoucher', { Codigo: codigo }).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Voucher: response.Voucher } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }
    public obterLinkRedefinicaoSenhaTrybu(parameter) {
        return this.execute('Cross', 'ObterLinkRedefinicaoSenhaTrybu', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterParametros() {
        return this.execute('Auth', 'ObterParametros', {}).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Parametros: response.Parametros } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarParametros(parametros: any) {
        return this.execute('Auth', 'SalvarParametros', {
            Parametros: parametros
        }).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public enviarPontos(parameter: any) {
        return this.execute('Main', 'EnviarPontos', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Enviado: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRelatorioDistribuidos(de?: Date, ate?: Date) {
        const parameter: any = {
            DataInicial: de
                ? (moment(de).startOf('day') as any).toNET()
                : undefined,
            DataFinal: ate
                ? (moment(ate).endOf('day') as any).toNET()
                : undefined
        };

        return this.execute(
            'Main',
            'ObterRelatorioDistribuidos',
            parameter
        ).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    const relatorio: any[] = [];

                    for (const item of response.Itens) {
                        item.Data = moment(item.Data).format(
                            'DD/MM/YYYY HH:mm'
                        );
                        item.DataTroca = item.DataTroca
                            ? moment(item.DataTroca).format('DD/MM/YYYY HH:mm')
                            : null;
                        item.Valor = item.Valor.toFixed(2);
                        relatorio.push(item);
                    }

                    return {
                        data: { itens: relatorio, campos: response.Campos }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public executarRelatorio(metodo: string, de?: Date, ate?: Date) {
        const parameter: any = {
            DataInicial: de
                ? (moment(de).startOf('day') as any).toNET()
                : undefined,
            DataFinal: ate
                ? (moment(ate).endOf('day') as any).toNET()
                : undefined
        };

        return this.execute('Main', metodo, parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    const relatorio: any[] = [];

                    for (const item of response.Itens) {
                        response.Campos.forEach(element => {
                            if (element.Formato == 'DataHora') {
                                if (item[element.Propriedade])
                                    item[element.Propriedade] = moment(
                                        item[element.Propriedade]
                                    ).format('DD/MM/YYYY HH:mm');
                            }
                        });

                        relatorio.push(item);
                    }

                    return {
                        data: { itens: relatorio, campos: response.Campos }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRelatorioRecebidos(de?: Date, ate?: Date) {
        const parameter: any = {
            DataInicial: de
                ? (moment(de).startOf('day') as any).toNET()
                : undefined,
            DataFinal: ate
                ? (moment(ate).endOf('day') as any).toNET()
                : undefined
        };

        return this.execute('Main', 'ObterRelatorioRecebidos', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    const relatorio: any[] = [];

                    for (const item of response.Itens) {
                        item.Data = moment(item.Data).format(
                            'DD/MM/YYYY HH:mm'
                        );
                        item.DataTroca = item.DataTroca
                            ? moment(item.DataTroca).format('DD/MM/YYYY HH:mm')
                            : null;

                        relatorio.push(item);
                    }

                    return {
                        data: { itens: relatorio, campos: response.Campos }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRelatorioVouchers(de?: Date, ate?: Date) {
        const parameter: any = {
            DataInicial: de
                ? (moment(de).startOf('day') as any).toNET()
                : undefined,
            DataFinal: ate
                ? (moment(ate).endOf('day') as any).toNET()
                : undefined
        };

        return this.execute('Main', 'ObterRelatorioVouchers', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    const relatorio: any[] = [];

                    for (const item of response.Relatorio) {
                        item.DataVenda = moment(item.DataVenda).format(
                            'DD/MM/YYYY HH:mm'
                        );
                        item.DataTroca = item.DataTroca
                            ? moment(item.DataTroca).format('DD/MM/YYYY HH:mm')
                            : null;

                        relatorio.push(item);
                    }

                    return { data: relatorio };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRelatorioPremios(de?: Date, ate?: Date) {
        const parameter: any = {
            DataInicial: de
                ? (moment(de).startOf('day') as any).toNET()
                : undefined,
            DataFinal: ate
                ? (moment(ate).endOf('day') as any).toNET()
                : undefined
        };

        return this.execute('Main', 'ObterRelatorioPremios', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    const relatorio: any[] = [];

                    for (const item of response.Relatorio) {
                        item.DataTroca = moment(item.DataTroca).format(
                            'DD/MM/YYYY HH:mm'
                        );
                        item.DataResgate = item.DataResgate
                            ? moment(item.DataResgate).format(
                                'DD/MM/YYYY HH:mm'
                            )
                            : null;

                        relatorio.push(item);
                    }

                    return { data: relatorio };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarConsultor(data: any) {
        const parameter: any = {
            Consultor: {
                ID: data.ID,
                Nome: data.Nome,
                CPF: data.CPF,
                Telefone: data.Telefone,
                Email: data.Email,
                Inativo: data.Inativo
            }
        };

        return this.execute('Main', 'SalvarConsultor', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterConsultores(parameter: any) {
        return this.execute(
            'Main',
            'RecuperarListaConsultores',
            parameter
        ).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Consultores: response.Consultores
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterConsultor(id: number) {
        return this.execute('Main', 'ObterConsultor', id).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Consultor: response.Consultor } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarConfiguracoesPagamento() {
        return this.execute('Payment', 'ListarConfiguracoesPagamento', {}).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { configuracoes: response.Configuracoes } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarRelatoriosUsuario() {
        return this.execute('Main', 'ListarRelatoriosUsuario', {}).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { relatorios: response.Relatorios } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    static toExportFileName(excelFileName: string): string {
        return `${excelFileName}_${new Date().getTime()}.xlsx`;
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
            Sheets: { Dados: worksheet },
            SheetNames: ['Dados']
        };

        XLSX.writeFile(workbook, APIService.toExportFileName(excelFileName));
    }

    public obterEstabelecimentos(parameter: any) {
        return this.execute('Main', 'ListarEstabelecimentos', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Estabelecimentos: response.Estabelecimentos
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterEstabelecimento(parameter: any) {
        return this.execute('Main', 'ObterEstabelecimento', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarEstabelecimento(parameter: any) {
        return this.execute('Main', 'AtualizarEstabelecimento', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarRedes(parameter: any) {
        return this.execute('Main', 'ListarRedes', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public ListaPlanosPontuacao(parameter: any) {
        return this.execute("Cross", "ListaPlanosPontuacao", parameter).pipe(
          map((response: any) => {
            if (!response.Errors) {
              return { data: response };
            } else {
              return { error: response.Errors[0] };
            }
          })
        );
      }

    public listarConfiguracaoCobranca(parameter: any) {
        return this.execute('Main', 'ListarConfiguracaoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterConfiguaracaoCobranca(parameter: any) {
        return this.execute('Main', 'ObterConfiguaracaoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterVendas(parameter: any) {
        return this.execute('Main', 'ListarVendas', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Itens: response.Itens,
                            Base64: response.Base64
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterVenda(parameter: any) {
        return this.execute('Main', 'ObterVenda', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public gerarTokenPowerBI(id: string) {
        const parameters = { 'DashboardID': id };
        return this.execute('Main', 'GerarTokenPowerBI', parameters).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }
    public gerarLinkPagamento(id: string) {
        const parameters = { 'VendaID': id };
        return this.execute('Main', 'GerarLinkVendaBackoffice', parameters).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterParcelas(parameter: any) {
        return this.execute('Main', 'ListarParcelas', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Parcelas: response.Parcelas,
                            Base64: response.Base64
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterDashboards(parameter: any) {
        return this.execute("Main", "ListarDashboards", parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total,
                            Dashboards: response.Dashboards
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public AtualizarDashboards(parameter: any) {
        return this.execute("Main", "AtualizarDashboards", parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return {
                        data: {
                            Total: response.Total
                        }
                    };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarRedePagamento(parameter: any) {
        return this.execute('Main', 'ListarRedePagamento', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRedePagamento(parameter: any) {
        return this.execute('Main', 'ObterRedePagamento', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarRedePagamento(parameter: any) {
        return this.execute('Main', 'SalvarRedePagamento', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: { Salvo: true } };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public formatNumberValue(value) {
        return value.replace(/[^0-9]/g, '');
    }

    public salvarAcaoConfiguracaoCobranca(parameter: any) {
        return this.execute('Main', 'SalvarAcaoConfiguracaoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarItemAcaoCobrancaComunicado(parameter: any) {
        return this.execute('Main', 'SalvarItemAcaoCobrancaComunicado', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public excluirItemAcaoCobrancaComunicado(parameter: any) {
        return this.execute('Main', 'ExcluirItemAcaoCobrancaComunicado', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public excluiComunicadoCobranca(parameter: any) {
        return this.execute('Main', 'ExcluiComunicadoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public salvarAcaoCobrancaCodigoRetorno(parameter: any) {
        return this.execute('Main', 'SalvarAcaoCobrancaCodigoRetorno', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public excluirAcaoConfiguracaoCobranca(parameter: any) {
        return this.execute('Main', 'ExcluirAcaoConfiguracaoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public criarNovaConfiguracao(parameter: any) {
        return this.execute('Main', 'CriarNovaConfiguracao', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public atualizarConfiguracaoCobranca(parameter: any) {
        return this.execute('Main', 'AtualizarConfiguracaoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public recuperarParamentrosMensagem(parameter: any) {
        return this.execute('Main', 'RecuperarParamentrosMensagem', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public recuperarListasExecucaoCobranca(parameter: any) {
        return this.execute('Main', 'RecuperarListasExecucaoCobranca', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public recuperarExecucaoCobrancas(parameter: any) {
        return this.execute('Main', 'RecuperarExecucaoCobrancas', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public recuperarExecucaoCobrancasComunicados(parameter: any) {
        return this.execute('Main', 'RecuperarExecucaoCobrancasComunicados', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarAutorizadoras(parameter: any) {
        return this.execute('Main', 'ListarAutorizadoras', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarMensagemEventos(parameter: any) {
        return this.execute('Main', 'ListarMensagemEventos', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterTicketsParcelas(parameter: any) {
        return this.execute('Main', 'ObterTicketsParcelas', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public atualizarGrupo(parameter: any) {
        return this.execute('Main', 'AtualizarGrupo', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterGrupos(parameter: any) {
        return this.execute('Main', 'ObterGrupos', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public obterRedes(parameter: any) {
        return this.execute('Main', 'ObterRedes', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public excluirGrupo(parameter: any) {
        return this.execute('Main', 'ExcluirGrupo', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public logout(parameter: any) {
        parameter.TokenRenew = this.tokenRenew;
        return this.execute("Auth", "Logout", parameter).pipe(
            map((response: any) => {
                this.token = null;
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }
    public obterCanalDistribuicao(parameter: any) {
        return this.execute('Main', 'ObterCanalDistribuicao', parameter).pipe(

            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );

    }

    public listaPlanosPontuacao(parameter: any) {
        return this.execute('Cross', 'ListaPlanosPontuacao', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public consultarCEP(cep: string = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {

          // Deixa apenas dígitos no CEP
          cep = cep.replace(/[^\d]/g, "");

          // Valida a quantidade de caracteres
          if (!cep || cep.length != 8) {
            observer.next({ error: "O CEP Deve conter 8 digitos." });
            return;
          }

          // Faz a consulta
          this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
            (response: any) => {

              if (response.erro) {
                observer.next({ error: "CEP inválido ou não encontrado." });
              }
              else {
                observer.next({ data: response });
              }
            },
            () => {
              observer.next({ error: "Não foi possível consultar o CEP." });
            }
          );
        });
    }

    public listaParceiros(parameter: any) {
        return this.execute('Main', 'ListaParceiros', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarMensagemParceiros(parameter: any) {
        return this.execute('Main', 'ListarMensagemParceiros', parameter).pipe(
            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );
    }

    public listarUsuarios(parameter: any) {
        return this.execute('BUSService', 'ListarUsuarios', parameter).pipe(

            map((response: any) => {
                if (!response.Errors) {
                    return { data: response };
                } else {
                    return { error: response.Errors[0] };
                }
            })
        );

    }
}
