import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

import * as moment from 'moment';

@Component({
    selector: 'cs-vouchers',
    templateUrl: './vouchers.component.html',
    styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {

    @Input() modal: string = undefined;
    @Input() publico: string = undefined;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    public model: any = {
        Clientes: true,
        Colaboradores: false
    };

    public total: number;
    public vouchers: any[] = [];

    public vouchersSelecionados: any[] = [];

    public sugestoes: any = {
        Redes: [],
        Grupos: [],
        Segmentos: [],
        Complexos: []
    };

    public quantidade: number = 10;

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        if (this.publico === 'Clientes') {

            this.model.Clientes = true;
            this.model.Colaboradores = false;
        }
        else if (this.publico === 'Colaboradores') {

            this.model.Clientes = false;
            this.model.Colaboradores = true;
        }

        this.api.obterSugestoes().subscribe(

            (response: any) => {

                if (!response.error) {

                    this.sugestoes = response.data;
                }
            }
        );

        setTimeout(() => {

            this.paginator.pageSize = 10;

            this.loadSession();
        });
    }

    limparFiltro() {

        this.model = {
            Clientes: true,
            Colaboradores: false
        };
    }

    filtrar(page?: number) {

        this.ui.block('Pesquisando vouchers...');

        const skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;

        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Validade = (moment(parameter.Validade) as any).toNET();
        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        this.api.obterVouchers(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    for (const voucher of response.data.Vouchers) {

                        const qrCode: string[] = voucher.QRCode.split('|');

                        voucher.QRCode = {
                            Top: qrCode[0],
                            Left: qrCode[1],
                            HalfTop: (Number(qrCode[0].replace('px', '')) / 2).toFixed(0) + 'px',
                            HalfLeft: (Number(qrCode[1].replace('px', '')) / 2).toFixed(0) + 'px',
                            Front: qrCode[2],
                            Back: qrCode[3]
                        };
                    }

                    this.total = response.data.Total;
                    this.vouchers = response.data.Vouchers;

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    newRecord(modelo?: number) {

        this.saveSession();

        if (!modelo) {

            this.router.navigate(['/vouchers', 0]);
        }
        else {

            this.router.navigate(['/vouchers', 0, modelo]);
        }
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/vouchers', element.ID]);
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session && (session.model.Clientes || session.model.Colaboradores)) {

            this.model = session.model;
            this.model.Clientes = true;
            this.model.Colaboradores = false;
            this.paginator.pageSize = session.page.size;

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    selecionaVoucher(voucher: any) {

        if (this.modal === 'single') {

            this.vouchersSelecionados = [voucher];
        }
        else {

            this.dialog.open(DialogVisualizarVouchersComponent, {
                width: '414px',
                height: '736px',
                data: { voucher: JSON.parse(JSON.stringify(voucher)) }
            });
        }
    }

    voucherSelecionado(voucher: any) {

        return this.vouchersSelecionados.some((_voucher) => _voucher.ID === voucher.ID);
    }

    icon(status: string) {

        if (status === 'Estoque') return 'work';
        else if (status === 'Disponivel') return 'style';
        else if (status === 'Entregue') return 'how_to_reg';
        else if (status === 'Utilizado') return 'verified_user';
        else if (status === 'Vencido') return 'not_interested';
    }

    descricao(status: string) {

        if (status === 'Estoque') return 'em estoque';
        else if (status === 'Disponivel') return 'disponíveis nas lojas';
        else if (status === 'Entregue') return 'entregues à clientes';
        else if (status === 'Utilizado') return 'resgatados (utilizados)';
        else if (status === 'Vencido') return 'vencidos';
    }

    pedirQuantidadeVouchers(voucher: any) {

        this.vouchersSelecionados = [voucher];

        const dialogRef = this.dialog.open(DialogMaisVouchersComponent, {
            width: '250px',
            data: { vouchers: 100 }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {

                this.gerarMaisVouchers(result);
            }
        });
    }

    gerarMaisVouchers(vouchers: number) {

        this.ui.block('Gerando vouchers...');

        const parameter: any = {
            Vouchers: this.vouchersSelecionados.map((voucher) => voucher.ID),
            Quantidade: vouchers
        };

        this.api.gerarMaisVouchers(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    for (const voucher of this.vouchersSelecionados) {

                        for (const estoque of voucher.Estoque) {

                            if (estoque.Status === 'Estoque') {

                                estoque.Quantidade = Number(estoque.Quantidade) + Number(vouchers);
                            }
                        }
                    }

                    this.vouchersSelecionados = [];
                    this.quantidade = 10;

                    this.ui.success('Vouchers criados com sucesso!');
                }
            }
        );
    }
}

@Component({
    selector: 'cs-mais-vouchers',
    template: `
        <div style="padding: 24px;">

            <h1 mat-dialog-title>Adicionar Vouchers</h1>

            <div mat-dialog-content>

                <p>Informe a quantidade de vouchers que deseja adicionar ao estoque:</p>

                <mat-form-field>
                    <input type="number" matInput [(ngModel)]="data.vouchers" cdkFocusInitial style="text-align: right;">
                </mat-form-field>

            </div>

            <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-button (click)="cancel()">Cancelar</button>
                <button mat-button mat-raised-button color="primary" [mat-dialog-close]="data.vouchers">Gerar</button>
            </div>

        </div>
    `,
})
export class DialogMaisVouchersComponent {

    constructor(public dialogRef: MatDialogRef<DialogMaisVouchersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    cancel(): void {

        this.dialogRef.close();
    }
}

@Component({
    selector: 'cs-visualizar-vouchers',
    template: `
        <div style="position: relative; cursor: alias;" (click)="flip()">

            <img [src]="data.voucher.Lado !== 2 ? data.voucher.ImagemFrente : data.voucher.ImagemVerso">

            <div *ngIf="data.voucher.Lado !== 2" style="position: absolute;" [style.top]="data.voucher.QRCode.Top" [style.left]="data.voucher.QRCode.Left">
                <qrcode [qrdata]="'000000'" [size]="100" [level]="'M'" [colordark]="data.voucher.QRCode.Front" [colorlight]="data.voucher.QRCode.Back"></qrcode>
                <h3 style="font-size: 24px; font-weight: bold; line-height: 1; margin: 5px 0 0; text-align: center;" [style.color]="data.voucher.QRCode.Front">#000000</h3>
            </div>

        </div>
`,
})
export class DialogVisualizarVouchersComponent {

    constructor(public dialogRef: MatDialogRef<DialogVisualizarVouchersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    flip(): void {

        if (this.data.voucher.Lado === 2) {

            this.data.voucher.Lado = 1;
        }
        else {

            this.data.voucher.Lado = 2;
        }
    }
}
