import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'startWith'
})
export class StartWithPipe implements PipeTransform {

    transform(options: string[], search: string): string[] {

        if (!options || !options.length || !search) {

            return null;
        }

        return options.filter((option) => option.toLowerCase().startsWith(search.toLowerCase()));
    }
}
