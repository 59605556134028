import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'cs-conf-cobrancas',
    templateUrl: './conf-cobrancas.component.html',
    styleUrls: ['./conf-cobrancas.component.scss']
})
export class ConfCobrancasComponent implements OnInit {

    @Input() modal: string = undefined;

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // *** todo: criar componente de filtro para configuraçao de cobrança ser usado em outras telas
    public model: any = {
        Inativos: true,
        FiltroConfCobranca: {
        }
    };

    public unidades: any[] = [];
    
    public total: number;
    public dataSource = new MatTableDataSource<any>(this.unidades);
    public selection = new SelectionModel<any>(true, []);

    public unidadeSelecionada: any;

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        setTimeout(() => {

            this._columns = ['Acoes','Descricao', 'HorarioComunicados','Inativo'];

            this.paginator.pageSize = 10;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            if (this.modal) {

                this.model.Inativos = false;
            }

            this.loadSession();

        });
    }

    limparFiltro() {

        this.model = {
            Inativos: true,
            FiltroConfCobranca: {
            }
        };
    }

    filtrar(page?: number, reset = false) {

        this.ui.block('Pesquisando configurações...');

        let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        if (reset) {
            skip = 0;
            this.paginator.pageIndex = 0;
        }
        const parameter: any = JSON.parse(JSON.stringify(this.model));
        parameter.ListarInativos = this.model.Inativos;
        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;
        
        this.api.listarConfiguracaoCobranca(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.total = response.data.Total;
                    this.unidades = response.data.Configuracoes;

                    this.dataSource = new MatTableDataSource<any>(response.data.Configuracoes);
                    this.selection = new SelectionModel<any>(true, []);

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    newRecord() {
        Swal.fire({
            title: 'Forneça a Descrição para a nova Configuração',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            showLoaderOnConfirm: true,
            preConfirm: (descricao) => {
                const parameter: any = JSON.parse(JSON.stringify({}));
                parameter.Descricao = descricao;
                this.ui.block();
        
                this.api.criarNovaConfiguracao(parameter).subscribe(
        
                    (response: any) => {
        
                        this.ui.unblock();
        
                        if (response.error) {
        
                            this.ui.error(response.error);
                        }
                        else {  
                            this.saveSession();
                            this.router.navigate(['/confcobrancas', response.data.Configuracao.ID]);
                        }
                    }
                );
            },
            allowOutsideClick: () => !Swal.isLoading()
          });        
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/confcobrancas', element.ID]);
    }

    deleteRecord(element: any) {
    }

    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Nome';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    selecionaUnidade(unidade: any) {

        this.unidadeSelecionada = unidade;
    }

    rowSelected(row: any) {

        if (!this.modal) {
            return;
        }

        if (this.modal === 'single') {

            this.selecionaUnidade(row);
        }
        else if (this.modal === 'multiple') {

            this.selection.toggle(row);
        }
    }

}
