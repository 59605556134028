import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "cpf"
})
export class CPFPipe implements PipeTransform {
    transform(oldCPF: string): string {
        let newCPF: string = "";
        if (oldCPF && oldCPF.length>=11) {
            for (var i = 0; i < oldCPF.length; i++) {
                if (i == 3 || i == 6) newCPF += ".";

                if (i == 9) {
                    newCPF += "-";
                    newCPF += oldCPF.charAt(i);
                } else {
                    newCPF += oldCPF.charAt(i);
                }
            }
        }
        return newCPF;
    }
}
