import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MapsAPILoader, AgmMap } from '@agm/core';

import { APIService } from './../../services/api.service';
import { UIService } from '../../services/ui.service';

declare var google: any;

@Component({
    selector: 'cs-map-dialog',
    templateUrl: './map-dialog.component.html',
    styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent implements OnInit {

    @ViewChild('search') search: ElementRef;
    @ViewChild('map') map: AgmMap;

    public zoom: number = 4;

    public centerMap: any = {
        latitude: -15.088998,
        longitude: -59.097545
    };

    public address: any;

    constructor(private ui: UIService, public dialogRef: MatDialogRef<MapDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private loader: MapsAPILoader, private zone: NgZone, public api: APIService) {

        if (data.coordinates.latitude && data.coordinates.longitude) {

            this.centerMap = JSON.parse(JSON.stringify(data.coordinates));
            this.zoom = 16;
        }
    }

    ngOnInit() {

        if (!this.data.radius) {

            this.data.radius = 5000;
        }

        this.loader.load().then(() => {

            const autocomplete = new google.maps.places.Autocomplete(this.search.nativeElement);

            setTimeout(() => { this.search.nativeElement.focus(); }, 500);

            autocomplete.addListener('place_changed', () => {

                this.zone.run(() => {

                    const place: any = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {

                        return;
                    }

                    this.data.coordinates.latitude = place.geometry.location.lat();
                    this.data.coordinates.longitude = place.geometry.location.lng();

                    this.centerMap = JSON.parse(JSON.stringify(this.data.coordinates));

                    this.zoom = 16;

                    setTimeout(() => {

                        this.selecionarLocal({ coords: { lat: this.data.coordinates.latitude, lng: this.data.coordinates.longitude } });

                        this.search.nativeElement.value = null;
                        this.search.nativeElement.focus();

                    }, 500);
                });
            });
        });
    }

    selecionarLocal(event) {

        this.address = null;

        this.data.coordinates.latitude = event.coords.lat;
        this.data.coordinates.longitude = event.coords.lng;

        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(event.coords.lat, event.coords.lng);
        const request: any = { latLng: latlng, language: 'pt-BR' };

        geocoder.geocode(request, (results, status) => {

            if (status === google.maps.GeocoderStatus.OK) {

                const addresses: any[] = results.filter((a) => a.types.some((b) => b === 'street_address'));

                if (addresses.length) {

                    const address: any = addresses[0];
                    const components: any[] = address.address_components;

                    let endereco: string;
                    let numero: string;
                    let bairro: string;
                    let cidade: string;
                    let estado: string;
                    let cep: string;
                    let enderecoCompleto: string;

                    if (components.some((a) => a.types.some((b) => b === 'street_number'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'street_number'))[0];
                        numero = component.long_name;
                    }

                    if (components.some((a) => a.types.some((b) => b === 'route'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'route'))[0];
                        endereco = component.long_name;
                    }

                    if (components.some((a) => a.types.some((b) => b === 'sublocality_level_1'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'sublocality_level_1'))[0];
                        bairro = component.long_name;
                    }

                    if (components.some((a) => a.types.some((b) => b === 'locality'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'locality'))[0];
                        cidade = component.long_name;
                    }
                    else if (components.some((a) => a.types.some((b) => b === 'administrative_area_level_2'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'administrative_area_level_2'))[0];
                        cidade = component.long_name;
                    }
                    else if (components.some((a) => a.types.some((b) => b === 'political'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'polititcal'))[0];
                        cidade = component.long_name;
                    }

                    if (components.some((a) => a.types.some((b) => b === 'administrative_area_level_1'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'administrative_area_level_1'))[0];
                        estado = component.short_name;
                    }

                    if (components.some((a) => a.types.some((b) => b === 'postal_code'))) {

                        const component: any = components.filter((a) => a.types.some((b) => b === 'postal_code'))[0];
                        cep = component.long_name;
                    }

                    if (endereco && numero && bairro && cidade && estado) {

                        enderecoCompleto = '<strong>' + endereco + ', ' + numero + '</strong><br>' + bairro + ' - ' + cidade + '/' + estado;

                        if (cep) {

                            enderecoCompleto += '<br>CEP: ' + cep;
                        }

                        this.data.address = {
                            endereco: endereco,
                            numero: numero,
                            bairro: bairro,
                            cidade: cidade,
                            estado: estado,
                            cep: cep,
                            enderecoCompleto: enderecoCompleto
                        };
                    }
                    else {

                        enderecoCompleto = address.formatted_address;
                    }

                    this.zone.run(() => {

                        this.address = enderecoCompleto;
                    });
                }
            }
        });
    }

    kilometros() {

        return (this.data && this.data.radius) ? '~ ' + (this.data.radius / 1000).toFixed(0) : null;
    }
}
