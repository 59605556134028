import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { DialogMessageComunicadoCobrancaComponent } from '../conf-cobranca/conf-cobranca.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'cs-exec-acao-cobrancas-comunicados',
    templateUrl: './exec-acao-cobrancas-comunicados.component.html',
    styleUrls: ['./exec-acao-cobrancas-comunicados.component.scss']
})
export class ExecucaoAcaoCobrancasComunicadosComponent implements OnInit {

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public model: any = {
        StatusID: 0,
        StatusMensagemID: 0,
        FiltroCliente: {
        },
        FiltroEstabelecimento: {
        }
    };
    
    public itens: any[] = [];
    public Listas: any = {
        RedeList: [],
        StatusComunicacaoCobrancaList: [],
        StatusMensagemList: []
    };
    public listGrupos: any[] = [];
    
    public total: number;
    public dataSource = new MatTableDataSource<any>(this.itens);

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private storage: StorageService, private sanitize : DomSanitizer) {
    }

    ngOnInit() {

        setTimeout(() => {

            this._columns = ['Acoes',
            'Venda', 'ReferenciaVenda', 'Enfileiramento', 'Status', 'Cliente', 
            'Estabelecimento', 'TipoDeCobranca', 'DescricaoComunicado', 'TipoComunicado', 'StatusMensagem',
            'DataMensagemEnviada', 'DataMensagemEntregue', 'DataMensagemLida','DataMensagemFalha' ,
            'DataMensagemNaoEntregue', 'DataVisualizado', 'DataMensagemLida', 'Erro'];

            this.paginator.pageSize = 10;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.loadSession();

            this.ui.block('Pesquisando Listas...');
            this.api.recuperarListasExecucaoCobranca({}).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {

                        this.Listas.RedeList = response.data.RedeList;                        
                        this.Listas.StatusComunicacaoCobrancaList = response.data.StatusComunicacaoCobrancaList;
                        this.Listas.StatusMensagemList = response.data.StatusMensagemList;
                    }

                }
            );

        });
    }

    limparFiltro() {

        this.model = {
            StatusID: 0,
            StatusMensagemID: 0,
            FiltroCliente: {
            },
            FiltroEstabelecimento: {
            }
        };
    }

    filtrar(page?: number, reset = false, gerarExcel = false) {

        this.ui.block('Pesquisando Comunicados de Execuções de Cobrança...');

        let skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;
        if (reset) {
            skip = 0;
            this.paginator.pageIndex = 0;
        }
        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = gerarExcel == true ? 0 : this.paginator.pageSize;
        parameter.Skip = gerarExcel == true ? 0 : skip;
        parameter.GerarExcel = gerarExcel;

        if (parameter.EnfileiramentoDe) parameter.EnfileiramentoDe = this.dateJS2DOTNET(parameter.EnfileiramentoDe);
        if (parameter.EnfileiramentoAte) parameter.EnfileiramentoAte = this.dateJS2DOTNET(parameter.EnfileiramentoAte);
        
        if (parameter.FiltroEstabelecimento && parameter.FiltroEstabelecimento.GrupoID=="") {
            parameter.FiltroEstabelecimento.GrupoID = '00000000-0000-0000-0000-000000000000';
        }
        if (parameter.FiltroEstabelecimento && parameter.FiltroEstabelecimento.RedeID=="") {
            parameter.FiltroEstabelecimento.RedeID = '00000000-0000-0000-0000-000000000000';
        }

        this.api.recuperarExecucaoCobrancasComunicados(parameter).subscribe(

            (response: any) => {
                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    //Gerar a planilha
                    if(response.data.Base64){
                        const linkSource = 'data:application/xlsx;base64,' + response.data.Base64;
                        const downloadLink = document.createElement("a");
                        const fileName = "exec-acao-cobrancas-comunicados.xlsx";

                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    }
                    if (!gerarExcel) {
                        this.total = response.data.Total;
                        this.itens = response.data.ResultList;

                        this.dataSource = new MatTableDataSource<any>(response.data.ResultList);

                        if (page) {

                            this.paginator.pageIndex = page;
                        }
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    showRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/consultavendas', element.VendaID]);
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            Listas: this.Listas,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');

        if (session) {

            this.model = session.model;
            
            if (!this.model.FiltroCliente)
                this.model.FiltroCliente = {};
            if (!this.model.FiltroEstabelecimento)
                this.model.FiltroEstabelecimento = {};

            this.Listas = session.Listas;
            if (!this.Listas)
                this.Listas = {};
                
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Venda';
            this.sort.direction = (session.sort) ? session.sort.direction : 'desc';
            
            if (this.model.FiltroEstabelecimento && this.model.FiltroEstabelecimento.RedeID != null)
                this.getGruposByRede(this.model.FiltroEstabelecimento.RedeID);
            
            this.filtrar(session.page.current);
        }
        else {

            if (!this.model.DataVencimentoAte) {
                this.model.DataVencimentoAte = new Date(new Date().setHours(0,0,0,0)).toISOString();
            }
            this.filtrar();
        }
    }

    rowSelected(row: any) {
    }

    redeSelected(e) {
        var grupos = [];
        if (this.model.FiltroEstabelecimento == null)
                this.model.FiltroEstabelecimento = {};
        if (!e.value) {            
            this.model.FiltroEstabelecimento.GrupoID = '';
            this.listGrupos = grupos;
        } else {
            const rede = this.Listas.RedeList.filter((r) => {
                return r.ID == e.value;
            });
            if (!rede.length) {
                this.model.FiltroEstabelecimento.GrupoID = '';
                this.listGrupos = grupos;
            } else {
                grupos = rede[0].Grupos;
                if (grupos.length == 0) {
                    this.listGrupos = grupos;
                } else if (grupos.length == 1) {
                    this.model.FiltroEstabelecimento.GrupoID = grupos[0].ID;
                    this.listGrupos = grupos;
                } else {
                    this.model.FiltroEstabelecimento.GrupoID = '';
                    this.listGrupos = [{
                        "ID": "",
                        "Nome": "Todos"
                    }].concat(grupos);

                }
            }

        }

    }

    private getGruposByRede(redeID) {
        if (!this.Listas || !this.Listas.RedeList)
            return;
        var grupos = [];
        const rede = this.Listas.RedeList.filter((r) => {
            return r.ID == redeID;
        });
        if (!rede.length) {
            this.listGrupos = grupos;
        } else {
            grupos = rede[0].Grupos;
            if (grupos.length == 0) {
                this.listGrupos = grupos;
            } else if (grupos.length == 1) {
                this.model.FiltroEstabelecimento.GrupoID = grupos[0].ID;
                this.listGrupos = grupos;
            } else {
                this.listGrupos = [{
                    "ID": "",
                    "Nome": "Todos"
                }].concat(grupos);
            }
        }
    }

    telefone(telefone: string) {

        if (telefone && telefone.length === 11) {

            return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4);
        }
        else {

            return telefone;
        }
    }

    dateDOTNET2JS(d) {
        const t = (d || '').match(/\/Date\((\d+)(\+|\-)(\d{2})(\d{2})\)\//) || [];
        if (t.length==5) {
          return new Date(parseInt(t[1]) + parseInt(t[2]+t[3])*3600000 + parseInt(t[2]+t[4])*60000);
        }
        const u = (d || '').match(/\/Date\((\d+)\)\//) || [];
        if (u.length==3) {
          return new Date(parseInt(u[1])/1000);
        }
        return null;
    }

    dateJS2DOTNET(d) {
        if (d) {
            const t: any = new Date(d);
            return '/Date(' + (t * 1) + '+0000)/';
        }
        return null;
    }

    getColorByStatusExecucao(e) {
        switch (e.StatusID) {
            case 1://Pendente
                return "#ffff99";
            case 2://ExecutadoComSucesso
                return "#ccffcc";
            case 3://ExecutadaComErro
                return "#ff9999";           
            default:
                return "";
        }
    }

    openMessageComunicado(e){
        const dialogRef = this.dialog.open(DialogMessageComunicadoCobrancaComponent, {
            width: '55%',
            height: '40%',
            data: { Mensagem: this.sanitize.bypassSecurityTrustHtml(e.MensagemComunicado),//JSON.parse(JSON.stringify(this.sanitize.bypassSecurityTrustHtml(e.MensagemComunicado))),
                    TipoEmail: e.TipoComunicadoID == 1 || e.TipoComunicadoID == 5,
                    ReadOnly: true,
                    ParametrosMensagem: {}
                  }
        },);
    }
}
