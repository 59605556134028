import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';
import { StorageService } from './../../services/storage.service';

import * as moment from 'moment';

@Component({
    selector: 'cs-campanhas',
    templateUrl: './campanhas.component.html',
    styleUrls: ['./campanhas.component.scss']
})
export class CampanhasComponent implements OnInit {

    @Input() modal: string = undefined;

    public _columns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public model: any = {
        Inativo: false,
        Inicio: Date,
        Termino: Date
    };

    public parceirosEmissores: Parceiro[] = [];
    public emissorNome: string = "";
    public campanhas: any[] = [];

    public total: number;
    public dataSource = new MatTableDataSource<any>(this.campanhas);
    public selection = new SelectionModel<any>(true, []);

    public campanhaSelecionada: any;

    constructor(private ui: UIService, private api: APIService, private router: Router, private storage: StorageService) {
    }

    ngOnInit() {

        setTimeout(() => {

            this._columns = [this.modal !== undefined ? 'Selecionado' : 'Acoes', 'Nome', 'Tipo', 'Entregues', 'Resgatados', 'Inicio', 'Termino', 'CodigoRastreio'];

            this.paginator.pageSize = this.modal ? 5 : 10;

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.loadSession();
        });
    }

    limparFiltro() {

        this.model = {};
    }

    filtrar(page?: number) {

        this.ui.block('Pesquisando campanhas...');

        const skip: number = (page || this.paginator.pageIndex) * this.paginator.pageSize;

        const parameter: any = JSON.parse(JSON.stringify(this.model));

        parameter.Take = this.paginator.pageSize;
        parameter.Skip = skip;

        if (this.sort && this.sort.direction) {

            parameter.Sort = this.sort.active + '|' + this.sort.direction;
        }
        else {

            parameter.Sort = 'Nome|asc';
        }

        this.api.obterCampanhas(parameter).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    for (const campanha of response.data.Campanhas) {

                        campanha.Inicio = moment(campanha.Inicio).format('DD/MM/YYYY');

                        if (campanha.Termino) {

                            campanha.Termino = moment(campanha.Termino).format('DD/MM/YYYY');
                        }
                    }

                    this.total = response.data.Total;
                    this.campanhas = response.data.Campanhas || [];

                    this.dataSource = new MatTableDataSource<any>(response.data.Campanhas);
                    this.selection = new SelectionModel<any>(true, []);

                    if (page) {

                        this.paginator.pageIndex = page;
                    }
                }

                this.storage.set('session', undefined);
            }
        );
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    masterToggle() {

        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    pageChanged(page: any) {

        this.filtrar();
    }

    sortChanged(sort: any) {

        this.filtrar();
    }

    newRecord() {

        this.saveSession();
        this.router.navigate(['/campanhas', 0]);
    }

    editRecord(element: any) {

        this.saveSession();
        this.router.navigate(['/campanhas', element.ID]);
    }

    deleteRecord(element: any) {
    }

    saveSession() {

        this.storage.set('session', {
            model: this.model,
            page: {
                size: this.paginator.pageSize,
                current: this.paginator.pageIndex
            },
            sort: {
                field: this.sort.active,
                direction: this.sort.direction
            }
        });
    }

    loadSession() {

        const session = this.storage.get('session');
        this.listarParceirosEmissores();
        if (session) {

            this.model = session.model;
            this.paginator.pageSize = session.page.size;
            this.sort.active = (session.sort) ? session.sort.field : 'Inicio';
            this.sort.direction = (session.sort) ? session.sort.direction : 'asc';

            this.filtrar(session.page.current);
        }
        else {

            this.filtrar();
        }
    }

    selecionaCampanha(campanha: any) {

        this.campanhaSelecionada = campanha;
    }

    rowSelected(row: any) {

        if (!this.modal) {

            return;
        }

        if (this.modal === 'single') {

            this.selecionaCampanha(row);
        }
        else if (this.modal === 'multiple') {

            this.selection.toggle(row);
        }
    }

    listarParceirosEmissores(){
        this.api.listarParceirosPorPerfil(1).subscribe((response: any) => {
            if (response.error) {
                this.ui.error(response.error);
            }
            else {
                response.data.Parceiros.forEach(element => {
                    if (element.Nome) {
                        this.parceirosEmissores.push({
                            ID: element.ID,
                            Nome: element.Nome
                        });
                    }
                });
            }
        })
    }

    trocarEmissor(){
        this.emissorNome = this.parceirosEmissores.find(x=>x.ID == this.model.EmissorID).Nome;
    }
    mudarData(){
        if(this.model.Inicio && this.model.Termino){
            var d1 = new Date(this.model.Inicio);
            var d2 = new Date(this.model.Termino);
            if(d1 > d2){
                this.model.Termino = d1;
                this.model.Inicio = d2;
            }
        }
    }
    validarCodigoRastreio(){
        if(this.model.CodigoRastreio < 0){
            this.model.CodigoRastreio = null;
        }
    }
}
export interface Parceiro {
    ID: string;
    Nome: string;
}
