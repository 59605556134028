import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

import { UIService } from '../../services/ui.service';
import { APIService } from './../../services/api.service';

@Component({
    selector: 'cs-consultor',
    templateUrl: './consultor.component.html',
    styleUrls: ['./consultor.component.scss']
})
export class ConsultorComponent implements OnInit, OnDestroy {

    @ViewChild('inputRede') inputRede: ElementRef;
    @Input() modal: string = undefined;

    private subscription: any;

    public acao: string;

    public model: any = {
    };

    constructor(public dialog: MatDialog, private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.subscription = this.route.params.subscribe(

            (params: any) => {

                const id = params['id'];

                if (id) {

                    this.ui.block();

                    const parameter: any = {
                        ID: id
                    };

                    this.api.obterConsultor(parameter).subscribe(

                        (response: any) => {

                            this.ui.unblock();

                            if (response.error) {

                                this.ui.error(response.error);
                                this.router.navigate(['/consultores']);
                            }
                            else {

                                this.model = response.data.Consultor;
                                this.acao = 'Edição';

                                // setTimeout(() => { this.inputRede.nativeElement.focus(); });
                            }
                        }
                    );
                }
                else {

                    this.acao = 'Inclusão';

                    // setTimeout(() => { this.inputRede.nativeElement.focus(); });
                }
            }
        );
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

    cancelar() {

        this.router.navigate(['/consultores']);
    }

    validar() {

        let podeSalvar: boolean = true;
        let reason: string;

        if (!this.model.Nome) {

            podeSalvar = false;
            reason = 'é necessário informar o nome do consultor.';
        }
        else if (!this.model.CPF) {

            podeSalvar = false;
            reason = 'é necessário informar o CPF do consultor.';
        }
        else if (!this.model.Telefone) {

            podeSalvar = false;
            reason = 'é necessário informar o CPF do consultor.';
        }
        else if (!this.model.Email) {

            podeSalvar = false;
            reason = 'é necessário informar o CPF do consultor.';
        }

        if (!this.model.ID) this.model.Inativo = 0;

        if (!podeSalvar) {

            this.ui.error('Não foi possível salvar o consultor pois ' + reason);
        }

        return podeSalvar;
    }

    salvar() {

        if (this.validar()) {

            this.ui.block();

            const parameter: any =
                JSON.parse(JSON.stringify(this.model));

            this.api.salvarConsultor(parameter).subscribe(

                (response: any) => {

                    this.ui.unblock();

                    if (response.error) {

                        this.ui.error(response.error);
                    }
                    else {

                        this.ui.success('Consultor salvo com sucesso.');

                        this.router.navigate(['/consultores']);
                    }
                }
            );
        }
    }
}
