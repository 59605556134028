import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy,
    Input
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";

import { Observable } from "rxjs";

import { UIService } from "../../services/ui.service";
import { APIService } from "./../../services/api.service";

@Component({
    selector: "cs-estabelecimento",
    templateUrl: "./estabelecimento.component.html",
    styleUrls: ["./estabelecimento.component.scss"]
})
export class EstabelecimentoComponent implements OnInit, OnDestroy {
    @ViewChild("inputRede") inputRede: ElementRef;
    @Input() modal: string = undefined;

    private subscription: any;
    public acao: string;

    public model: any = {
        ID: '0',
        TerminaisPOS: []
    };

    public listRedes: any[] = [];
    public listGrupos: any[] = [];
    
    public redesFiltradas: Observable<string>;

    constructor(
        public dialog: MatDialog,
        private ui: UIService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.ui.block('Pesquisando redes...');
        this.api.listarRedes({}).subscribe(

            (response: any) => {

                this.ui.unblock();

                if (response.error) {

                    this.ui.error(response.error);
                }
                else {

                    this.listRedes = response.data.Redes;
                    if (this.listRedes.length == 1) {
                        this.model.RedeID = this.listRedes[0].ID;
                    }

                    this.subscription = this.route.params.subscribe((params: any) => {
                        const id = params["id"];

                        if (id != '0') {
                            this.ui.block();

                            const parameter: any = {
                                ID: id
                            };

                            this.api.obterEstabelecimento(parameter).subscribe((response: any) => {
                                this.ui.unblock();

                                if (response.error) {
                                    this.ui.error(response.error);
                                    this.router.navigate(["/estabelecimentos"]);
                                } else {
                                    this.model = response.data.Estabelecimento;
                                    this.acao = "Edição";

                                    const rede = this.listRedes.filter((r) => {
                                        const grupo = r.Grupos.filter((g) => {
                                            return g.ID == this.model.GrupoID;
                                        });
                                        return (grupo.length);
                                    });
                                    if (rede.length) {
                                        this.listGrupos = rede[0].Grupos;
                                        this.model.RedeID = rede[0].ID;
                                    }

                                    setTimeout(() => {
                                    //    this.inputRede.nativeElement.focus();
                                    });
                                }
                            });
                        } else {
                            this.acao = "Inclusão";

                            setTimeout(() => {
                            //    this.inputRede.nativeElement.focus();
                            });
                        }
                    });

            }

        });

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancelar() {
        this.router.navigate(["/estabelecimentos"]);
    }

    validar() {
        let podeSalvar: boolean = true;
        let reason: string;

        if (!this.model.RedeID) {
            podeSalvar = false;
            reason = "é necessário informar uma rede.";
        } 
        else if (!this.model.GrupoID) {
            podeSalvar = false;
            reason = "é necessário informar um grupo.";
        }
        else if (!this.model.Nome) {
            podeSalvar = false;
            reason = "é necessário informar o nome.";
        }
        else {
            let terminalRepetido = false;
            let index = 0;
            while (this.model.TerminaisPOS.length > index + 1) {
                let c = this.model.TerminaisPOS[index];
                for (let i = index + 1; i < this.model.TerminaisPOS.length; i++) {
                    let c2 = this.model.TerminaisPOS[i];
                    if (c.Usuario == c2.Usuario && !c.Inativo && !c2.Inativo) {
                        terminalRepetido = true;
                        break;
                    }
                }
                if (!terminalRepetido)
                    index++;
                else
                    break;
            }
            if (terminalRepetido) {
                podeSalvar = false;
                reason = "existem Terminais POS com o usuário repetido.";
            }
        }
        if (!podeSalvar) {
            this.ui.error("Não foi possível salvar o estabelecimento pois " + reason);
        }

        return podeSalvar;
    }

    salvar() {
        if (this.validar()) {
            this.ui.block();
            if (this.model.ID == '0') {
                this.model.ID = '00000000-0000-0000-0000-000000000000';
            }

            const parameter: any = {
                Estabelecimento: JSON.parse(JSON.stringify(this.model)),
            };

            this.api.salvarEstabelecimento(parameter).subscribe((response: any) => {
                this.ui.unblock();

                if (response.error) {
                    this.ui.error(response.error);
                } else {
                    this.ui.success("Estabelecimento salvo com sucesso.");

                    this.router.navigate(["/estabelecimentos"]);
                }
            });
        }
    }

    
    redeSelected(e) {
        const rede = this.listRedes.filter((r) => {
            return r.ID == e.value;
        });
        this.listGrupos = rede[0].Grupos;
        if (!rede.length) {
            this.model.GrupoID = '';
        } else {
          if (this.listGrupos.length == 1) {
            this.model.GrupoID = this.listGrupos[0].ID;
          }
        }
    }

}

