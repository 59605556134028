import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { MatDialog } from '@angular/material';

import { Font, FontPickerService } from 'ngx-font-picker';

import { UIService } from '../../services/ui.service';
import { APIService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';

import html2canvas from 'html2canvas';

declare var window: any;

@Component({
    selector: 'cs-comunicado',
    templateUrl: './comunicado.component.html',
    styleUrls: ['./comunicado.component.scss']
})
export class ComunicadoComponent implements OnInit, OnDestroy {

    @ViewChild('cropperFrente') cropperFrente: any;
    @ViewChild('opacityFrente') opacityFrente: any;

    @ViewChild('cropperVerso') cropperVerso: any;
    @ViewChild('opacityVerso') opacityVerso: any;

    public isReady: boolean;

    private subscription: any;

    public acao: string;

    public model: any = {
        Info: { UnityID: 1 }
    };

    public listRedes: any = [];

    public ladoComunicado: number = 1;

    public cropperReadyFrente: boolean;
    public croppedImageFrente: any;

    public cropperReadyVerso: boolean;
    public croppedImageVerso: any;

    public robotoFiles: any = {
        '100': 'http://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgWxP.ttf',
        '300': 'http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5vAw.ttf',
        '500': 'http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9vAw.ttf',
        '700': 'http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlvAw.ttf',
        '900': 'http://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmYUtvAw.ttf',
        '100italic': 'http://fonts.gstatic.com/s/roboto/v18/KFOiCnqEu92Fr1Mu51QrIzc.ttf',
        '300italic': 'http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TjARc9.ttf',
        'regular': 'http://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Me5Q.ttf',
        'italic': 'http://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu52xP.ttf',
        '500italic': 'http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51S7ABc9.ttf',
        '700italic': 'http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBhc9.ttf',
        '900italic': 'http://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TLBBc9.ttf'
    };

    public robotoStyles: any[] = [
        '100',
        '100italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '500',
        '500italic',
        '700',
        '700italic',
        '900',
        '900italic'
    ];

    public camposFrente: any[] = [
        { Nome: 'Campo 1', Texto: '', Fonte: { 'size': '32px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 2', Texto: '', Fonte: { 'size': '28px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 3', Texto: '', Fonte: { 'size': '20px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 4', Texto: '', Fonte: { 'size': '16px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 5', Texto: '', Fonte: { 'size': '14px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 6', Texto: '', Fonte: { 'size': '12px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 7', Texto: '', Fonte: { 'size': '12px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 8', Texto: '', Fonte: { 'size': '12px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 9', Texto: '', Fonte: { 'size': '12px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 10', Texto: '', Fonte: { 'size': '12px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } }
    ];

    public modelosFrente: any[] = [
        {
            ID: 0,
            Nome: 'Nenhum'
        },
        {
            ID: 1,
            Nome: 'Modelo 1',
            Campos: this.camposFrente
        }
    ];

    public camposVerso: any[] = [
        { Nome: 'Campo 1', Texto: '', Fonte: { 'size': '20px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 2', Texto: '', Fonte: { 'size': '16px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 3', Texto: '', Fonte: { 'size': '14px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 4', Texto: '', Fonte: { 'size': '16px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 5', Texto: '', Fonte: { 'size': '14px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 6', Texto: '', Fonte: { 'size': '16px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } },
        { Nome: 'Campo 7', Texto: '', Fonte: { 'size': '14px', 'style': 'regular', 'family': 'Roboto', 'files': this.robotoFiles, 'styles': this.robotoStyles } }
    ];

    public modelosVerso: any[] = [
        {
            ID: 0,
            Nome: 'Nenhum'
        },
        {
            ID: 1,
            Nome: 'Modelo 1',
            Campos: this.camposVerso
        }
    ];

    public modeloSelecionadoFrente: any = this.modelosFrente[0];
    public campoSelecionadoFrente: any;

    public modeloSelecionadoVerso: any = this.modelosVerso[0];
    public campoSelecionadoVerso: any;

    public imageChangedEventFrente: any;
    public imageChangedEventVerso: any;

    constructor(private ui: UIService, private api: APIService, private router: Router, private route: ActivatedRoute, private storage: StorageService, private fontService: FontPickerService, public dialog: MatDialog) {
    }

    layout() {

        const layout: any = {
            camposFrente: this.camposFrente,
            modelosFrente: this.modelosFrente,
            camposVerso: this.camposVerso,
            modelosVerso: this.modelosVerso,
            indexModeloFrente: this.modelosFrente.indexOf(this.modeloSelecionadoFrente),
            indexModeloVerso: this.modelosVerso.indexOf(this.modeloSelecionadoVerso),
            croppedImageFrente: this.croppedImageFrente,
            opacityFrente: this.opacityFrente.value,
            croppedImageVerso: this.croppedImageVerso,
            opacityVerso: this.opacityVerso.value
        };

        return JSON.stringify(layout);
    }

    ngOnInit() {

        this.ui.block();

        this.subscription = this.route.params.subscribe(

            (params: any) => {

                const id = params['id'];
                const modelo: number = +params['modelo'];

                if (id != '0') {

                    this.api.obterComunicado(id).subscribe(

                        (response: any) => {

                            if (!response.error) {

                                response.data.Comunicado.AlvoClientes = (response.data.Comunicado.Publico === 1 || response.data.Comunicado.Publico === 3);
                                response.data.Comunicado.AlvoColaboradores = (response.data.Comunicado.Publico === 2 || response.data.Comunicado.Publico === 3);

                                this.model = response.data.Comunicado;
                                this.acao = 'Edição';

                                const layout: any = JSON.parse(response.data.Comunicado.Layout);

                                this.camposFrente = layout.camposFrente;
                                this.modelosFrente = layout.modelosFrente;
                                this.camposVerso = layout.camposVerso;
                                this.modelosVerso = layout.modelosVerso;

                                setTimeout(() => {

                                    this.ladoComunicado = 2;

                                    this.cropperVerso.imageBase64 = layout.croppedImageVerso;
                                    this.opacityVerso.value = layout.opacityVerso;
                                    this.croppedImageVerso = layout.croppedImageVerso;
                                    this.cropperReadyVerso = true;

                                    setTimeout(() => {

                                        this.ladoComunicado = 1;

                                        this.cropperFrente.imageBase64 = layout.croppedImageFrente;
                                        this.opacityFrente.value = layout.opacityFrente;
                                        this.croppedImageFrente = layout.croppedImageFrente;
                                        this.cropperReadyFrente = true;

                                        this.load(layout.indexModeloFrente, layout.indexModeloVerso);

                                    }, 1000);

                                }, 1000);
                            }
                        }
                    );
                }
                else {

                    if (modelo) {

                        this.api.obterComunicado(modelo).subscribe(

                            (response: any) => {

                                if (!response.error) {

                                    this.acao = 'Criação';

                                    const layout: any = JSON.parse(response.data.Comunicado.Layout);

                                    this.camposFrente = layout.camposFrente;
                                    this.modelosFrente = layout.modelosFrente;
                                    this.camposVerso = layout.camposVerso;
                                    this.modelosVerso = layout.modelosVerso;

                                    setTimeout(() => {

                                        this.ladoComunicado = 2;

                                        this.cropperVerso.imageBase64 = layout.croppedImageVerso;
                                        this.opacityVerso.value = layout.opacityVerso;
                                        this.croppedImageVerso = layout.croppedImageVerso;
                                        this.cropperReadyVerso = true;

                                        setTimeout(() => {

                                            this.ladoComunicado = 1;

                                            this.cropperFrente.imageBase64 = layout.croppedImageFrente;
                                            this.opacityFrente.value = layout.opacityFrente;
                                            this.croppedImageFrente = layout.croppedImageFrente;
                                            this.cropperReadyFrente = true;

                                            this.load(layout.indexModeloFrente, layout.indexModeloVerso);

                                        }, 1000);

                                    }, 1000);
                                }
                            }
                        );
                    }
                    else {

                        this.acao = 'Criação';

                        this.load(0, 0);
                    }
                }
            }
        );

        this.api.listarRedes({}).subscribe(
            (response: any) => {
                if (response.error) {
                    this.ui.error(response.error);
                }
                else {
                    if (response.data.Redes.length > 1) {
                        this.listRedes = [{
                            ID: '',
                            UnityID: 1,
                            Nome: 'Todas'
                        }].concat(response.data.Redes);
                    } else {
                        this.listRedes = response.data.Redes;                        
                    }
                }

            }
        );
    }

    load(indexModeloFrente: number, indexModeloVerso: number) {

        for (const modelo of this.modelosFrente) {

            if (modelo.Campos) {

                for (const campo of modelo.Campos) {

                    if (campo.Fonte) {

                        campo.Fonte = new Font(campo.Fonte);

                        this.fontService.loadFont(campo.Fonte);
                    }
                }
            }
        }

        this.modeloSelecionadoFrente = this.modelosFrente[indexModeloFrente];
        if (this.modeloSelecionadoFrente.Campos) this.campoSelecionadoFrente = this.modeloSelecionadoFrente.Campos[0];

        for (const modelo of this.modelosVerso) {

            if (modelo.Campos) {

                for (const campo of modelo.Campos) {

                    if (campo.Fonte) {

                        campo.Fonte = new Font(campo.Fonte);
                        this.fontService.loadFont(campo.Fonte);
                    }
                }
            }
        }

        this.modeloSelecionadoVerso = this.modelosVerso[indexModeloVerso];
        if (this.modeloSelecionadoVerso.Campos) this.campoSelecionadoVerso = this.modeloSelecionadoVerso.Campos[0];

        this.isReady = true;

        this.ui.unblock();
    }

    ngOnDestroy() {

        this.subscription.unsubscribe();
    }

    cancelar() {

        this.router.navigate(['/comunicados']);
    }

    getImages() {

        return Observable.create(

            (observer: Observer<boolean>) => {

                const ladoComunicado: number = this.ladoComunicado;

                this.ladoComunicado = 1;

                setTimeout(() => {

                    html2canvas(document.querySelector('#imagem-comunicado-frente')).then((canvas) => {

                        this.model.ImagemFrente = canvas.toDataURL();
                    });

                    this.ladoComunicado = 2;

                    setTimeout(() => {

                        html2canvas(document.querySelector('#imagem-comunicado-verso')).then((canvas) => {

                            this.model.ImagemVerso = canvas.toDataURL();

                            setTimeout(() => {

                                this.ladoComunicado = ladoComunicado;

                                observer.next(true);
                            });
                        });
                    });
                });
            }
        );
    }

    salvar() {

        if (this.validar()) {

            this.getImages().subscribe(() => {

                this.ui.block('Salvando comunicado...');

                const data: any = JSON.parse(JSON.stringify(this.parameter()));

                this.api.salvarComunicado(data).subscribe(

                    (response: any) => {

                        this.ui.unblock();

                        if (response.error) {

                            this.ui.error(response.error);
                        }
                        else {

                            this.ui.success('Comunicado salvo com sucesso!');

                            this.router.navigate(['/comunicados']);
                        }
                    }
                );
            });
        }
    }

    fileChangeEventFrente(event: any): void {

        this.imageChangedEventFrente = event;
    }

    imageCroppedFrente(image: string) {

        this.croppedImageFrente = image;
        this.cropperReadyFrente = true;
    }

    mudouModeloFrente() {

        if (this.modeloSelecionadoFrente && this.modeloSelecionadoFrente.Campos) {

            this.campoSelecionadoFrente = this.modeloSelecionadoFrente.Campos[0];
        }
        else {

            this.campoSelecionadoFrente = undefined;
        }
    }

    fileChangeEventVerso(event: any): void {

        this.imageChangedEventVerso = event;
    }

    imageCroppedVerso(image: string) {

        this.croppedImageVerso = image;
        this.cropperReadyVerso = true;
    }

    mudouModeloVerso() {

        if (this.modeloSelecionadoVerso && this.modeloSelecionadoVerso.Campos) {

            this.campoSelecionadoVerso = this.modeloSelecionadoVerso.Campos[0];
        }
        else {

            this.campoSelecionadoVerso = undefined;
        }
    }

    validar() {

        let podeSalvar: boolean = true;
        let reason: string;

        if (!this.model.Descricao) {

            podeSalvar = false;
            reason = 'é necessário informar um nome para o mesmo.';
        }
        else if (!this.model.Titulo) {

            podeSalvar = false;
            reason = 'é necessário informar um título para o mesmo.';
        }
        else if (!this.model.AlvoClientes && !this.model.AlvoColaboradores) {

            podeSalvar = false;
            reason = 'é necessário selecionar o público alvo.';
        }

        if (!podeSalvar) {

            this.ui.error('Não foi possível salvar o comunicado pois ' + reason);
        }

        return podeSalvar;
    }

    parameter() {

        return {
            ID: this.model.ID,
            Descricao: this.model.Descricao,
            Titulo: this.model.Titulo,
            BoasVindas: this.model.BoasVindas,
            Clientes: this.model.AlvoClientes,
            Colaboradores: this.model.AlvoColaboradores,
            ImagemFrente: this.model.ImagemFrente,
            ImagemVerso: this.model.ImagemVerso,
            Info: this.model.Info,
            Layout: this.layout()
        };
    }
}
