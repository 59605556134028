import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from "@angular/core";
import * as pbi from "powerbi-client";
import { APIService } from "src/app/services/api.service";
import { UIService } from "src/app/services/ui.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: "cs-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"]
})
export class ReportsComponent implements OnInit, OnDestroy {
    scrHeight: any;
    scrWidth: any;
    private subscription: any;
    public listReports: any[] = [];
    public model: any = {};

    @HostListener("window:resize", ["$event"])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
       // console.log(this.scrHeight, this.scrWidth);
    }

    @ViewChild("reportContainer") reportContainer: ElementRef;

    constructor(private api: APIService, private ui: UIService,
        private route: ActivatedRoute, private router: Router
    ) {}

    ngOnInit() {
        let id = '';

        this.subscription = this.route.params.subscribe((params: any) => {
            id = params["id"];

            this.ui.block();
            this.api.obterDashboards({
                Take: 5000,
                Skip: 0
            }).subscribe((response: any) => {
                this.ui.unblock();
                if (!response.error && response.data.Dashboards && response.data.Dashboards.length > 0) {
                    this.listReports = response.data.Dashboards;
                    this.reportSelected({ value: id});
                }
            });

        });

        setTimeout(() => {
            this.getScreenSize();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    reportSelected(e) {
        const reportContainer = this.reportContainer.nativeElement;
        if (e.value) {
            const report = this.listReports.filter(r => {
                return r.ID == e.value;
            })[0];

            if (report) {
                this.ui.block();
                this.api.gerarTokenPowerBI(report.ID).subscribe((response: any) => {
                    this.ui.unblock();
                    if (response.error) {
                        this.ui.error(response.error);
                    } else {
                        this.showReport(
                            response.data.AccessToken,
                            response.data.EmbedUrl,
                            response.data.EmbedResourceId,
                            response.data.Type
                        );
                    }
                });
            }
        }
    }

    showReport(accessToken: string, embedUrl: string, embedResourceId: string, type: string) {
        let models = pbi.models;
        let config = {
            type: type,
            tokenType: models.TokenType.Embed,
            accessToken: accessToken,
            embedUrl: embedUrl,
            permission: models.Permissions.All,
            id: embedResourceId,
            viewMode: models.ViewMode.View,
            settings: {}
        };

        const reportContainer = this.reportContainer.nativeElement;
        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        const report = powerbi.embed(reportContainer, config);
        report.off("loaded");
        report.on("loaded", () => {
           // console.log("Loaded");
        });
        report.on("error", () => {
            //this.getToken();
        });
    }
}
